<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 250px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color:  #0B2154 !important;height: 250px !important;">
        <app-navbar></app-navbar>
        <div class="container">
            <div class="menu">
                <div class="menu-title">
                    <span>Mes informations</span>
                </div>
                <div class="profile">
                    <div class="row">
                        <div class="col-4">
                            <svg style="    width: 98%;" xmlns="http://www.w3.org/2000/svg" width="104" height="106" viewBox="0 0 104 106">
                                <g id="Groupe_58" data-name="Groupe 58" transform="translate(-449.57 -116.254)">
                                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="52" cy="52" r="52" transform="translate(449.57 116.255)" fill="#60ba07"/>
                                    <text id="G" transform="translate(501.57 200.254)" fill="#cecece" font-size="87" font-family="Montserrat-Bold, Montserrat" font-weight="700" letter-spacing="0.041em"><tspan x="-32.625" y="0">{{_lettre(dataUsers?.spectateurnom)}}</tspan></text>
                                </g>
                            </svg>

                        </div>
                        <div class="col-8">
                            <span class="profile-text">{{dataUsers?.spectateurnom}} {{dataUsers?.spectateurprenom}}</span> <br>
                            <span class="profile-text">{{dataUsers?.spectateurtel}}</span><br>
                            <span class="profile-text">{{dataUsers?.spectateuremail}}</span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>
</section>

<div class="courses-details-area pb-70" style="background-color:#006837 ;min-height: 100%;">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <nav class="navCat">
                    <a style="cursor: pointer" [routerLink]="data.uri"  *ngFor="let data of dataNav;let i=index" id="nav{{i}}">{{data.label}}</a>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-8">
                <div class="courses-details-desc" style="margin-top: 5%;box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;padding: 4%;">
                    <form class="edit-account">

                        <div class="row">

                            <div class="col-lg-12 col-md-12">
                                <legend>MODIFIER MOT DE PASSE</legend>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Mot de passe actuel</label>
                                    <input [formControl]="passwordForm.controls['oldpassword']" type="password"
                                           maxlength="5" minlength="5"  oninput="this.value = this.value.replace(/[^0-9]/g, '');" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Nouveau mot de passe (La longueur du mot de passe doit être de 5 chiffres)</label>
                                    <input [formControl]="passwordForm.controls['password']" type="password"
                                           maxlength="5" minlength="5"  oninput="this.value = this.value.replace(/[^0-9]/g, '');" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Confirmation mot de passe</label>
                                    <input [formControl]="passwordForm.controls['confpassword']" type="password"
                                           maxlength="5" minlength="5"  oninput="this.value = this.value.replace(/[^0-9]/g, '');" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button (click)="resetPassword()" type="submit" class="default-btn"><i
                                    class='bx bx-save icon-arrow before'></i><span class="label">VALIDER</span><i
                                    class="bx bx-save icon-arrow after"></i></button>
                            </div>

                        </div>


                        <br>
                        <div class="col-lg-12 col-md-12">
                            <legend>MODIFIER PROFIL</legend>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Telephone <span class="required">*</span></label>
                                    <input [disabled]="true" type="text" class="form-control" [formControl]="spectateurForm.controls['spectateurtel']">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" class="form-control" [formControl]="spectateurForm.controls['spectateuremail']">
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Nom <span class="required">*</span></label>
                                    <input type="text" class="form-control" [formControl]="spectateurForm.controls['spectateurnom']">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Prenom <span class="required">*</span></label>
                                    <input type="text" class="form-control" [formControl]="spectateurForm.controls['spectateurprenom']">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Date naissance <span class="required">*</span></label>
                                    <input type="date" class="form-control" [formControl]="spectateurForm.controls['spectateurdatenais']">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Sexe <span class="required">*</span></label>
                                    <select class="form-control" [formControl]="spectateurForm.controls['spectateursexe']">
                                        <option value="homme">un homme</option>
                                        <option value="femme">une femme</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button (click)="updateProfile()" type="submit" class="default-btn"><i
                                    class='bx bx-save icon-arrow before'></i><span class="label">VALIDER</span><i
                                    class="bx bx-save icon-arrow after"></i></button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-2">

            </div>
        </div>
    </div>
</div>







