<header class="header-area p-relative" style="display: none">
    <div class="top-header top-header-style-four">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-7" style="display: none">
                    <ul class="top-header-contact-info">
                        <li>
                            Abidjan Cocody Rivera - Derriere le 30 eme Arrondissement
                            <!--
                            <a href="tel:+502464674">20 27 30 5 00</a>
                            -->
                        </li>
                    </ul>
                    <div class="top-header-social">
                        <span>+225 27 20 24 33 31 </span>
                        <!--
                             <a href="#" target="_blank"><i class='bx bxl-facebook'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-twitter'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-instagram'></i></a>
                        -->

                    </div>
                </div>
                <div class="col-lg-5 col-md-5" style="display: none">
                    <ul class="top-header-login-register">
                        <li ><a href="https://nbh-organisateur.com"><i class='bx bx-credit-card-alt'></i> Espace Organisateur</a>
                        </li>
                        <li *ngIf="!isConnect"><a routerLink="/connecter"><i class='bx bx-log-in'></i> Se connecter</a>
                        </li>
                        <li *ngIf="!isConnect"><a routerLink="/inscrire"><i class='bx bx-log-in-circle'></i>
                                S'inscrire</a></li>
                        <li *ngIf="isConnect"><a style="cursor: pointer;" (click)="goToRouting('/achat')"><i
                                    class='bx bx-log-in'></i> Mon profil</a></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>


    <div class="navbar-area navbar-style-four" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav style="display: none">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light nv" style="height: 100px;background: transparent !important;" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/digit.jpg"
                        style=" width: 165px;" alt="logo"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">

                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Accueil</a></li>


                        <li (click)="goToRouting('/concert')" class="nav-item"><a  class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Concert</a></li>

                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Theatre <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li (click)="goToRouting('/comedie')" class="nav-item">
                                    <a  class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                        Comedie
                                    </a></li>
                                <li (click)="goToRouting('/theatre')" class="nav-item"><a  class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Theatre
                                    </a></li>
                            </ul>
                        </li>

                        <li (click)="goToRouting('/festival')" class="nav-item"><a  class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Festival</a></li>


                        <li (click)="goToRouting('/spectacle')"  class="nav-item"><a  class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Cinema</a></li>



                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Sport <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li  (click)="goToRouting('/loisir')"  class="nav-item"><a  class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">
                                        Loisirs
                                    </a></li>
                                <li  (click)="goToRouting('/sports')"  class="nav-item"><a  class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Sports
                                    </a></li>
                            </ul>
                        </li>

                        <li (click)="goToRouting('/autres')" class="nav-item"><a  class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Autres</a></li>


                    </ul>

                    <!--
                        <ul class="navbar-nav">

                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Accueil</a></li>


                        <li class="nav-item"><a routerLink="/concert" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Concert</a></li>

                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Theatres <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/comedie" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">
                                        Comedie
                                    </a></li>
                                <li class="nav-item"><a routerLink="/theatre" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Theatre
                                    </a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/festival" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Festival</a></li>


                        <li class="nav-item"><a routerLink="/spectacle" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Spectacle</a></li>



                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Sport <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/loisir" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">
                                        Loisirs
                                    </a></li>
                                <li class="nav-item"><a routerLink="/sports" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Sports
                                    </a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/autres" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Autres</a></li>


                    </ul>
                    -->


                </div>
                <div *ngIf="isConnect && notif && notif.check" class="others-option">

                    <a class="cart-wrapper-btn d-inline-block">


                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-bell" viewBox="0 0 16 16">
                            <path
                                d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                        </svg>
                        <span>{{notif.check}}</span>
                    </a>
                    <!--bi bi-bell-fill
  <div class="search-box d-inline-block">
                        <i class='bx bx-search' (click)="toggleClass3()"></i>
                    </div>
                    -->

                </div>
            </nav>
        </div>
    </div>


</header>

<div class="search-overlay" [class.active]="classApplied3" style="display: none">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>

