<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 250px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color:  #0B2154 !important;height: 250px !important;">
        <app-navbar></app-navbar>
        <div class="container">
            <div class="menu">
                <div class="menu-title">
                    <span>Mes tickets</span>
                </div>
                <div class="profile">
                    <div class="row">
                        <div class="col-4">
                            <svg style="    width: 98%;" xmlns="http://www.w3.org/2000/svg" width="104" height="106" viewBox="0 0 104 106">
                                <g id="Groupe_58" data-name="Groupe 58" transform="translate(-449.57 -116.254)">
                                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="52" cy="52" r="52" transform="translate(449.57 116.255)" fill="#60ba07"/>
                                    <text id="G" transform="translate(501.57 200.254)" fill="#cecece" font-size="87" font-family="Montserrat-Bold, Montserrat" font-weight="700" letter-spacing="0.041em"><tspan x="-32.625" y="0">{{_lettre(dataUsers?.spectateurnom)}}</tspan></text>
                                </g>
                            </svg>

                        </div>
                        <div class="col-8">
                            <span class="profile-text">{{dataUsers?.spectateurnom}} {{dataUsers?.spectateurprenom}}</span> <br>
                            <span class="profile-text">{{dataUsers?.spectateurtel}}</span><br>
                            <span class="profile-text">{{dataUsers?.spectateuremail}}</span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>
</section>

<div class="courses-details-area pb-70" style="background-color:#006837 ;min-height: 100%;">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <nav class="navCat">
                    <a style="cursor: pointer" [routerLink]="data.uri"  *ngFor="let data of dataNav;let i=index" id="nav{{i}}">{{data.label}}</a>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-8">
                <div class="courses-details-desc">

                    <div class="container" style="margin-top: 100px;" *ngIf="loadingCheck">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" style="font-size: 30px;height: 120px;width: 120px;color: red;" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>

                    </div>
                    <h2 class="titleListe">Vos tickets diponibles :</h2>
                    <div class="contLine" >
                        <div class="event" *ngFor="let a of paginatedItems; let i=index" (click)="showMyTicket(a)">
                            <img [src]="a?.urlImage || 'assets/img/party.jpg'" [alt]="a.title">
                            <div class="info">
                                <p class="title">{{a.event}}</p>
                                <p class="title" style="font-size: 12px;">{{a.typeticket}}</p>
                                <p class="price">
                                    {{_split(a.caticket)}} : {{separe(a.ticketmontantpayer | number)}} FCFA
                                    <!--                                &nbsp;&nbsp;{{a.achatnbreticket}}-->
                                    <!--                                x  &nbsp;&nbsp;-->
                                    <!--                                <b style="float: right;font-weight: bold;"> &gt;</b>-->
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">

            </div>
        </div>
    </div>
</div>















<section class="mission-area" style="background: linear-gradient(to bottom, #006400, #004d00);height: 220vh; display: none">

            <app-navbar></app-navbar>

            <div class="container">






                    <div class="grid-container">

                        <div *ngFor="let data of paginatedItems" class="card">
                            <img [src]="data?.urlImage || 'assets/img/party.jpg'" [alt]="data.title">
                            <div class="card-content">
                                <h3>{{data.event}}</h3>
                                <p class="price">{{data.typeticket}}</p>
                                <p class="price">{{data.caticket}} : {{data.ticketmontantpayer}} F</p>
                                <a href="javascript:void(0)" class="demo-button"  (click)="showMyTicket(data)">Voir le ticket</a>
                            </div>
                        </div>
                    </div>

                    <div class="pagination" *ngIf="paginatedItems.length">
                        <button (click)="prevPage()" [disabled]="currentPage === 1">Précédent</button>
                        <div class="page-numbers">
                            <button *ngFor="let page of pages" (click)="goToPage(page)" [class.active]="page === currentPage">
                                {{page}}
                            </button>
                        </div>
                        <button (click)="nextPage()" [disabled]="currentPage === pageCount">Suivant</button>
                    </div>



                <div *ngIf="!showListTicket && showTicket" class="myDashboard-profile" style="margin-top: 20px !important;display: none">
                    <div *ngIf="showTicket.typeticket==='QR_CODE'" class="row align-items-center">


                        <div class="col-lg-4 col-md-4">
                            <qrcode [qrdata]="showTicket.ticketcode" [width]="356" [errorCorrectionLevel]="'M'"></qrcode>
                        </div>


                        <div class="col-lg-8 col-md-8">
                            <div class="profile-content" *ngIf="!sharedTicketQrcode">
                                <ul class="contact-info">
                                    <li>
                                        <h3>{{ showTicket.event}}</h3>
                                    </li>
                                    <li>
                                        <p> <b>Categorie ticket : </b> {{ showTicket.caticket}}</p>
                                    </li>
                                    <li><b>Ref achat : </b>{{ showTicket.achatcode }}</li>
                                    <li><b>Date achat : </b>{{ showTicket.ticketdatecreation | date: 'dd/MM/yyyy hh:mm' }}</li>
                                    <li><b>Mode Achat : </b> {{ showTicket.provenance}}</li>
                                    <li><b>Montant normal : </b> {{ showTicket.ticketmontantnormal}}</li>
                                    <li><b>Reduction : </b> {{ showTicket.ticketreduction}}</li>
                                    <li><b>Montant payé : </b> {{ showTicket.ticketmontantpayer}}</li>
                                    <li><b>Beneficiaire Nom : </b> {{ showTicket.beneficiairenom}}</li>
                                    <li><b>Beneficiaire Prenom : </b> {{ showTicket.beneficiaireprenom}}</li>
                                    <li><b>Beneficiaire Contact : </b> {{ showTicket.beneficiairetel}}</li>

                                    <li>
<!--                                        <a (click)="loadTicket()" style="cursor: pointer; color: white;"-->
<!--                                           class="myDashboard-logout">Fermer</a>-->
                                        <a (click)="shareTicketQrcode()"
                                           style="cursor: pointer; color: white; margin-left: 10px;"
                                           class="myDashboard-logout">TRANSFERER</a>
                                    </li>

                                </ul>
                            </div>

                            <div class="profile-content" *ngIf="sharedTicketQrcode">

                                <form [formGroup]="shareQrcodeForm">


                                    <div *ngIf="errorShareQrcode" class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <strong style="color: red;">{{errorShareQrcodeMessage}}</strong>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>TELEPHONE DU BENEFICIAIRE <span class="required">*</span></label>
                                                <input type="text" [formControl]="shareQrcodeForm.controls['beneficiaire']"
                                                       class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>VEUILLEZ SAISIR VOTRE MOT DE PASSE <span
                                                    class="required">*</span></label>
                                                <input type="password" [formControl]="shareQrcodeForm.controls['password']"
                                                       class="form-control" appMaxLength="5">

                                            </div>
                                        </div>


                                    </div>

                                    <li>
                                        <a (click)="cancelShareTicketQrcode()" style="cursor: pointer; color: white;"
                                           class="myDashboard-logout">ANNULER</a>
                                        <a (click)="saveShareTicketQrcode()"
                                           style="cursor: pointer; color: white; margin-left: 10px;"
                                           class="myDashboard-logout">VALIDER</a>
                                    </li>


                                </form>

                            </div>



                        </div>


                    </div>

                    <div *ngIf="showTicket.typeticket==='CARTE'" class="row align-items-center">



                        <div class="col-lg-12 col-md-12">
                            <div class="profile-content" *ngIf="!sharedTicketQrcode">
                                <ul class="contact-info">
                                    <li>
                                        <h3>{{ showTicket.event}}</h3>
                                    </li>
                                    <li>
                                        <p> <b>Categorie ticket : </b> {{ showTicket.caticket}}</p>
                                    </li>
                                    <li><b>Code ticket : </b>{{ showTicket.ticketcode }}</li>
                                    <li><b>Carte : </b>{{ showTicket.beneficiairecarte }}</li>


                                    <li><b>Ref achat : </b>{{ showTicket.achatcode }}</li>
                                    <li><b>Date achat : </b>{{ showTicket.ticketdatecreation | date: 'dd/MM/yyyy hh:mm' }}</li>
                                    <li><b>Mode Achat : </b> {{ showTicket.provenance}}</li>
                                    <li><b>Montant normal : </b> {{ showTicket.ticketmontantnormal}}</li>
                                    <li><b>Reduction : </b> {{ showTicket.ticketreduction}}</li>
                                    <li><b>Montant payé : </b> {{ showTicket.ticketmontantpayer}}</li>
                                    <li><b>Beneficiaire Nom : </b> {{ showTicket.beneficiairenom}}</li>
                                    <li><b>Montant Prenom : </b> {{ showTicket.beneficiaireprenom}}</li>
                                    <li><b>Montant Contact : </b> {{ showTicket.beneficiairetel}}</li>

                                    <li *ngIf="!sharedTicketCarte">
<!--                                        <a (click)="loadTicket()" style="cursor: pointer; color: white;"-->
<!--                                           class="myDashboard-logout">Fermer</a>-->
                                        <a (click)="shareTicketCarte()"
                                           style="cursor: pointer; color: white; margin-left: 10px;"
                                           class="myDashboard-logout">TRANSFERER</a>
                                    </li>
                                </ul>
                            </div>

                            <div class="profile-content" *ngIf="sharedTicketCarte">

                                <form [formGroup]="shareCarteForm">


                                    <div *ngIf="errorShareCarte" class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <strong style="color: red;">{{errorShareCarteMessage}}</strong>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>CARTE <span class="required">*</span></label>
                                                <input type="text" [formControl]="shareCarteForm.controls['beneficiaire']"
                                                       class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>VEUILLEZ SAISIR VOTRE MOT DE PASSE <span
                                                    class="required">*</span></label>
                                                <input type="password" [formControl]="shareCarteForm.controls['password']"
                                                       class="form-control">

                                            </div>
                                        </div>


                                    </div>

                                    <li>
                                        <a (click)="cancelShareTicketCarte()" style="cursor: pointer; color: white;"
                                           class="myDashboard-logout">ANNULER</a>
                                        <a (click)="saveShareTicketCarte()"
                                           style="cursor: pointer; color: white; margin-left: 10px;"
                                           class="myDashboard-logout">VALIDER</a>
                                    </li>


                                </form>

                            </div>



                        </div>


                    </div>

                </div>

            </div>
        </section>





















<div class="modal" *ngIf="isOpen">
    <div class="modal-content">
        <div class="modal-header">
            <span>{{ showTicket.event}}</span>
        </div>
        <div class="modal-body">

            <div *ngIf="showTicket.typeticket==='QR_CODE'" class="row align-items-center">


                <div class="col-lg-4 col-md-4">
                    <qrcode class="sensitive-data" [qrdata]="showTicket.ticketcode" [width]="356" [errorCorrectionLevel]="'M'"></qrcode>
                </div>


                <div class="col-lg-8 col-md-8">
                    <div class="profile-content" *ngIf="!sharedTicketQrcode">
                        <ul class="contact-info">
                            <li>
                                <h3>{{ showTicket.event}}</h3>
                            </li>
                            <li>
                                <p> <b>Categorie ticket : </b> {{ showTicket.caticket}}</p>
                            </li>
                            <li><b>Ref achat : </b>{{ showTicket.achatcode }}</li>
                            <li><b>Date achat : </b>{{ showTicket.ticketdatecreation | date: 'dd/MM/yyyy hh:mm' }}</li>
                            <li><b>Mode Achat : </b> {{ showTicket.provenance}}</li>
                            <li><b>Montant normal : </b> {{ showTicket.ticketmontantnormal}}</li>
                            <li><b>Reduction : </b> {{ showTicket.ticketreduction}}</li>
                            <li><b>Montant payé : </b> {{ showTicket.ticketmontantpayer}}</li>
                            <li><b>Beneficiaire Nom : </b> {{ showTicket.beneficiairenom}}</li>
                            <li><b>Beneficiaire Prenom : </b> {{ showTicket.beneficiaireprenom}}</li>
                            <li><b>Beneficiaire Contact : </b> {{ showTicket.beneficiairetel}}</li>

                            <li class="mt-5">
<!--                                <a (click)="loadTicket()"  style="cursor: pointer; color: white;"-->
<!--                                   class="myDashboard-logout btnCl">Fermer</a>-->
                                <a (click)="shareTicketQrcode()"
                                   style="cursor: pointer; color: white; margin-left: 10px;"
                                   class="myDashboard-logout btnCl">TRANSFERER</a>
                            </li>

                        </ul>
                    </div>

                    <div class="profile-content" *ngIf="sharedTicketQrcode">

                        <form [formGroup]="shareQrcodeForm">


                            <div *ngIf="errorShareQrcode" class="row">
                                <div class="col-lg-12 col-md-12">
                                    <strong style="color: red;">{{errorShareQrcodeMessage}}</strong>
                                </div>
                            </div>


                            <div class="row">
<!--                                <div class="col-12">-->
<!--                                    <div class="form-group">-->
<!--                                        <label  class="labelDis">PAYS BENEFICIAIRE <span class="required">*</span></label>-->
<!--                                        <select-->
<!--                                                class="form-control inpForm form-control  mt-2" [formControl]="shareQrcodeForm.controls['indicatifPays']">-->
<!--                                            <option *ngFor="let data of dataContry" [value]="data.indicatif">-->
<!--                                                <span class="flag-icon flag-icon-{{ data.code }}"></span>   {{data.country}}-->
<!--                                            </option>-->
<!--                                        </select>-->
<!--                                    </div>-->
<!--                                </div>-->
                                <div class="col-12  mt-3">
                                    <div class="form-group">
                                        <label>NUMERO DE COMPTE NBH <span class="required">*</span></label>
                                        <nz-input-group nzCompact>
                                            <nz-select [formControl]="shareQrcodeForm.controls['indicatifPays']">
                                                <nz-option  [nzLabel]="'+'+data.indicatif" [nzValue]="data.indicatif" *ngFor="let data of dContry"></nz-option>
                                            </nz-select>
                                            <input type="text" [formControl]="shareQrcodeForm.controls['beneficiaire']" class="form-control noBorder"  style="width: 69%;margin-top: 0;height: 36px !important;border-bottom-left-radius:0px !important;border-top-left-radius: 0px !important;" />
                                        </nz-input-group>

<!--                                        <input type="text" [formControl]="shareQrcodeForm.controls['beneficiaire']"-->
<!--                                               class="form-control mt-2">-->
                                    </div>
                                </div>
                                <div class="col-12 mt-3">
                                    <div class="form-group">
                                        <label>VEUILLEZ SAISIR VOTRE MOT DE PASSE <span
                                            class="required">*</span></label>
                                        <input type="password" [formControl]="shareQrcodeForm.controls['password']"
                                               class="form-control  mt-2 noBorder" style="height: 36px !important;width: 83%;">

                                    </div>
                                </div>


                            </div>

                            <li style="list-style: none;margin-top: 20px">
                                <a (click)="cancelShareTicketQrcode()" style="cursor: pointer; color: white;"
                                   class="myDashboard-logout btnCl">ANNULER</a>
                                <a (click)="saveShareTicketQrcode()"
                                   style="cursor: pointer; color: white; margin-left: 10px;"
                                   class="myDashboard-logout btnCl">VALIDER</a>
                            </li>


                        </form>

                    </div>



                </div>


            </div>

            <div *ngIf="showTicket.typeticket==='CARTE'" class="row align-items-center">



                <div class="col-lg-12 col-md-12">
                    <div class="profile-content" *ngIf="!sharedTicketQrcode">
                        <ul class="contact-info">
                            <li>
                                <h3>{{ showTicket.event}}</h3>
                            </li>
                            <li>
                                <p> <b>Categorie ticket : </b> {{ showTicket.caticket}}</p>
                            </li>
                            <li><b>Code ticket : </b>{{ showTicket.ticketcode }}</li>
                            <li><b>Carte : </b>{{ showTicket.beneficiairecarte }}</li>


                            <li><b>Ref achat : </b>{{ showTicket.achatcode }}</li>
                            <li><b>Date achat : </b>{{ showTicket.ticketdatecreation | date: 'dd/MM/yyyy hh:mm' }}</li>
                            <li><b>Mode Achat : </b> {{ showTicket.provenance}}</li>
                            <li><b>Montant normal : </b> {{ showTicket.ticketmontantnormal}}</li>
                            <li><b>Reduction : </b> {{ showTicket.ticketreduction}}</li>
                            <li><b>Montant payé : </b> {{ showTicket.ticketmontantpayer}}</li>
                            <li><b>Beneficiaire Nom : </b> {{ showTicket.beneficiairenom}}</li>
                            <li><b>Montant Prenom : </b> {{ showTicket.beneficiaireprenom}}</li>
                            <li><b>Montant Contact : </b> {{ showTicket.beneficiairetel}}</li>

                            <li *ngIf="!sharedTicketCarte">
<!--                                <a (click)="loadTicket()" style="cursor: pointer; color: white;"-->
<!--                                   class="myDashboard-logout">Fermer</a>-->
                                <a (click)="shareTicketCarte()"
                                   style="cursor: pointer; color: white; margin-left: 10px;"
                                   class="myDashboard-logout">TRANSFERER</a>
                            </li>
                        </ul>
                    </div>

                    <div class="profile-content" *ngIf="sharedTicketCarte">

                        <form [formGroup]="shareCarteForm">


                            <div *ngIf="errorShareCarte" class="row">
                                <div class="col-lg-12 col-md-12">
                                    <strong style="color: red;">{{errorShareCarteMessage}}</strong>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label  class="labelDis">PAYS BENEFICIAIRE <span class="required">*</span></label>
                                        <select
                                            class="form-control inpForm form-control  mt-2" [formControl]="shareCarteForm.controls['indicatifPays']">
                                            <option *ngFor="let data of dataContry" [value]="data.indicatif">
                                                <span class="flag-icon flag-icon-{{ data.code }}"></span>   {{data.country}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12  mt-3">
                                    <div class="form-group">
                                        <label>CARTE <span class="required">*</span></label>
                                        <input type="text" [formControl]="shareCarteForm.controls['beneficiaire']"
                                               class="form-control mt-2">
                                    </div>
                                </div>
                                <div class="col-12  mt-3">
                                    <div class="form-group">
                                        <label>VEUILLEZ SAISIR VOTRE MOT DE PASSE <span
                                            class="required">*</span></label>
                                        <input type="password" [formControl]="shareCarteForm.controls['password']"
                                               class="form-control mt-2">

                                    </div>
                                </div>


                            </div>

                            <li>
                                <a (click)="cancelShareTicketCarte()" style="cursor: pointer; color: white;"
                                   class="myDashboard-logout">ANNULER</a>
                                <a (click)="saveShareTicketCarte()"
                                   style="cursor: pointer; color: white; margin-left: 10px;"
                                   class="myDashboard-logout">VALIDER</a>
                            </li>


                        </form>

                    </div>



                </div>


            </div>

        </div>
        <div class="modal-footer">
            <button class="btn btnCl" style="width: 126px;" (click)="closeModal()">Fermer</button>
        </div>
    </div>
</div>
