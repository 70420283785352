import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'src/environments/environment';
import {EventwithoutkeyService} from '../home-billeterie/eventwithoutkey.service';
import {Spectateur, UserLogin} from '../spectateur';

@Component({
    selector: 'app-login-billeterie',
    templateUrl: './login-billeterie.component.html',
    styleUrls: ['./login-billeterie.component.scss']
})
export class LoginBilleterieComponent implements OnInit {


    data: UserLogin = new UserLogin();
    public loginForm;
    checking: boolean = false;
    checkingMessage: string = "";
    key;

    dContry: any = [];

    constructor(private _activatedRoute: ActivatedRoute, private toastr: ToastrService,
                public fb: UntypedFormBuilder, private router: Router,
                private gestserv: EventwithoutkeyService, private logger: NGXLogger) {
    }

    ngOnInit(): void {
        localStorage.clear();

        this._activatedRoute.queryParams.forEach((params: Params) => {
            if (params['key']) {
                this.key = params['key'];
            }
        });

        this.loginForm = this.fb.group({
            'indicatifPays': ['225', Validators.required],
            'username': [null, Validators.required],
            'password': [null, Validators.required]
        });
        this.loadPays();
    }

    loadPays() {

        this.gestserv.get(`/billeterie/session/getAllListPays`).subscribe(data => {

                switch (data.status) {
                    case 200: {

                        if (data.body.length) {

                            this.dContry = data.body.map((d) => {
                                return {
                                    ...d,
                                    country: d.paysNom,
                                    indicatif: d.paysIndicatif,
                                    code: d.paysCode,
                                }
                            });
                        }

                        break;
                    }
                    default : {

                    }
                }
            },
            error => {

                switch (error.status) {
                    case 401: {
                        //this.router.navigate(['/authentication/signin']);
                        break;
                    }
                    default: {
                        this.logger.trace(error);
                        break;
                    }
                }
            });
    }

    login() {
        this.checking = false;
        this.checkingMessage = "";
        this.logger.trace(this.loginForm);
        if (!this.loginForm.valid) {
            this.checkingMessage = "Veuillez renseigner votre identifiant et mot de passe";
            this.toastr.error('Echec!', "Veuillez renseigner votre identifiant et mot de passe");

        }


        this.checking = true;
        let request: any = this.loginForm.value;
        request.username = request.indicatifPays + request.username
        this.gestserv.post(request, "billeterie/:session/:login").subscribe(data => {
                this.logger.trace('INFOS CNX', data);
                this.checking = false;
                switch (data.status) {
                    case 200: {
                        this.toastr.success('', "Bienvenue sur NBH ! Connexion réussie avec succès.");
                        this.logger.trace('Retour login');
                        this.logger.trace(data.body);
                        let user: Spectateur = new Spectateur();
                        user = data.body;

                        localStorage.setItem(`${environment.keylocalstorage}`, JSON.stringify(user));
                        this.router.navigate(["/"]);

                        break;
                    }
                }
            },
            error => {
                //this.loader.close();

                this.logger.error('ERROR', error);
                switch (error.status) {
                    default: {
                        this.checkingMessage = "Login ou mot de passe incorrect !";
                        this.toastr.error('Echec!', 'Login ou mot de passe incorrect');

                        this.checking = false;
                        break;
                    }
                }
            });
    }

}
