
<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" id="backConcert" style="padding-top: 0; padding-bottom: 5px;height: 450px !important;">
    <section class="mission-area" style=";height: 450px !important;position: relative">
        <app-navbar></app-navbar>
<!--        <img src="{{imgDefault}}" alt="">-->
        <div class="container">
            <div class="row">
                <div class="col-lg-1 col-sm-12">

                </div>
                <div class="col-lg-8 col-sm-12"  style="float: right;justify-content: right;display: flex;">
                    <nz-input-group [nzSuffix]="suffixIconSearch">
                        <input type="text"  [(ngModel)]="query"
                               (input)="onSearch()"
                               autocomplete="off" nz-input placeholder="Rechercher..." />
                    </nz-input-group>
                    <ng-template #suffixIconSearch>
                        <span nz-icon nzType="search"></span>
                    </ng-template>
<!--                    <img src="assets/img/digit.png" style="float: right;width: 50%;margin-top: 67px" alt="">-->
                </div>
                <div class="col-12" style="justify-content: right;display: flex;">
                    <div *ngIf="filteredSuggestions.length > 0 && showSuggestions" class="suggestions">
                        <div *ngFor="let suggestion of filteredSuggestions" (click)="selectSuggestion(suggestion)">
                            <img src="{{suggestion.url}}" alt="" style="width: 72px;"> {{ suggestion.eventitre }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bleuCard">
            <nav class="navCat">
                <a href="javascript:void(0)" (click)="selectNav(data,i)" *ngFor="let data of dataCategorie;let i=index" id="navCatCheck{{i}}">{{data.categorielib}}</a>
            </nav>
        </div>
    </section>

</section>


<section style="padding-top: 10px; padding-bottom: 100px;  background-color: #006837;min-height: 100%;">
    <div class="container">
        <div class="event-header">
            <span class="event-title">LISTE DES EVENEMENTS NBH :</span>
        </div>

            <h1>{{libCat?.categorielib}}S</h1>
<!--            <div class="contain">-->
<!--                <img src="{{data.url}}" alt="Image" *ngFor="let data of rows?.events" (click)="toGoRoute(data)">-->
<!--            </div>-->
        <div class="container" *ngIf="!rows?.events">
            <p style="color: #fff;text-align: center;font-weight: bold;">Aucun évènement n'a été trouvé !</p>
        </div>

        <div class="container-box">
            <div class="image-box" *ngFor="let data of rows?.events" (click)="toGoRoute(data)">
                <img src="{{data.url}}" alt="Oumou Sangaré Concert Poster">
                <p class="description">{{data.eventitre}}</p>
            </div>
        </div>

        </div>
<section>


    <div *ngIf="!loadingCheck" class="d-flex justify-content-center">
        <div class="spinner-border" style="font-size: 30px;height: 120px;width: 120px;color: red;" role="status">
            <span class="sr-only"></span>
        </div>
    </div>


<!--<div *ngIf="loadingCheck">-->
<!--    <div *ngFor="let item of rows">-->
<!--        <app-course-billeterie [items]="item"></app-course-billeterie>-->
<!--        &lt;!&ndash;-->
<!--        <app-homeone-courses></app-homeone-courses>-->
<!--        &ndash;&gt;-->

<!--    </div>-->
<!--</div>-->


<!--<app-footer *ngIf="!(location == '/login' || location == '/register' || location == '/coming-soon')"></app-footer>-->




<!--<app-header-billeterie></app-header-billeterie>-->
<!--<br *ngIf="!loadingCheck">-->
<!--<br *ngIf="!loadingCheck">-->

<!--<div *ngIf="!loadingCheck" class="d-flex justify-content-center">-->
<!--    <div class="spinner-border" style="font-size: 30px;-->
<!--    height: px;-->
<!--    height: 120px;-->
<!--    width: 120px;-->
<!--    color: red;" role="status">-->
<!--        <span class="sr-only"></span>-->
<!--    </div>-->
<!--</div>-->

<!--<div *ngIf="loadingCheck" class="events-area pt-100 pb-70">-->
<!--    <div class="container">-->
<!--        <div class="row">-->
<!--            <div *ngFor="let item of rows" class="col-md-3 mb-30">-->
<!--                <div class="single-events-box_">-->
<!--                    <div class="events-box_">-->
<!--                        <div class="events-image_">-->
<!--                            <img [src]="item.url" alt="image">-->
<!--                        </div>-->
<!--                        <div class="events-content_">-->
<!--                            <div class="content_">-->
<!--                                <h3><a (click)="goToRouting('/check-event?key='+item.eventcode)">{{item.eventitre}}</a></h3>-->
<!--                                <p>-->
<!--                                    <span class="location"><i class='bx bx-time'></i> {{item.eventdebut | date: 'dd/MM/yyyy hh:mm'}}</span>-->
<!--                                </p>-->
<!--                                <h6 class="location"><i class='bx bx-map'></i> {{item.eventlieu}}</h6>-->
<!--                                <div class="text-center">-->
<!--                                    <a (click)="goToRouting('/check-event?key='+item.eventcode)" class="join-now-btn">Voir plus</a>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->


<!--    <section>-->

<!--        <app-footer></app-footer>-->
<!--    </section>-->


