import {HttpClient, HttpBackend, HttpResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Observable, throwError} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Spectateur} from '../spectateur';
import {decryptData, postDataCrypte} from "../../../core/config";
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class EventwithkeyService {


    private url = `${environment.apiUrl}`;
    private urlSms = `${environment.apiUrlSms}`;

    // parameter/tva/getall

    authorization: string;

    constructor(private http: HttpClient, handler: HttpBackend,
                private logger: NGXLogger) {

        this.http = new HttpClient(handler);
        let u = new Spectateur();
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        logger.trace("KEYSTORAGE-VALUE => " + this.authorization);

        if (u != null) {
            this.authorization = u.token;
            logger.trace("KEYSTORAGE => " + this.authorization);
        }
    }

    post(value: any, path: string, key): Observable<HttpResponse<any>> {
        const body = JSON.stringify(value);

        const HEADER_OPTIONSs = new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': key
        });
        return this.http.post(`${this.url}${path}`, {data: postDataCrypte(value)}, {
            headers: HEADER_OPTIONSs,
            observe: 'response',
        }).pipe(
            map((res: any) => {
                console.log("response::::", (res))
                let rep = decryptData(res.body.data)
                res.body = rep
                return (res);
            }),
            catchError((error) => {
                console.log("error ::::", error)
                let errorMessage
                switch (error.status) {
                    // case 201 || 200 || 400 || 422:
                    //     errorMessage = responseDecode(error.error.response, this.env.environment.config.exchange_key)
                    //   break;
                    case 201:
                        errorMessage = ""
                        break;
                    case 200:
                        errorMessage = ""
                        break;
                    case 422:
                        errorMessage = ""
                        break;
                    case 401:
                        document.location.href = "public/expire"
                        break;
                    default:
                        errorMessage = {message: error.statusText, err: error.error}
                        break;
                }
                return throwError({
                    status: error.status,
                    ok: error.ok,
                    statusText: error.statusText,
                    error: errorMessage
                });
            })
        );
    }
    get(url: string, key): Observable<HttpResponse<any>> {
        const HEADER_OPTIONS = new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization':key
        });
        return this.http.get(`${environment.apiUrl}${url}`, {
            headers: HEADER_OPTIONS,
            // headers: Header.set('no-encoded-request', 'true'),
            observe: 'response'
        }).pipe(
            map((res: any) => {
                const response = JSON.parse(JSON.stringify(res.body)).response
                if (!HEADER_OPTIONS.has('no-encoded-request'))
                    res = res.clone({
                        body: response
                    })
                // this.cache[url] = res
                // this.emitCache()
                let rep = decryptData(res.body.data)
                res.body = rep
                return res
            }),
            catchError((error) => {
                let errorMessage
                switch (error.status) {
                    // case 201 || 200 || 400 || 422:
                    //     errorMessage = responseDecode(error.error.response, this.env.environment.config.exchange_key)
                    //   break;
                    case 201:
                        errorMessage = ""
                        break;
                    case 200:
                        errorMessage = ""
                        break;
                    case 400:
                        errorMessage = ""
                        break;
                    case 422:
                        errorMessage = ""
                        break;
                    case 401:
                        document.location.href = "public/expire"
                        break;
                    default:
                        errorMessage = {message: error.statusText}
                        break;
                }
                return throwError({
                    status: error.status,
                    ok: error.ok,
                    statusText: error.statusText,
                    error: errorMessage
                });
            })
        );
    }
    public post_save(value: any, path: string, key): Observable<HttpResponse<any>> {
        return this.http.post(this.url + '' + path, JSON.stringify(value), {
            observe: 'response',
            headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
                .set('content-Type', 'application/json')
                .set('Authorization', key)
        });
    }

    public postSender(value: any, path: string): Observable<HttpResponse<any>> {
        return this.http.post(this.urlSms + '' + path, (value), {
            observe: 'response',
            headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
                .set('content-Type', 'application/json')
        });
    }

    public put(value: any, path: string): Observable<HttpResponse<any>> {
        return this.http.put(this.url + '' + path, JSON.stringify(value), {
            observe: 'response',
            headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
                .set('content-Type', 'application/json')
                .set('Authorization', this.authorization)
        });
    }

    delete(path: string): Observable<HttpResponse<any>> {
        return this.http.delete(this.url + '' + path, {
            observe: 'response',
            headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
                .set('content-Type', 'application/json')
                .set('Authorization', this.authorization)
        });
    }

    get_save(path: string, key): Observable<HttpResponse<any>> {
        return this.http.get(this.url + '' + path, {
            observe: 'response',
            headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
                .set('content-Type', 'application/json')
                .set('Authorization', key)
        });
    }


}
