import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {EventwithoutkeyService} from "../eventwithoutkey.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

declare let $: any;

@Component({
    selector: 'app-boxes-billeterie',
    templateUrl: './boxes-billeterie.component.html',
    styleUrls: ['./boxes-billeterie.component.scss']
})
export class BoxesBilleterieComponent implements OnInit, AfterViewInit {
    imgDefault: string = "assets/img/pub-nbh.png";
    // imgDefault: string = "assets/img/COMMERCIAL NBH.jpg";
    dataEvent: any = []
    dataItems: any = []
    srcValue: string = "";
    dataFreeEvent: any = [
        "https://cdn.rawgit.com/huijing/filerepo/gh-pages/lw1.jpg",
        "https://i.pinimg.com/236x/7c/39/76/7c39762f38650923ef6bf47f2e1d527f.jpg",
        "https://i.pinimg.com/236x/7c/39/76/7c39762f38650923ef6bf47f2e1d527f.jpg",
        "https://thumbs.dreamstime.com/b/bench-park-beautiful-morning-light-background-48336462.jpg",
        "https://png.pngtree.com/thumb_back/fw800/background/20240401/pngtree-blue-sneaker-made-of-fabric-with-a-white-sole-on-a-image_15649145.jpg"
    ]
    index: number = 0;
    indiceActif: number = 0
    @ViewChild('nextButton', {static: false}) nextButton!: ElementRef;

    constructor(private gestserv: EventwithoutkeyService, private router: Router, private toastr: ToastrService,) {
        //this.loadEventLibre();
    }

    ngOnInit(): void {

        // $('#_box_pub').css({
        //     'background-image': 'url('+this.imgDefault+')',
        //     'background-size': 'contain', // or 'contain', 'auto', etc.
        //     'background-repeat': 'no-repeat',
        //     'background-position': 'center'
        // });
    }

    ngAfterViewInit() {
    }


    loadEventLibre() {
        this.dataFreeEvent = [];
        this.gestserv.get("/billeterie/alleventbycategorie/XQAJUK11").subscribe(data => {
                switch (data.status) {
                    case 200: {

                        this.dataEvent = data.body;
                        this.dataFreeEvent = [
                            {
                                "eventcode": "ZPEC37",
                                "eventitre": "CONCERT OUMOU SANGARE",
                                "categorie": "VVIP",
                                "eventdebut": "2024-06-21T18:44:00.000+0000",
                                "eventlieu": "Sofitel Hôtel Ivoire",
                                "eventmaxticket": 0,
                                "eventachete": 0,
                                "caticketarif": 0,
                                "caticketlib": "VVIP",
                                "caticketcode": "ZSOC2588",
                                "typeplacecode": "ZSOC2588",
                                "url": this.imgDefault
                            }, ...data.body];
                        setTimeout(() => {
                            const dataSlides = document.querySelectorAll('.slider-wrapper .slide');
                            dataSlides[0]?.classList.add('active');
                            $('#_box').css({
                                'background-image': 'url(' + this.dataFreeEvent[0].url + ')',
                                'background-size': 'cover'
                            });
                        }, 2000)
                        break;
                    }
                    default: {
                        $('#_box').css({
                            'background-image': 'url(' + this.imgDefault + ')',
                            'background-size': 'cover'
                        });
                    }


                }
            },
            error => {
                switch (error.status) {
                    case 401: {
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });
    }

    achetTicket() {
        let liElement = document.querySelector('li.slide.active');
        let imgElement = liElement.querySelector('img');
        this.srcValue = imgElement.getAttribute('src');
        let _d = this.dataFreeEvent.find((e) => e.url == this.srcValue);
        if (this.srcValue == this.imgDefault) {
            this.toastr.warning( 'Ce évènement n\'est pas disponible !');
            return;
        }
        // check-event?key=ZPEC37
        this.router.navigateByUrl('/check-event?key=' + _d.eventcode);
    }

    playEnvent() {
        let me = this;

        window.addEventListener('load', (event) => {
            const sliderWrapper = document.querySelector('.slider-wrapper');
            const slides = document.querySelectorAll('.slider-wrapper .slide');
            const slidesToShow = 1;//me.dataFreeEvent.length; // Number of slides to show
            const slideHeight = 180; // Image height (100px) + margin (10px top and bottom)
            let currentIndex = 0;

            function showSlide(index) {
                const offset = -index * slideHeight;
                //@ts-ignore
                sliderWrapper.style.transform = `translateY(${offset}px)`;
                slides.forEach((slide, i) => {
                    slide.classList.remove('active');
                    if (i >= index && i < index + slidesToShow) {
                        slide.classList.add('active');
                        let liElement = document.querySelector('li.slide.active');

                        // Récupérer l'élément img à l'intérieur de l'élément li
                        let imgElement = liElement.querySelector('img');
                        // Récupérer le contenu de l'attribut src
                        me.srcValue = imgElement.getAttribute('src');
                        $('#_box').css({'background-image': 'url(' + me.srcValue + ')', 'background-size': 'cover'});
                    }
                });
            }

            document.querySelector('.prev').addEventListener('click', function () {
                if (currentIndex > 0) {
                    currentIndex--;
                } else {
                    currentIndex = Math.max(slides.length - slidesToShow, 0); // Wrap around to the last set
                }
                showSlide(currentIndex);
            });

            document.querySelector('.next').addEventListener('click', function () {
                if (currentIndex < slides.length - slidesToShow) {
                    currentIndex++;
                } else {
                    currentIndex = 0; // Wrap around to the first set
                }
                showSlide(currentIndex);
            });

            // Initialize the first set of slides as active
            showSlide(currentIndex);
        });
        // ===============================


        // carousel.addEventListener("mousedown", dragStart);
        // carousel.addEventListener("mousemove", dragging);
        // document.addEventListener("mouseup", dragStop);
        // carousel.addEventListener("scroll", infiniteScroll);
        // wrapper.addEventListener("mouseenter", () => clearTimeout(timeoutId));
        // wrapper.addEventListener("mouseleave", autoPlay);
    }

    next() {
        let me = this;
        const sliderWrapper = document.querySelector('.slider-wrapper');
        const slides = document.querySelectorAll('.slider-wrapper .slide');
        const slidesToShow = 1;//me.dataFreeEvent.length; // Number of slides to show
        const slideHeight = 180; // Image height (100px) + margin (10px top and bottom)

        slides.forEach((li, index) => {
            if (li.classList.contains('active')) {
                this.index = index + 1;
            }
        });
        if (this.index >= slides.length) {
            this.index = 0; // Wrap around to the first set
        }
        const offset = -this.index * slideHeight;
        //@ts-ignore
        sliderWrapper.style.transform = `translateY(${offset}px)`;
        slides.forEach((slide, i) => {
            slide.classList.remove('active');
            if (i >= this.index && i < this.index + slidesToShow) {
                slide.classList.add('active');
                let liElement = document.querySelector('li.slide.active');

                // Récupérer l'élément img à l'intérieur de l'élément li
                let imgElement = liElement.querySelector('img');
                // Récupérer le contenu de l'attribut src
                me.srcValue = imgElement.getAttribute('src');
                $('#_box').css({'background-image': 'url(' + this.srcValue + ')', 'background-size': 'cover'});
            }
        });
    }

    prev() {
        let me = this;
        const sliderWrapper = document.querySelector('.slider-wrapper');
        const slides = document.querySelectorAll('.slider-wrapper .slide');
        const slidesToShow = 1;//me.dataFreeEvent.length; // Number of slides to show
        const slideHeight = 180; // Image height (100px) + margin (10px top and bottom)

        slides.forEach((li, index) => {
            if (li.classList.contains('active')) {
                this.index = index > 0 ? index - 1 : slides.length - 1;
            }
        });

        if (this.index == 0) {
            //  this.index = slides.length - 1; // Wrap around to the first set
        }
        const offset = -this.index * slideHeight;
        //@ts-ignore
        sliderWrapper.style.transform = `translateY(${offset}px)`;
        slides.forEach((slide, i) => {
            slide.classList.remove('active');
            if (i >= this.index && i < this.index + slidesToShow) {
                slide.classList.add('active');
                let liElement = document.querySelector('li.slide.active');

                // Récupérer l'élément img à l'intérieur de l'élément li
                let imgElement = liElement.querySelector('img');
                // Récupérer le contenu de l'attribut src
                me.srcValue = imgElement.getAttribute('src');
                $('#_box').css({'background-image': 'url(' + this.srcValue + ')', 'background-size': 'cover'});
            }
        });
    }
}
