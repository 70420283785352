<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 350px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color: #006837 !important;height: 350px !important;">
        <app-navbar></app-navbar>
        <div class="container" style="display: flex;justify-content: center">
            <h1 style="margin-top: auto;margin-bottom: auto;color: #0B2154 !important;font-family: Montserrat;font-size: 30px;line-height: 0.9;">Conditions d'utilisation</h1> <img class="imgApropos" src="{{imgPay}}">
        </div>
    </section>
</section>


<div class="courses-details-area pb-70" style="background-color: #0B2154;min-height: 100%;">
    <div class="container">

        <div class="row">
            <div class="col-lg-2">

            </div>

            <div class="col-lg-8">



                <div class="courses-details-desc">
                    <h3 style="text-transform: uppercase">Conditions d'Utilisation de la Plateforme NBH</h3>
                    <p>
                      <b> 1. &nbsp; Introduction</b>  Les présentes conditions d'utilisation (le "Contrat") régissent l'accès et l'utilisation des services fournis par la plateforme NBH (Nouvelle Billetterie Hybride) accessible à l'adresse
                        <a href="www.nbh.ci" style="color: #fff">www.nbh.ci</a>.
                        <br> En utilisant ce site, vous acceptez ces conditions, notre politique de confidentialité, ainsi que les lois et règlements applicables.
                    </p>
                    <p>
                     <b> 2. &nbsp; Modifications</b>   La NBH se réserve le droit de modifier ces conditions à tout moment sans préavis.
                        Il est recommandé de consulter régulièrement les conditions pour être informé des éventuelles modifications.
                    </p>
                    <p>
                      <b> 3. &nbsp; Utilisation</b>  autorisée L'accès à la plateforme est accordé pour un usage personnel et non commercial. Toute utilisation à des fins commerciales, y compris la revente de billets, est interdite.
                    </p>
                    <p><b> 4. Utilisation</b> interdite Il est interdit de :</p>

                    <ul>
                        <li>Utiliser des robots, scripts ou autres dispositifs automatisés pour accéder au contenu du site.</li>
                        <li>Acheter des billets en grande quantité pour les revendre.</li>
                        <li>Contourner les mesures de sécurité du site.</li>
                        <li>Publier ou transmettre des contenus illégaux ou nuisibles.</li>

                    </ul>

                    <p>
                      <b>5. &nbsp; Frais de transaction L'achat</b>   de billets peut entraîner des frais supplémentaires, tels que des frais de commodité et de traitement. Ces frais seront clairement indiqués lors de l'achat.
                    </p>

                    <p>
                     <b>6. &nbsp; Liens vers d'autres sites Le site </b>   peut contenir des liens vers des sites tiers. La NBH n'est pas responsable du contenu ou des pratiques de ces sites.
                    </p>

                    <p>
                       <b>7. &nbsp; Communications électroniques </b> En utilisant le site, vous consentez à recevoir des communications électroniques de la part de la NBH, y compris des notifications concernant vos achats et des publicités sur nos produits et services.
                    </p>

                    <p>
                       <b>8. &nbsp; Propriété intellectuelle</b>  La NBH détient les droits de propriété intellectuelle sur tout le contenu de la plateforme. Vous ne pouvez pas reproduire, modifier ou distribuer ce contenu sans autorisation préalable.
                    </p>

                    <p>
                      <b>9.&nbsp; Limitation de responsabilité </b>  La plateforme est fournie "telle quelle" sans garantie d'aucune sorte. La NBH ne sera pas responsable des dommages résultant de l'utilisation du site ou de l'incapacité à l'utiliser.
                    </p>

                    <p>
                      <b> 10.&nbsp; Équipement</b>  Vous êtes responsable des équipements nécessaires pour accéder au site, y compris les frais de connexion.
                    </p>
                    <p>
                       <b>11.&nbsp; Respect des droits d'auteur </b> La NBH respecte les droits d'auteur et agira en cas de violation présumée. Les titulaires de droits peuvent soumettre une notification de contrefaçon conformément à la loi ivoirienne.
                    </p>
                    <p>
                      <b>12. &nbsp;Contenu de tiers </b>  Le contenu fourni par des tiers sur le site n'est pas nécessairement approuvé par la NBH. Nous ne garantissons pas l'exactitude de ce contenu.
                    </p>
                    <p>
                      <b> 13.  &nbsp;Infractions</b>  En cas de violation des conditions, la NBH peut annuler des transactions et restreindre l'accès au site sans préavis.
                    </p>
                    <p>
                      <b> 14.&nbsp; Services Mobiles</b>  Les services mobiles fournis par la NBH sont gratuits, mais des frais de votre opérateur peuvent s'appliquer.
                    </p>
                    <p>
                       <b>15. Contact</b>  Pour signaler des violations ou pour toute autre question, veuillez contacter NBH à l'adresse :
                        <a href="javascript:void(0)" style="color: #fff">infocommerciale@nbh.ci.</a>
                    </p>
                </div>

            </div>
            <div class="col-lg-2">

            </div>
        </div>
    </div>
</div>
