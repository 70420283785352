import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {environment} from 'src/environments/environment';
import {EventwithkeyService} from '../../body/home-billeterie/eventwithkey.service';
import {Spectateur} from '../../body/spectateur';

declare let $: any;

@Component({
    selector: 'app-header-billeterie',
    templateUrl: './header-billeterie.component.html',
    styleUrls: ['./header-billeterie.component.scss']
})
export class HeaderBilleterieComponent implements OnInit {
    @Input() customClass: string = '';
    isConnect: boolean = false;
    notif: any;

    constructor(private router: Router, private gestserv: EventwithkeyService, private logger: NGXLogger) {
    }

    ngOnInit(): void {
        this.checkNotification();
    }

    classApplied = false;


    checkNotification() {

        let u = new Spectateur();
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        if (u != null) {
            this.isConnect = true;
            // this.loadNotification(u.spectateurcode);
        } else {
            this.isConnect = false;
        }
    }


    loadNotification(code, key) {
        this.gestserv.get("/billeterie/notification/byspectateurcheck/" + code, key).subscribe(data => {
                switch (data.status) {
                    case 200: {

                        this.notif = data.body;
                        this.logger.trace(this.notif);
                        break;
                    }


                }
            },
            error => {
                switch (error.status) {
                    case 401: {
                        //this.router.navigate(['/authentication/signin']);
                        this.notif = {};
                        break;
                    }
                    default: {
                        this.notif = {};
                        this.logger.trace(error);

                        break;
                    }
                }
            });
    }


    goToRouting(path: string) {
        this.router.navigateByUrl(path);
    }

    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;

    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;

    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

}
