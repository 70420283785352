<section class="container secLigne secLigneH">

    <div class="container">
        <div class="instructor-slides">
            <div class="row">
                <div class="col-lg-2 ant-col-xs-2">
                    <button type="button"
                            class="btn btn-warning text-nowrap d-inline-flex position-relative me-4 rubBtn">
                        <b>{{dataItems?.categorie ? dataItems?.categorie : 'Evènement'}}</b>
                    </button>
                </div>
                <div class="col-lg-10 ant-col-xs-15">
                    <hr class="ligneCat">
                </div>
                <div class="col-12 mt-3">
                    <div class="carousel-container" *ngIf="dataSilde.length">

                        <button  class="swiper-button-prev button-prev" [disabled]="isBeginning" #prevBtn>⟨</button>
                        <swiper
                            [slidesPerView]="slidesPerView"
                            [spaceBetween]="5"
                            [navigation]="true"
                            [loop]="false"
                            (swiper)="onSwiper($event)"
                            (slideChange)="onSlideChange($event)"
                            [pagination]="{ prevEl: prevBtn, nextEl: nextBtn }"
                            class="mySwiper"
                        >
                            <ng-container *ngFor="let slide of dataSilde;let i=index">
                                <ng-template swiperSlide >
                                    <div (click)="toGoRoute(slide)" data-id="{{i+2}}"  class="slide-card">
                                        <img [src]="slide.image" alt="Event Image">
                                        <p style="color: #000;margin-top: 2%;">{{ slide.title }}</p>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </swiper>
                        <button  [disabled]="isEnd" class="swiper-button-next" #nextBtn>⟩</button>
                    </div>





                    <!--                                     <ng-image-slider *ngIf="dataSilde.length"-->
                    <!--                                     (imageClick)="toGoRoute($event)"-->
                    <!--                                     [imagePopup]="false"-->
                    <!--                                     [imageSize]="{width: '300px', height: '290px', space: 4}"-->
                    <!--                                     [images]="dataSilde" #nav>-->
                    <!--                                     </ng-image-slider>-->

                    <!--                    <ng-image-slider-->
                    <!--                        [images]="dataSilde"-->
                    <!--                        [imageSize]="{ width: '300px', height: '290px', space: 4 }"-->
                    <!--                        (imageClick)="toGoRoute($event)">-->
                    <!--                    </ng-image-slider>-->




                </div>
            </div>
        </div>
    </div>









    <!--    <div class="concert-carousel">-->
    <!--        <button class="carousel-control prev" (click)="prevSlide()">-->
    <!--            &lt;-->
    <!--        </button>-->
    <!--        <div class="carousel-wrapper">-->
    <!--            <div class="carousel">-->
    <!--                <div class="carousel-itemm" *ngFor="let data of dataItems.events">-->
    <!--                    <img src="{{data.image}}" alt="{{ data.eventitre }}">-->
    <!--                    <div class="concert-info">-->
    <!--                        <h3>{{ data.eventitre }}</h3>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <button class="carousel-control next" (click)="nextSlide()">-->
    <!--            &gt;-->
    <!--        </button>-->
    <!--    </div>-->


    <!--        <div class="section">-->
    <!--            <h2>{{ 'section.title' }}</h2>-->
    <!--            <div class="carousel-container">-->
    <!--                <button class="carousel-control prev" (click)="prevSlide('section')">-->
    <!--                    &lt;-->
    <!--                </button>-->
    <!--                <div class="carousel-wrapper">-->
    <!--                    <div class="carousel" >-->
    <!--                        <div class="carousel-itemm" *ngFor="let concert of dataItems.events">-->
    <!--                            <img [src]="concert.image" alt="{{ concert.title }}">-->
    <!--                            <div class="concert-info">-->
    <!--                                <h3>{{ concert.title }}</h3>-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--                <button class="carousel-control next" (click)="nextSlide('section')">-->
    <!--                    &gt;-->
    <!--                </button>-->
    <!--            </div>-->
    <!--        </div>-->






    <section>
