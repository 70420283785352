import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NavigationCancel, NavigationEnd, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {CinetpayService} from './cinetpay.service';
import {EventwithoutkeyService} from './eventwithoutkey.service';
import {OwlOptions} from "ngx-owl-carousel-o";
import {filter} from "rxjs/operators";

declare let $: any;

@Component({
    selector: 'app-home-billeterie',
    templateUrl: './home-billeterie.component.html',
    styleUrls: ['./home-billeterie.component.scss']
})
export class HomeBilleterieComponent implements OnInit {

    instructorSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: false,
        margin: 30,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ],
        responsive: {

            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            1200: {
                items: 3
            }

        }
    }
    rows: any = [];
    dataCatEvents: any = [];
    dataCatEventsSecond: any = [];
    dataEvent: any = [];
    loadingCheck: any = false;
    loading: boolean = false;
    location: any;
    routerSubscription: any;


    imgDefault: string = "../../../../../../assets/img/pub-nbh.png";
    dataItems: any = []
    srcValue: string = "";
    dataFreeEvent: any = [
        "https://cdn.rawgit.com/huijing/filerepo/gh-pages/lw1.jpg",
        "https://i.pinimg.com/236x/7c/39/76/7c39762f38650923ef6bf47f2e1d527f.jpg",
        "https://i.pinimg.com/236x/7c/39/76/7c39762f38650923ef6bf47f2e1d527f.jpg",
        "https://thumbs.dreamstime.com/b/bench-park-beautiful-morning-light-background-48336462.jpg",
        "https://png.pngtree.com/thumb_back/fw800/background/20240401/pngtree-blue-sneaker-made-of-fabric-with-a-white-sole-on-a-image_15649145.jpg"
    ]
    index: number = 0;
    indiceActif: number = 0
    @ViewChild('nextButton', {static: false}) nextButton!: ElementRef;
    currentLibreImg: string = "";
    textEventFree: string = "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum. ";
    eventitre: string = "";

    constructor(private router: Router, private gestserv: EventwithoutkeyService,
                private logger: NGXLogger,
                private cinetpay: CinetpayService) {
    }

    ngOnInit(): void {
        this.loadCategorie();
        this.recallJsFuntions();
        //this.testCinetpay();
        this.loadEventLibre();
    }


    async testCinetpay() {
        var result = await this.cinetpay.seamless(100, "", "");
    }


    goToRouting(path: string) {
        this.router.navigateByUrl(path);
    }

    loadEvent(dataCat: any = []) {
        this.rows = [];
        this.dataCatEvents = [];
        this.dataCatEventsSecond = [];
        this.loadingCheck = false;
        this.gestserv.get("/billeterie/allevent").subscribe(data => {
                switch (data.status) {
                    case 200: {
                        this.loading = true;
                        if (data.body.length) {
                            this.rows.push(data.body[0]);
                        }
                        if (this.rows.length) {
                            this.dataEvent = this.rows[0].events;
                        }
                        console.log("this.rows ",this.rows)
                        console.log("this.dataEvent ",this.dataEvent)
                        console.log("data.body event ",data.body)
                        let eventData = dataCat.map((d) => {
                            let evn = data.body.filter((e) => e.categorie == d.categorielib) //categorielib
                            return {
                                categorie: d.categorielib,
                                events: evn.length ? evn[0].events : []
                            }
                        })
                        console.log("eventData1 =====",eventData)

                        this.dataCatEvents = eventData.filter((d) => d.categorie.toLowerCase().includes("con") || d.categorie.toLowerCase().includes("cine") || d.categorie.toLowerCase().includes("hum"))
                        this.dataCatEventsSecond = eventData.filter((d) => !d.categorie.toLowerCase().includes("con") && !d.categorie.toLowerCase().includes("cine") && !d.categorie.toLowerCase().includes("hum"))
                        console.log("this.dataCatEvents =====",this.dataCatEvents)
                        this.loadingCheck = true;
                        setTimeout(() => {
                            this.selectNav(0);
                        }, 1);

                        break;
                    }
                    default: {
                        this.loadingCheck = true;
                    }
                }
            },
            error => {
                switch (error.status) {
                    case 401: {
                        //this.router.navigate(['/authentication/signin']);
                        this.loadingCheck = true;
                        break;
                    }
                    default: {
                        this.loadingCheck = true;
                        this.logger.trace(error);
                        this.loading = true;
                        break;
                    }
                }
            });
    }

    selectNav(i: number) {
        for (let j = 0; j < 7; j++) {
            $('#nav' + j).removeClass("active");
        }
        this.dataEvent = this.rows[i].events;
        $('#nav' + i).addClass('active');
    }


    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    loadCategorie() {
        this.gestserv.get("billeterie/:event/:getAllcategorieEvent").subscribe(data => {
                switch (data.status) {
                    case 200: {
                        this.loadEvent(data.body);
                        break;
                    }
                    default: {
                        this.loadingCheck = true;
                        break;
                    }
                }
            },
            error => {
                switch (error.status) {
                    case 401: {
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });
    }

    loadingAllImageGratuit() {
        this.gestserv.get("/billeterie/allpub/NKMXCF21").subscribe(data => {

                switch (data.status) {

                    case 200: {

                        this.loading = true;


                        break;
                    }
                    default: {

                        break;
                    }
                }
            },
            error => {
                switch (error.status) {

                    default: {

                        break;
                    }
                }
            });
    }

    loadEventLibre() {
        this.dataFreeEvent = [];
        this.gestserv.get("/billeterie/alleventbycategorie/XQAJUK11").subscribe(data => {
                switch (data.status) {
                    case 200: {

                        this.dataEvent = data.body;
                        let mod = 0;// Math.round(data.body.length / 2);
                        this.dataFreeEvent = [...data.body];
                        // this.currentLibreImg =
                        setTimeout(() => {
                            const dataSlides = document.querySelectorAll('.slider-wrapper .slide');
                            dataSlides[mod]?.classList.add('active');
                            this.currentLibreImg = this.dataFreeEvent[mod]?.url;
                            this.eventitre = this.dataFreeEvent[mod]?.eventitre;
                            // this.textEventFree = this.dataFreeEvent[0].event;
                            // {
                            //     "eventcode": "PALA70",
                            //     "eventitre": "OUMOU SANGARE EN LIVE",
                            //     "categorie": "VVIP",
                            //     "eventdebut": "2024-07-08T14:19:00.000+0000",
                            //     "eventlieu": "INSTITUT FRANCAIS ABIDJAN",
                            //     "eventmaxticket": 500,
                            //     "eventachete": 0,
                            //     "caticketarif": 10000,
                            //     "caticketlib": "VVIP",
                            //     "caticketcode": "EYFS3137",
                            //     "typeplacecode": "EYFS3137",
                            //     "url": "https://nbh-organisateur.com:4848/digitalbackendorganisateur/event/image/download/ANYH3134"
                            // }
                            $('#_box').css({
                                'background-image': 'url(' + this.dataFreeEvent[mod].url + ')',
                                'background-size': 'cover'
                            });
                        }, 2000)
                        break;
                    }
                    default: {
                        $('#_box').css({
                            'background-image': 'url(' + this.imgDefault + ')',
                            'background-size': 'cover'
                        });
                    }
                }
            },
            error => {
                switch (error.status) {
                    case 401: {
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });
    }

    achetTicket() {
        let liElement = document.querySelector('li.slide.active');
        let imgElement = liElement.querySelector('img');
        this.srcValue = imgElement.getAttribute('src');
        let _d = this.dataFreeEvent.find((e) => e.url == this.srcValue);
        if (this.srcValue == this.imgDefault) {
            //this.toastr.warning( 'Ce évènement n\'est pas disponible !');
            return;
        }
        // check-event?key=ZPEC37
       this.router.navigateByUrl('/check-event?key=' + _d.eventcode);

        //   this.router.navigateByUrl('/event-parc?key=' + _d.eventcode);
    }

    playEnvent() {
        let me = this;

        window.addEventListener('load', (event) => {
            const sliderWrapper = document.querySelector('.slider-wrapper');
            const slides = document.querySelectorAll('.slider-wrapper .slide');
            const slidesToShow = 1;//me.dataFreeEvent.length; // Number of slides to show
            const slideHeight = 180; // Image height (100px) + margin (10px top and bottom)
            let currentIndex = 0;

            function showSlide(index) {
                me.eventitre = me.dataFreeEvent[index].eventitre;
                const offset = -index * slideHeight;
                //@ts-ignore
                sliderWrapper.style.transform = `translateY(${offset}px)`;
                slides.forEach((slide, i) => {
                    slide.classList.remove('active');
                    if (i >= index && i < index + slidesToShow) {
                        slide.classList.add('active');
                        let liElement = document.querySelector('li.slide.active');

                        // Récupérer l'élément img à l'intérieur de l'élément li
                        let imgElement = liElement.querySelector('img');
                        // Récupérer le contenu de l'attribut src
                        me.srcValue = imgElement.getAttribute('src');
                        me.currentLibreImg = me.srcValue
                        $('#_box').css({'background-image': 'url(' + me.srcValue + ')', 'background-size': 'cover'});
                    }
                });
            }

            document.querySelector('.prev').addEventListener('click', function () {
                if (currentIndex > 0) {
                    currentIndex--;
                } else {
                    currentIndex = Math.max(slides.length - slidesToShow, 0); // Wrap around to the last set
                }
                showSlide(currentIndex);
            });

            document.querySelector('.next').addEventListener('click', function () {
                if (currentIndex < slides.length - slidesToShow) {
                    currentIndex++;
                } else {
                    currentIndex = 0; // Wrap around to the first set
                }
                showSlide(currentIndex);
            });

            // Initialize the first set of slides as active
            showSlide(currentIndex);
        });
        // ===============================


        // carousel.addEventListener("mousedown", dragStart);
        // carousel.addEventListener("mousemove", dragging);
        // document.addEventListener("mouseup", dragStop);
        // carousel.addEventListener("scroll", infiniteScroll);
        // wrapper.addEventListener("mouseenter", () => clearTimeout(timeoutId));
        // wrapper.addEventListener("mouseleave", autoPlay);
    }

    next_n() {
        const me = this;
        const sliderWrapper = document.querySelector('.slider-wrapper');
        const slides = document.querySelectorAll('.slider-wrapper .slide');
        const slidesToShow = 3; // Ajustez ce nombre si vous voulez plus ou moins d'éléments visibles
        const slideHeight = 180; // Hauteur d'une slide (image + marges)
        const middleIndex = Math.floor(slidesToShow / 2); // Calcul de l'index du milieu

        // Trouver l'index de l'élément actif
        slides.forEach((slide, index) => {
            if (slide.classList.contains('active')) {
                me.index = index + 1; // Incrémenter l'index pour passer à l'élément suivant
            }
        });

        // Remettre à zéro si on atteint la fin des slides
        if (me.index >= slides.length) {
            me.index = 0;
        }

        // Calculer le décalage pour centrer le slide actif
        const offset = -(me.index - middleIndex) * slideHeight;
        //@ts-ignore
        sliderWrapper.style.transform = `translateY(${offset}px)`;

        // Supprimer les classes "active" et ajouter "active" au slide du milieu
        slides.forEach((slide, i) => {
            slide.classList.remove('active');
            if (i === me.index) {
                slide.classList.add('active');

                // Obtenir l'image de l'élément actif
                const imgElement = slide.querySelector('img');
                if (imgElement) {
                    me.srcValue = imgElement.getAttribute('src');

                    // Mettre à jour l'image de fond de #_box
                    $('#_box').css({
                        'background-image': 'url(' + me.srcValue + ')',
                        'background-size': 'cover'
                    });
                }
            }
        });
    }

    next() {
        let me = this;
        const sliderWrapper = document.querySelector('.slider-wrapper');
        const slides = document.querySelectorAll('.slider-wrapper .slide');
        const slidesToShow = 1;//me.dataFreeEvent.length; // Number of slides to show
        const slideHeight = 180; // Image height (100px) + margin (10px top and bottom)

        slides.forEach((li, index) => {
            if (li.classList.contains('active')) {
                this.index = index + 1;
            }
        });
        if (this.index >= slides.length) {
            this.index = 0; // Wrap around to the first set
        }
        this.eventitre = this.dataFreeEvent[this.index].eventitre;
        const offset = -this.index * slideHeight;
        //@ts-ignore
        sliderWrapper.style.transform = `translateY(${offset}px)`;
        slides.forEach((slide, i) => {
            slide.classList.remove('active');
            if (i >= this.index && i < this.index + slidesToShow) {
                slide.classList.add('active');
                let liElement = document.querySelector('li.slide.active');

                // Récupérer l'élément img à l'intérieur de l'élément li
                let imgElement = liElement.querySelector('img');
                // Récupérer le contenu de l'attribut src
                me.srcValue = imgElement.getAttribute('src');
                me.currentLibreImg = me.srcValue
                $('#_box').css({'background-image': 'url(' + this.srcValue + ')', 'background-size': 'cover'});
            }
        });
    }

    prev() {
        let me = this;
        const sliderWrapper = document.querySelector('.slider-wrapper');
        const slides = document.querySelectorAll('.slider-wrapper .slide');
        const slidesToShow = 1;//me.dataFreeEvent.length; // Number of slides to show
        const slideHeight = 180; // Image height (100px) + margin (10px top and bottom)

        slides.forEach((li, index) => {
            if (li.classList.contains('active')) {
                this.index = index > 0 ? index - 1 : slides.length - 1;
            }
        });

        if (this.index == 0) {
            //  this.index = slides.length - 1; // Wrap around to the first set
        }
        this.eventitre = this.dataFreeEvent[this.index].eventitre;
        const offset = -this.index * slideHeight;
        //@ts-ignore
        sliderWrapper.style.transform = `translateY(${offset}px)`;
        slides.forEach((slide, i) => {
            slide.classList.remove('active');
            if (i >= this.index && i < this.index + slidesToShow) {
                slide.classList.add('active');
                let liElement = document.querySelector('li.slide.active');

                // Récupérer l'élément img à l'intérieur de l'élément li
                let imgElement = liElement.querySelector('img');
                // Récupérer le contenu de l'attribut src
                me.srcValue = imgElement.getAttribute('src');
                me.currentLibreImg = me.srcValue
                $('#_box').css({'background-image': 'url(' + this.srcValue + ')', 'background-size': 'cover'});
            }
        });
    }


}
