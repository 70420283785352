import { Component } from '@angular/core';

@Component({
  selector: 'app-succes-wave',
  templateUrl: './succes-wave.component.html',
  styleUrls: ['./succes-wave.component.scss']
})
export class SuccesWaveComponent {

}
