import {DatePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'src/environments/environment';
import {EventwithkeyService} from '../home-billeterie/eventwithkey.service';
import {Spectateur} from '../spectateur';
import Swal from "sweetalert2";

declare let $: any;

@Component({
    selector: 'app-infos-billeterie',
    templateUrl: './infos-billeterie.component.html',
    styleUrls: ['./infos-billeterie.component.scss']
})
export class InfosBilleterieComponent implements OnInit {
    dataMenu: any = [
        {
            uri: "/",
            label: "Accueil",
        }, {
            uri: "/concert",
            label: "Découvrir",
        }, {
            uri: "/connecter",
            label: "Mon profil",
        }
    ]
    passwordForm;
    spectateurForm;

    spectateur: Spectateur = new Spectateur();
    myDatepipe!: any;
    dataUsers: any = [];
    dataNav: any = [
        {
            uri: "/achat",
            label: "Mes achats",
        },
        // {
        //     uri: "/ticket",
        //     label: "Mes tickets",
        // },
        {
            uri: "/receive",
            label: "Tickets reçu",
        }, {
            uri: "/foward",
            label: "Tickets transférés",
        }, {
            uri: "/carte",
            label: "Mes cartes",
        }, {
            uri: "/notification",
            label: "Notifications",
        }, {
            uri: "/infos",
            label: "Info personnelle",
        }
    ]
     isLoad: boolean=false;
    spectateursexe: any;

    constructor(private _activatedRoute: ActivatedRoute, private router: Router,
                private gestwithkey: EventwithkeyService, public fb: UntypedFormBuilder,
                private toastr: ToastrService, datepipe: DatePipe,
                private logger: NGXLogger) {
        this.myDatepipe = datepipe;
    }


    ngOnInit(): void {


        this.getSpectateur();
        let u = new Spectateur();
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        this.dataUsers = u;
        if (!u) {
            this.router.navigate(['/'])
        }
        setTimeout(() => {
            this.selectNav(5)
        }, 1000);

        this.passwordForm = this.fb.group({
            'oldpassword': [null, Validators.required],
            'password': [null, Validators.required],
            'confpassword': [null, Validators.required]
        });

 this.spectateursexe = this.dataUsers.spectateursexe=='un homme' ? 'homme' : this.dataUsers.spectateursexe ? 'femme' : '';

        console.log(" this.spectateursexe ======", this.spectateursexe)
        this.spectateurForm = this.fb.group({
            'spectateurcode': [null, Validators.required],
            'spectateuremail': [null, null],
            'spectateurtel': [null, Validators.required],
            'spectateurnom': [null, Validators.required],
            'spectateurprenom': [null, Validators.required],
            'spectateursexe': [this.spectateursexe , Validators.required],
            'spectateurdatenais': [null, Validators.required],
        });
        this.spectateurForm.controls['spectateursexe'].setValue(this.dataUsers?.spectateursexe ?? 'homme');

        console.log(" this.spectateurForm ======", this.spectateurForm.value)
    }


    resetPassword() {

        if (!this.passwordForm.valid) {

        }

            let request: any = this.passwordForm.value;
            if (!request.oldpassword || request.oldpassword.trim().length == 0) {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    text: 'Veuillez saisir l\' ancien mot de passe valide',
                });
               // this.toastr.error('Echec!', 'Veuillez saisir l\' ancien mot de passe valide');
                return null;
            }

            if (!request.password || request.password.trim().length < 5) {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    text: 'Veuillez saisir un nouveau mot de passe valide. au moins 5 chiffres',
                });
                //this.toastr.error('Echec!', 'Veuillez saisir un nouveau mot de passe valide. au moins 5 chiffres');
                return null;
            }

            if (request.password!== request.confpassword) {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    text: 'Les mots de passes ne sont pas identiques',
                });
              //  this.toastr.error('Echec!', 'Les mots de passes ne sont pas identiques');
                return null;
            }

            let u: Spectateur;
            u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));

            request.code = u.spectateurcode;

            request.spectateurdatenais = this.myDatepipe.transform(request.spectateurdatenais, "dd-MM-yyyy");

this.isLoad = true;
            this.gestwithkey.post(request, "/billeterie/session/updatepssword", u.token).subscribe(data => {
                    this.logger.trace('INFOS CNX', data);
                    this.isLoad = false;
                    switch (data.status) {
                        case 200: {
                            this.passwordForm = this.fb.group({
                                'oldpassword': [null, Validators.required],
                                'password': [null, Validators.required],
                                'confpassword': [null, Validators.required]
                            });
                            Swal.fire({
                                icon: "success",
                                title: "Succes",
                                text: 'Mot de passe mis à Jour',
                            });
                          //  this.toastr.success('Succes!', 'Mot de passe mis à Jour');
                            break;
                        }
                    }
                },
                error => {
                    //this.loader.close();
                    this.isLoad = false;
                    this.logger.error('ERROR', error);
                    switch (error.status) {

                        case 423: {
                            this.toastr.error('Echec!', 'Ancien mot de passe incorrect');
                            break;
                        }

                        default: {
                            this.toastr.error('Echec!', 'Erreur survenue ');
                            break;
                        }
                    }
                });


    }

    logout() {
        localStorage.clear();
        this.router.navigate(['/'])
    }

    getSpectateur() {
        let u: Spectateur;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));

        this.gestwithkey.get("/billeterie/session/spectateurbycode/" + u.spectateurcode, u.token).subscribe(data => {
                switch (data.status) {
                    case 200: {
                        this.spectateur = data.body;
                        this.logger.trace("SPECTATEUR ==> ");

                        this.logger.trace(this.spectateur);

                        this.spectateur.spectateurdatenais = this.myDatepipe.transform(this.spectateur.spectateurdatenais, "yyyy-MM-dd");

                        if (this.spectateur) {
                            this.spectateurForm = this.fb.group({
                                'spectateurcode': [this.spectateur.spectateurcode, Validators.required],
                                'spectateuremail': [this.spectateur.spectateuremail, Validators.required],
                                'spectateurtel': [this.spectateur.spectateurtel, Validators.required],
                                'spectateurnom': [this.spectateur.spectateurnom, Validators.required],
                                'spectateurprenom': [this.spectateur.spectateurprenom, Validators.required],
                                'spectateursexe': [this.spectateur.spectateursexe, Validators.required],
                                'spectateurdatenais': [this.spectateur.spectateurdatenais, Validators.required]
                            });

                            this.spectateurForm.get('spectateurtel')?.disable();
                        }
                        break;
                    }
                }
            },
            error => {
                switch (error.status) {
                    case 401: {
                        this.router.navigate(['/authentication/signin']);
                        break;
                    }

                    default: {
                        this.logger.error("ERROR");

                        this.logger.error(error);
                        break;
                    }
                }
            });
    }


    validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    updateProfile() {

        this.spectateurForm.get('spectateurtel')?.enable();
        if (this.spectateurForm.valid) {
          //  return null;
        }

        if (!this.validateEmail(this.spectateurForm.get('spectateuremail').value)
        ) {
            // this.toastr.error('Echec!', 'Email incorrect');
            // return null;
        }
        let request: any = this.spectateurForm.value;
        console.log(request);

        if (!request.spectateuremail) {
            Swal.fire({
                icon: "error",
                title: "Echec",
                text: 'Veuillez renseigner votre adresse email',
            });
            return;
        }
        if (!request.spectateurnom) {
            Swal.fire({
                icon: "error",
                title: "Echec",
                text: 'Veuillez renseigner votre nom',
            });
            return;
        }
        if (!request.spectateurprenom) {
            Swal.fire({
                icon: "error",
                title: "Echec",
                text: 'Veuillez renseigner votre prénoms',
            });
            return;
        }
        if (!request.spectateursexe) {
            Swal.fire({
                icon: "error",
                title: "Echec",
                text: 'Veuillez selectionner votre genre',
            });
            return;
        }
        if (!request.spectateurdatenais) {
            Swal.fire({
                icon: "error",
                title: "Echec",
                text: 'Veuillez renseigner votre date de naissance',
            });
            return;
        }


        this.gestwithkey.post(request, "/billeterie/session/updatespectateur", this.dataUsers.token).subscribe(data => {
                this.logger.trace('INFOS CNX', data);

                switch (data.status) {
                    case 200: {
                        this.toastr.success('Succes!', 'Profil mis à Jour');

                        this.getSpectateur();
                        break;
                    }
                }
            },
            error => {
                //this.loader.close();

                this.logger.error('ERROR', error);
                switch (error.status) {


                    case 423: {
                        this.toastr.error('Echec!', 'Email deja utilisé par un ancien compte');
                        break;
                    }


                    default: {
                        this.toastr.error('Echec!', 'Erreur survenue ');
                        break;
                    }
                }
            });
    }

    selectNav(i: number) {
        for (let j = 0; j < this.dataMenu.length; j++) {
            $('#nav' + j).removeClass("active");
        }
        $('#nav' + i).addClass('active');
    }

    _lettre(spectateurnom: string) {
        return spectateurnom?.substring(0, 1)?.toUpperCase();
    }

    _split(caticket: string) {

        let d = caticket.split(" ");
        let r: string = "";
        if (d.length > 1) {
            r = d[0].substring(0, 1) + "" + d[1].substring(0, 1)
        } else {
            r = caticket;
        }
        return r;
    }
}
