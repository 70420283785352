import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import Swiper, { Navigation } from 'swiper';
@Component({
    selector: 'app-section-categorie',
    templateUrl: './section-categorie.component.html',
    styleUrls: ['./section-categorie.component.scss']
})
export class SectionCategorieComponent implements OnInit,AfterViewInit  {
    @Input() dataItems: any = {
        events: [] // Initialise avec une structure par défaut
    };
    @ViewChild('prevBtn', { static: false }) prevBtn: any;
    @ViewChild('nextBtn', { static: false }) nextBtn: any;


    slides = [
        { img: 'assets/image1.jpg', title: 'RAP FUSION' },
        { img: 'assets/image2.jpg', title: 'Rap fusion renaissance' },
        { img: 'assets/image3.jpg', title: 'GIM EN LIVE' },
        { img: 'assets/image4.jpg', title: 'All Back event' },
    ];
    dataSilde: Array<object> = [];
    imageObject: Array<object> = [
        {
            video: 'https://youtu.be/6pxRHBw-k8M' // Youtube url
        },
        {
            video: 'assets/video/movie.mp4', // MP4 Video url
        },
        {
            video: 'assets/video/movie2.mp4',
            posterImage: 'https://nbh-organisateur.com/digitalbackendorganisateur/event/image/download/UXLI2268', //Optional: You can use this key if you want to show video poster image in slider
            title: 'Image title-----------'
        },

        {
            video: 'assets/video/movie.mp4', // MP4 Video url
        }
    ];
    sections = [
        {
            title: 'CONCERTS',
            items: [
                { image: 'assets/concerts/oumou-sangare-1.jpg', title: 'OUMOU SANGARÉ EN CONCERT LIVE' },
                { image: 'assets/concerts/roseline-layo.jpg', title: 'ROSELINE LAYO EN CONCERT LIVE' },
                { image: 'assets/concerts/onel-mala.jpg', title: 'O\'NEL MALA EN CONCERT LIVE' },
                { image: 'assets/concerts/josey.jpg', title: 'JOSEY EN CONCERT LIVE' },
                // Ajoutez plus d'images si nécessaire
            ],
            currentIndex: 0
        },
        {
            title: 'CINEMA',
            items: [
                { image: 'assets/concerts/oumou-sangare-1.jpg', title: 'OUMOU SANGARÉ EN CONCERT LIVE' },
                { image: 'assets/concerts/roseline-layo.jpg', title: 'ROSELINE LAYO EN CONCERT LIVE' },
                { image: 'assets/concerts/onel-mala.jpg', title: 'O\'NEL MALA EN CONCERT LIVE' },
                { image: 'assets/concerts/josey.jpg', title: 'JOSEY EN CONCERT LIVE' },
                // Ajoutez plus d'images si nécessaire
            ],
            currentIndex: 0
        },
        {
            title: 'HUMOUR',
            items: [
                { image: 'assets/concerts/oumou-sangare-1.jpg', title: 'OUMOU SANGARÉ EN CONCERT LIVE' },
                { image: 'assets/concerts/roseline-layo.jpg', title: 'ROSELINE LAYO EN CONCERT LIVE' },
                { image: 'assets/concerts/onel-mala.jpg', title: 'O\'NEL MALA EN CONCERT LIVE' },
                { image: 'assets/concerts/josey.jpg', title: 'JOSEY EN CONCERT LIVE' },
                // Ajoutez plus d'images si nécessaire
            ],
            currentIndex: 0
        }
    ];
    slidesPerView: number = 4;
    isBeginning = true; // Bouton "Prev" désactivé au départ
    isEnd = false; // Bouton "Next" activé au départ
    swiperInstance: any;
    constructor(private router: Router,private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if (window.innerWidth <= 480) {
            this.slidesPerView = 1; // 1 slide visible sur petits écrans
        }
        setTimeout(()=>{
            if (this.dataItems.events) {
                this.dataSilde = []
                let dd =this.dataItems
                this.dataSilde = dd.events.map((e) => {
                    return {
                        ...e,
                        image: e?.url,
                        thumbImage: e?.url,
                        alt: e?.eventitre,
                        title: e?.eventitre,
                    }
                });
                this.cdr.detectChanges();
            }
        },2000);
    }
    ngAfterViewInit(): void {
        let bl:any = this.slidesPerView == 1
        Swiper.use([Navigation]); // Active la navigation Swiper

        // Crée une instance de Swiper avec gestion de la navigation
        setTimeout(() => {
            // @ts-ignore
            const swiper = new Swiper('.mySwiper', {
                slidesPerView: this.slidesPerView, // Nombre de slides visibles
                spaceBetween: bl, // Espacement entre les slides
                loop: false, // Désactivation de la boucle
                navigation: {
                    prevEl: this.prevBtn?.nativeElement, // Bouton précédent
                    nextEl: this.nextBtn?.nativeElement, // Bouton suivant
                },
                on: {
                    // Met à jour les états des boutons à chaque changement de slide
                    slideChange: () => {
                        this.isBeginning = swiper?.isBeginning;
                        this.isEnd = swiper?.isEnd;
                    },
                },
            });

            // Initialiser l'état au chargement
            this.isBeginning = swiper?.isBeginning;
            this.isEnd = swiper?.isEnd;
        }, 3000);
    }
    // Gère les changements de slide
    onSlideChange(swiper: any): void {
        this.isBeginning = swiper.isBeginning;
        this.isEnd = swiper.isEnd;
    }

    // Initialise Swiper
    onSwiper(swiper: any): void {
        this.isBeginning = swiper.isBeginning;
        this.isEnd = swiper.isEnd;
    }

    toGoRoute(event: any) {
            console.log(event)

        //let path: any = this.dataItems?.events[event];
        this.router.navigateByUrl('/check-event?key=' + event.eventcode);
        // this.router.navigateByUrl('/event-parc?key=' + path.eventcode);
    }


}
