<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 250px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color:  #0B2154 !important;height: 250px !important;">
        <app-navbar></app-navbar>
        <div class="container">
            <div class="menu">
                <div class="menu-title">
                    <span>Mes achats</span>
                </div>
                <div class="profile">
                    <div class="row _user">
                        <div class="col-4">
                            <svg style="    width: 98%;" xmlns="http://www.w3.org/2000/svg" width="104" height="106" viewBox="0 0 104 106">
                                <g id="Groupe_58" data-name="Groupe 58" transform="translate(-449.57 -116.254)">
                                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="52" cy="52" r="52" transform="translate(449.57 116.255)" fill="#60ba07"/>
                                    <text id="G" transform="translate(501.57 200.254)" fill="#cecece" font-size="87" font-family="Montserrat-Bold, Montserrat" font-weight="700" letter-spacing="0.041em"><tspan x="-32.625" y="0">{{_lettre(dataUsers?.spectateurnom)}}</tspan></text>
                                </g>
                            </svg>

                        </div>
                        <div class="col-8 _inf_user">
                            <span class="profile-text">{{dataUsers?.spectateurnom}} {{dataUsers?.spectateurprenom}}</span> <br>
                            <span class="profile-text">{{dataUsers?.spectateurtel}}</span><br>
                            <span class="profile-text">{{dataUsers?.spectateuremail}}</span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>
</section>

<div class="courses-details-area pb-70" style="background-color:#006837 ;min-height: 100%;">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <nav class="navCat">
                    <a style="cursor: pointer" [routerLink]="data.uri"  *ngFor="let data of dataNav;let i=index" id="nav{{i}}">{{data.label}}</a>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-8">
            <div class="courses-details-desc">
                <div class="contLine" >
                    <div class="event" *ngFor="let a of achats; let i=index" (click)="showMyAchat(a)">
                        <img src="{{a?.urlImageEvent}}" alt="Événement 1">
                        <div class="info">
                            <p class="title">{{a.event}}</p>
                            <p class="price">
                                {{_split(a.caticket)}} : {{a.achatmontantpayer | number}} FCFA
<!--                                &nbsp;&nbsp;{{a.achatnbreticket}}-->
<!--                                x  &nbsp;&nbsp;-->
<!--                                <b style="float: right;font-weight: bold;"> &gt;</b>-->
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            </div>
            <div class="col-lg-2">

            </div>

            <div class="col-12" *ngIf="!achats.length">
                <p style="color: #fff;text-align: center;margin-top: 15%">
                    Aucune donnée !
                </p>
            </div>
        </div>
    </div>
</div>














<div class="modal" *ngIf="isOpen">
    <div class="modal-content">
        <div class="modal-header">
            <span>{{ showAchat.event}}</span>
        </div>
        <div class="modal-body">



            <div  class="myDashboard-profile">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="profile-content">
                            <ul class="contact-info">
                                <li>
                                    <h3>{{ showAchat.event}}</h3>
                                </li>
                                <li>
                                    <p> <b>Categorie ticket : </b> {{ showAchat.caticket}}</p>
                                </li>

                                <li><b>Date achat : </b>{{ showAchat.achatdatelevel2 | date: 'dd/MM/yyyy hh:mm' }}</li>
                                <li><b>Montant normal : </b> {{ showAchat.achatmontantnormal}}</li>
                                <li><b>Reduction : </b> {{ showAchat.achatreduction}}</li>
                                <li><b>Montant payé : </b> {{ showAchat.achatmontantpayer}}</li>
                                <li><b>Nombre ticket acheté : </b> {{ showAchat.achatnbreticket}}</li>
                                <li><b>Ticket qr_code : </b> {{ showAchat.achatnbreqr}} </li>
                                <li><b>Ticket carte : </b> {{ showAchat.achatnbrecarte}}</li>
<!--                                <li>-->
<!--                                    <a (click)="loadAchat()" style="cursor: pointer; color: white;"-->
<!--                                       class="myDashboard-logout">Fermer</a>-->
<!--                                </li>-->

                            </ul>
                        </div>
                    </div>
                </div>
            </div>



        </div>
        <div class="modal-footer">
            <button class="btn btnCl" style="width: 126px;" (click)="closeModal()">Fermer</button>

        </div>
    </div>
</div>
