import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {EventwithoutkeyService} from '../home-billeterie/eventwithoutkey.service';
import {OwlOptions} from "ngx-owl-carousel-o";
import * as moment from "moment/moment";

declare let $: any;

@Component({
    selector: 'app-concert-billeterie',
    templateUrl: './concert-billeterie.component.html',
    styleUrls: ['./concert-billeterie.component.scss']
})
export class ConcertBilleterieComponent implements OnInit {
    query: string = '';
    suggestions: any = [];
    filteredSuggestions: string[] = [];
    showSuggestions: boolean = true;
    imgDefault: string = "../../../../../../assets/img/COV ESP.png";
    loadingCheck: any = false;
    rows: any = [];
    instructorSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: false,
        margin: 30,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ],
        responsive: {

            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            1200: {
                items: 3
            }

        }
    }
    dataEvent: any = [];
    dataCategorie: any = [];
    loading: boolean = false;
    location: any;
    routerSubscription: any;
    timeoutId;
    private currentImageIndex: number = 0;
    libCat: any;

    constructor(private _activatedRoute: ActivatedRoute, private router: Router, private gestserv: EventwithoutkeyService,
                private toastr: ToastrService,
                private logger: NGXLogger) {
    }

    ngOnInit(): void {
        // $('#backConcert').css({
        //     'background-image': 'url(' + this.imgDefault + ')',
        //     'background-repeat': 'no-repeat',
        //     'background-size': 'contain'
        // });
        this.loadCategorie();
        setTimeout(() => {
            this._activatedRoute.queryParams.forEach((params: Params) => {
                if (params['key']) {
                    this.loadEvent(params['key']);
                } else {
                    // this.loadEvent(params['key']);
                    this.router.navigate(['/']);
                }
            });
        }, 1000)


    }


    goToRouting(path: string) {
        // this.router.navigateByUrl(path);
    }

    loadEvent(categorie: string) {
        this.loadingCheck = false;
        this.rows = [];
        $('#ligneMenu' + 1).addClass('active');

        let newCat = categorie == '1' ? this.dataCategorie[0]?.categoriecode : categorie;
        this.libCat = this.dataCategorie.find((d) => d.categoriecode == newCat);
        this.gestserv.get(`/billeterie/alleventbycategorie/${newCat}`).subscribe(data => {

                switch (data.status) {
                    case 200: {

                        if (data.body.length) {

                            this.rows.events = data.body;
                            data.body.forEach((d) => {
                                this.suggestions.push({
                                    "eventitre": d.eventitre + " le " + moment(d.eventdebut).format("DD-MM-YYYY à HH:mm:ss"),
                                    "eventdebut": d.eventdebut,
                                    "eventlieu": d.eventlieu,
                                    "eventcode": d.eventcode,
                                    "url": d.url,
                                })
                            })
                            // $('#backConcert').css({
                            //     'background-image': 'url(' + data.body[0].url + ')',
                            //     'background-repeat': 'no-repeat',
                            //     'background-size': 'cover'
                            // });

                            this.rows.categorie = data.body[0].eventitre.split(" ")[0];
                            this.timeoutId = setInterval(() => {
                                this.changeBackgroundImage();
                            }, 10000);
                        } else {
                            clearInterval(this.timeoutId);
                            // $('#backConcert').css({
                            //     'background-image': 'url(' + this.imgDefault + ')',
                            //     'background-repeat': 'no-repeat',
                            //     'background-size': 'cover'
                            // });
                        }

                        this.logger.trace(this.rows);
                        this.loadingCheck = true;
                        // if (!this.rows || this.rows.length == 0) {
                        //     this.toastr.error('', 'Aucun evenement');
                        //     this.goToRouting("/");
                        // }
                        break;
                    }
                    default : {
                        clearInterval(this.timeoutId)
                        // $('#backConcert').css({
                        //     'background-image': 'url(' + this.imgDefault + ')',
                        //     'background-repeat': 'no-repeat',
                        //     'background-size': 'cover'
                        // });
                        this.rows.events = [];
                    }
                }
            },
            error => {
                clearInterval(this.timeoutId)
                this.rows.events = [];
                switch (error.status) {
                    case 401: {
                        //this.router.navigate(['/authentication/signin']);
                        this.loadingCheck = true;
                        break;
                    }
                    default: {
                        this.loadingCheck = true;
                        this.logger.trace(error);
                        // $('#backConcert').css({
                        //     'background-image': 'url(' + this.imgDefault + ')',
                        //     'background-repeat': 'no-repeat',
                        //     'background-size': 'cover'
                        // });
                        break;
                    }
                }
            });
    }

    toGoRoute(event: any) {
        this.router.navigateByUrl('/check-event?key=' + event.eventcode);
    }

    selectNav(data: any, j: number) {
        this.currentImageIndex = 0;
        for (let j = 0; j < 30; j++) {
            $('#navCatCheck' + j).removeClass("active");
        }
        $('#navCatCheck' + j).addClass('active');

        this.router.navigateByUrl('/concert?key=' + data.categoriecode);
    }


    loadCategorie() {
        this.dataCategorie = [];
        this.gestserv.get("/billeterie/event/getAllcategorieEvent").subscribe(data => {
                switch (data.status) {
                    case 200: {
                        this.dataCategorie = data.body;
                        break;
                    }
                }
            },
            error => {
                switch (error.status) {
                    case 401: {
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });
    }

    // Sélection d'une suggestion
    selectSuggestion(data: any): void {
        this.router.navigateByUrl('/check-event?key=' + data.eventcode);
        // this.query = suggestion;
        // this.filteredSuggestions = [];
    }

    changeBackgroundImage() {
        this.currentImageIndex = (this.currentImageIndex + 1) % this.rows.events.length;
        this.setBackgroundImage();
    }

    setBackgroundImage() {
        // $('#backConcert').css('background-image', 'url(' + this.rows.events[this.currentImageIndex].url + ')');
    }

    // Méthode qui filtre les suggestions
    onSearch(): void {
        this.showSuggestions = true;
        const queryLower = this.query.toLowerCase();
        this.filteredSuggestions = this.suggestions.filter(suggestion => suggestion.eventitre.toLowerCase().includes(queryLower)
        );
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event): void {
        this.showSuggestions = false;
        this.query = "";
        const clickedInside = (event.target as HTMLElement).closest('.search-container');
        if (!clickedInside) {
            //this.showSuggestions = false;
        }
    }
}
