<app-header-billeterie></app-header-billeterie>

<section class="mission-area"  id="myImgApropos" style="background-color: #0B2154 !important;">
    <app-navbar></app-navbar>
    <!--        <div class="container" style="display: flex;justify-content: center">-->
    <!--            <h1 style="margin-top: auto;margin-bottom: auto;color: #0B2154 !important;font-family: Montserrat;font-size: 52px;line-height: 0.9;">A propos de <br>-->
    <!--                nous.</h1> <img class="imgApropos" src="">-->
    <!--        </div>-->
    <div class="login-area" style="background-color: #0b2154">

        <div class="row m-0">
            <div class="col-lg-8 col-md-12" id="enImgg">
                <!--                    <img src="assets/img/digit.png" class="igmS" alt="LOGO NBH">-->
            </div>
            <div class="col-lg-4 col-md-12 p-0">

                <div class="login-content {{current!=0?'nTop':''}}">
                    <div class="d-table">

                        <div class="d-table-cell">
                            <div class="login-form">
                                <!--                            <div class="logo">-->
                                <!--                                <a routerLink="/">-->
                                <!--                                    <img src="assets/img/digit.jpg" style="height: 150px; width: 300px;" alt="image"></a>-->
                                <!--                             </div>-->
                                <div class="header">
                                    <div class="dash left {{current===0?'tReto':'tReto2'}} "></div>
                                    <h3>Mot de passe oublié !</h3>
                                    <div class="dash right  {{current===0?'tLefto':'tLefto2'}}"></div>
                                </div>

                                <!--
                                <strong *ngIf="checking" style="color: red;">{{checkingMessage}}</strong>
                                -->
                                <strong *ngIf="key" style="color: green;"> Vous vous êtes inscrit avec succès, veuillez
                                    entrer votre telephone ou email et votre mot de passe pour vous connecter ! votre
                                    identifiant est
                                    {{key}}</strong>

                                <form [formGroup]="loginForm" (ngSubmit)="login()" *ngIf="current===0">
                                    <div class="form-group fieldInputCnx inpWid">
                                        <nz-input-group nzCompact>
                                            <nz-select [formControl]="loginForm.controls['indicatifPays']">
                                                <nz-option  [nzLabel]="'+'+data.indicatif" [nzValue]="data.indicatif" *ngFor="let data of dContry"></nz-option>
                                            </nz-select>
                                            <input type="text"  placeholder="Votre numéro de téléphone" [formControl]="loginForm.controls['username']" class="form-control"  style="width: 69%;margin-top: 0;height: 48px !important;border-bottom-left-radius:0px !important;border-top-left-radius: 0px !important;" />
                                        </nz-input-group>
                                        <!--                                        <input type="text" placeholder="Votre numéro de téléphone"-->
                                        <!--                                               [formControl]="loginForm.controls['username']" class="form-control inpForm">-->
                                    </div>
<!--                                    <div class="form-group fieldInput">-->
<!--                                        <input type="password"  placeholder="Votre mot de passe"-->
<!--                                               [formControl]="loginForm.controls['password']" class="form-control inpForm">-->
<!--                                    </div>-->

<!--                                    <div class="forgot-password fieldInput">-->
<!--                                        <a routerLink="/emailcheck" style="color:#fff">Mot de passe oublié ?</a>-->
<!--                                    </div>-->
                                    <br>
                                    <div class="button-wrapper">
                                        <div class="dash left"></div>
                                        <button type="submit" class="inpForm btnCnx">
                                            <div class="spinner-border" *ngIf="checking" style="font-size: 15px;height: 30px;width: 30px;color: red;" role="status">
                                                <span class="sr-only"></span>
                                            </div>
                                           <b *ngIf="!checking">Soumettre</b>
                                        </button>
                                        <div class="dash right"></div>
                                    </div>
                                </form>
                                <form [formGroup]="loginFormCf" (ngSubmit)="login()" *ngIf="current!=0">
<!--                                    <div class="form-group fieldInputCnx" style=" width: 79% !important;margin-left: 35px !important;">-->
<!--                                        <nz-input-group nzCompact>-->
<!--                                            <nz-select [formControl]="loginForm.controls['indicatifPays']">-->
<!--                                                <nz-option  [nzLabel]="'+'+data.indicatif" [nzValue]="data.indicatif" *ngFor="let data of dContry"></nz-option>-->
<!--                                            </nz-select>-->
<!--                                            <input type="text"  placeholder="Votre numéro de téléphone" [formControl]="loginForm.controls['username']" class="form-control"  style="width: 69%;margin-top: 0;height: 48px !important;border-bottom-left-radius:0px !important;border-top-left-radius: 0px !important;" />-->
<!--                                        </nz-input-group>-->
<!--                                    </div>-->

                                       <div class="form-group fieldInput _divInp">
                                         <input type="text" autocomplete="off" placeholder="Code OTP"
                                              [formControl]="loginFormCf.controls['otp']" class="form-control inpForm inpLen">
                                       </div>
                                    <div class="form-group fieldInput _divInp">
                                        <input type="password" autocomplete="off"  maxlength="5" minlength="5"  placeholder="Nouveau mot de passe (5 Chiffres)"
                                               [formControl]="loginFormCf.controls['pwd']" class="form-control inpForm inpLen">
                                    </div>
                                    <div class="form-group fieldInput _divInp ">
                                        <input type="password" autocomplete="off" maxlength="5" minlength="5"  placeholder="Confirmer le mot de passe"
                                               [formControl]="loginFormCf.controls['cfpwd']" class="form-control inpForm inpLen">
                                    </div>

                                    <!--                                    <div class="forgot-password fieldInput">-->
                                    <!--                                        <a routerLink="/emailcheck" style="color:#fff">Mot de passe oublié ?</a>-->
                                    <!--                                    </div>-->
                                    <br>
                                    <div class="{{current===0?'button-wrapper':'button-wrapper2'}}">
                                        <div class="dash left"></div>
                                        <button type="submit" class="inpForm btnCnx">
                                            <div class="spinner-border" *ngIf="checking" style="font-size: 15px;height: 30px;width: 30px;color: red;" role="status">
                                                <span class="sr-only"></span>
                                            </div>
                                            <b *ngIf="!checking">Confirmer</b>
                                            </button>
                                        <div class="dash right"></div>
                                    </div>
                                </form>
                            </div>
                            <p class="textBottom"><a routerLink="/connecter" class="aCreer">Je me connecte</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12" id="enImg">
                <div class="spanStyle">
                    <!--                    <img src="assets/img/digit.png" class="igmS" alt="LOGO NBH">-->
                </div>
                <div class="txt">
                    <!--                    <strong class="textAcc">-->
                    <!--                        Bienvenue <br> à vous !-->
                    <!--                    </strong>-->
                    <!--                    <p>-->
                    <!--                        Bienvenue sur NBH, <br>-->
                    <!--                        La nouvelle billeterie hybride, connecté à un système de côntrole <br>-->
                    <!--                        d'accès automatique.-->
                    <!--                        <br><br>-->
                    <!--                        NBH est un système de billeterie hybride qui vous propose des <br>-->
                    <!--                        tickets digitaux de tous vos événements préférés. Concert,<br>-->
                    <!--                        spectacle, barbecue, brunch, matchs, conférénce, salon, panel et bien d'autres...-->
                    <!--                    </p>-->
                </div>

            </div>



        </div>


    </div>


</section>

