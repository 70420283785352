<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 250px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color:  #0B2154 !important;height: 250px !important;">
        <app-navbar></app-navbar>
        <div class="container">
            <div class="menu">
                <div class="menu-title">
                    <span>Achat de ticket </span>
                </div>
                <div class="profile">
                    <div class="row _user" *ngIf="dataUsers?.spectateurtel">
                        <div class="col-4">
                            <svg style="    width: 98%;" xmlns="http://www.w3.org/2000/svg" width="104" height="106" viewBox="0 0 104 106">
                                <g id="Groupe_58" data-name="Groupe 58" transform="translate(-449.57 -116.254)">
                                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="52" cy="52" r="52" transform="translate(449.57 116.255)" fill="#60ba07"/>
                                    <text id="G" transform="translate(501.57 200.254)" fill="#cecece" font-size="87" font-family="Montserrat-Bold, Montserrat" font-weight="700" letter-spacing="0.041em"><tspan x="-32.625" y="0">{{_lettre(dataUsers?.spectateurnom)}}</tspan></text>
                                </g>
                            </svg>

                        </div>
                        <div class="col-8 _inf_user">
                            <span class="profile-text">{{dataUsers?.spectateurnom}} {{dataUsers?.spectateurprenom}}</span> <br>
                            <span class="profile-text">{{dataUsers?.spectateurtel}}</span><br>
                            <span class="profile-text">{{dataUsers?.spectateuremail}}</span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>
</section>

<div class="courses-details-area pb-70" style="background-color:#006837 ;min-height: 100%;">
    <div class="container">

        <div class="row">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-8">
                <div class="courses-details-desc">
                    <div class="container" *ngIf="(key!=1 && key!=2) && (mont!=1 && mont!=2)">
                        <div class="success-container">
                            <h1 style="color: #fff">Votre paiement a été effectué avec succès.</h1>
                            <div class="checkmark-container">
                                <img src="https://funtura.in/wp-content/themes/funtura/assets/images/success.svg" alt="Success Checkmark" class="checkmark">
                            </div>
                            <div class="details">
                                <p>Référence : {{key}}</p>
                                <p>MONTANT : {{separe(mont | number: '1.0-0') }} FCFA</p>
                                <p style="text-align: left !important;">
                                    Cher(e) {{dataUsers?.spectateurnom}} {{dataUsers?.spectateurprenom}}, <br>
                                    vous pouvez retrouver votre ticket dans la section 'Mes Tickets'.
                                </p>
                                <button [disabled]="!dataUsers?.spectateurtel" (click)="mesTicket()" style="background-color: #0B2154 !important;border-color: #0B2154 !important; margin-top: 5px;margin-bottom: 10px" nz-button nzType="primary">Accéder à Mes Tickets</button>
                            </div>
                        </div>

                    </div>


                    <div class="containerError" *ngIf="key==1 && mont==1">
                        <div class="error-message">
                            <!--                <div class="card">-->
                            <img src="assets/img/error.png" alt="Erreur" class="error-icon">
                            <h1>Erreur de Paiement</h1>
                            <p>Nous avons rencontré un problème lors du traitement de votre paiement.</p>
                            <p>Veuillez réessayer plus tard ou contacter notre support.</p>
                            <a href="#" class="button">Réessayer</a>
                        </div>
                        <!--            </div>-->
                    </div>
                    <div class="containerError" *ngIf="key==2 && mont==2">
                        <div class="error-message">
                            <!--                <div class="card">-->
                            <img src="assets/img/error.png" alt="Erreur" class="error-icon">
                            <h1>Erreur de Paiement</h1>
                            <p>Erreur ! Cas de fraude signalé !</p>
                            <p>Veuillez réessayer plus tard ou contacter notre support.</p>
                            <a href="#" class="button">Réessayer</a>
                        </div>
                        <!--            </div>-->
                    </div>

                </div>
            </div>
            <div class="col-lg-2">

            </div>
        </div>
    </div>
</div>

