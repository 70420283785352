<app-header-billeterie></app-header-billeterie>


<section class="mission-area ptb-100" id="myImg">
    <section class="mission-area" style="background: rgba(0, 0, 0, 0.9) !important;height: 500px !important;">
        <app-navbar></app-navbar>
        <div class="container">

            <div class="row">
                <div class="col-6">
                    <img [src]="event.url" class="imgEvent" alt="image">
                </div>
                <div class="col-6">
                    <div class="elemParc"></div>
                    <strong style="font-size: 30px;color: #fff;">PARC DES EXPOSITIONS D'ABIDJAN</strong>
                    <p style="color: #fff">Abidjan / Port-Bouet / parc des expositions d'abidjan</p>
                    <p style="background-color: #60BA07 !important;padding: 1%;color: #fff;font-size: 1.3em;font-weight: bold;">{{event.eventitre}}</p>

                    <!--*ngFor="let cat of event.catickets"-->

                    <div class="pricing" *ngFor="let cat of event.catickets;let i=index">
                        <div class="category">
                            <div *ngIf="i==0" class="color-box cat1"></div>
                            <div *ngIf="i==1" class="color-box cat2"></div>
                            <div *ngIf="i==2" class="color-box cat3"></div>
                            <div *ngIf="i==3" class="color-box cat4"></div>
                            {{cat.caticketlib}} = {{formatNumber(cat.caticketarif)}} F
                        </div>
<!--                        <div class="category"><div class="color-box cat2"></div>CAT 2 = 3.000F</div>-->
<!--                        <div class="category"><div class="color-box cat3"></div>CAT 3 = 5.000F</div>-->
<!--                        <div class="category"><div class="color-box cat4"></div>CAT 4 = 10.000F</div>-->

                    </div>
                </div>
            </div>
        </div>
    </section>
</section>



<div *ngIf="!loadingCheck" class="d-flex justify-content-center">
    <div class="spinner-border" style="font-size: 30px;
    height: 120px;
    width: 120px;
    color: red;" role="status">
        <span class="sr-only"></span>
    </div>
</div>

<!--height: 100%;-->
<div *ngIf="loadingCheck" class="events-details-area" style="background-color: #006837 !important;">
    <div class="container ">
        <div class="row">
            <div class="col-lg-7 col-sm-12">

                <div class="events-details">

                    <div class="stadium-container">
                        <img src="assets/img/parc.png" class="stadium-image"/>

                        <!-- Boucle pour afficher toutes les zones interactives -->
                        <div
                            *ngFor="let zone of zones"
                            class="seat-block"
                            [style.top.px]="zone.y"
                            [style.left.px]="zone.x"
                            (mouseenter)="showPopupp($event, zone)"
                            (mouseleave)="hidePopup()">
                        </div>

                        <!-- Popup d'informations au survol -->
                        <div class="popup" *ngIf="showPopup" [style.top.px]="popupY" [style.left.px]="popupX">
                            <h3>{{ selectedZone.name }}</h3>
                            <p><strong>Capacité :</strong> {{ selectedZone.capacity }} places</p>
                            <p><strong>Places disponibles :</strong> {{ selectedZone.available }}</p>
                            <img src="assets/view.png" class="popup-image"/>
                        </div>
                    </div>




                    <!--                    <div class="events-details-image">-->
<!--                        <img [src]="event.url" class="imgEvent" alt="image">-->
<!--                    </div>-->
<!--                    <div class="events-details-desc">-->
<!--                        <p>{{event.eventdesclevel1}}-->
<!--                        </p>-->
<!--                        <p>{{event.eventdesclevel2}}</p>-->
<!--                    </div>-->
                    <br><br><br>
                </div>
            </div>
            <div class="col-lg-5 col-sm-12">

                <aside class="widget-area widget-area-card eventCard">

                    <div class="widget widget_events_details">

                        <div class="row">



                            <div class="col-12">
                                <div class="product-details-desc" style="background-color: #092055 !important;padding: 10px;border-radius: 12px; margin-top: 20px">

                                    <div class="row">
                                        <div class="col-12 sepChoix">
                                            <b class="contText">Choix du nombre de tickets / CARTE OU QR CODE</b>
                                        </div>
                                        <div class="col-12">
                                            <div class="icon-section">
                                                <div class="icon-box" style="margin-right: 20%;">
                                                    <div class="icon">
                                                        <svg  style="text-align: center;width: 100%;" xmlns="http://www.w3.org/2000/svg" width="54.254" height="43.403" viewBox="0 0 54.254 43.403">
                                                            <g id="card-svgrepo-com" transform="translate(-2 -4)">
                                                                <path id="Tracé_20" data-name="Tracé 20" d="M34.508,4H23.658C13.427,4,8.312,4,5.134,7.178,2.846,9.466,2.205,12.759,2.026,18.242H56.14c-.179-5.483-.82-8.775-3.108-11.064C49.854,4,44.738,4,34.508,4Z" transform="translate(0.044)" fill="#fff"/>
                                                                <path id="Tracé_21" data-name="Tracé 21" d="M23.7,35.842H34.552c10.23,0,15.345,0,18.523-3.178s3.178-8.293,3.178-18.523q0-1.8-.005-3.391H2.005Q2,12.345,2,14.141c0,10.23,0,15.345,3.178,18.523S13.471,35.842,23.7,35.842Z" transform="translate(0 11.561)" fill="#fff"/>
                                                                <path id="Tracé_22" data-name="Tracé 22" d="M5.25,17.285A2.035,2.035,0,0,1,7.285,15.25H18.135a2.035,2.035,0,1,1,0,4.069H7.285A2.035,2.035,0,0,1,5.25,17.285Z" transform="translate(5.566 19.268)" fill="#092055" fill-rule="evenodd"/>
                                                                <path id="Tracé_23" data-name="Tracé 23" d="M11.75,17.285a2.035,2.035,0,0,1,2.035-2.035h4.069a2.035,2.035,0,1,1,0,4.069H13.785A2.035,2.035,0,0,1,11.75,17.285Z" transform="translate(16.699 19.268)" fill="#092055" fill-rule="evenodd"/>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div class="arrows">

                                                        <svg (click)="down('c')" class="arrow" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                            <g id="Groupe_49" data-name="Groupe 49" transform="translate(-1332 -546)">
                                                                <rect id="Rectangle_40" data-name="Rectangle 40" width="18" height="18" transform="translate(1332 546)" fill="#fff"/>
                                                                <path id="Tracé_26" data-name="Tracé 26" d="M4239.46,1098.224l-4.553,4.553,4.553,4.553" transform="translate(-2897 -547.777)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                            </g>
                                                        </svg>
                                                        <span class="number">{{ticketCarte || '0'}}</span>
                                                        <svg  (click)="up('c')" class="arrow" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                            <g id="Groupe_50" data-name="Groupe 50" transform="translate(-1332 -546)">
                                                                <rect id="Rectangle_40" data-name="Rectangle 40" width="18" height="18" transform="translate(1332 546)" fill="#fff"/>
                                                                <path id="Tracé_26" data-name="Tracé 26" d="M4234.907,1107.313l4.544-4.544-4.544-4.544" transform="translate(-2895.381 -547.786)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>

                                                <div class="icon-box">
                                                    <div class="icon">
                                                        <svg  id="qr-code-svgrepo-com_1_" data-name="qr-code-svgrepo-com(1)" xmlns="http://www.w3.org/2000/svg" width="56.857" height="56.857" viewBox="0 0 56.857 56.857">
                                                            <path id="Tracé_24" data-name="Tracé 24" d="M0,0H56.857V56.857H0Z" fill="none"/>
                                                            <path id="Tracé_25" data-name="Tracé 25" d="M33.8,36.167V33.8H26.691V26.691H33.8v4.738h4.738v4.738H36.167V40.9H31.429v4.738H26.691V38.536h4.738V36.167Zm11.845,9.476H36.167V40.9H40.9V36.167h4.738ZM3,3H21.952V21.952H3ZM7.738,7.738v9.476h9.476V7.738ZM26.691,3H45.643V21.952H26.691Zm4.738,4.738v9.476H40.9V7.738ZM3,26.691H21.952V45.643H3Zm4.738,4.738V40.9h9.476V31.429Zm30.8-4.738h7.107v4.738H38.536ZM10.107,10.107h4.738v4.738H10.107Zm0,23.691h4.738v4.738H10.107ZM33.8,10.107h4.738v4.738H33.8Z" transform="translate(4.107 4.107)" fill="#fff"/>
                                                        </svg>
                                                    </div> <!-- Use FontAwesome or image for the correct icon -->
                                                    <div class="arrows">

                                                        <svg (click)="down('q')" class="arrow" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                            <g id="Groupe_49" data-name="Groupe 49" transform="translate(-1332 -546)">
                                                                <rect id="Rectangle_40" data-name="Rectangle 40" width="18" height="18" transform="translate(1332 546)" fill="#fff"/>
                                                                <path id="Tracé_26" data-name="Tracé 26" d="M4239.46,1098.224l-4.553,4.553,4.553,4.553" transform="translate(-2897 -547.777)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                            </g>
                                                        </svg>
                                                        <span class="number">{{ticketQrcode || '0'}}</span>
                                                        <svg  (click)="up('q')" class="arrow" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                            <g id="Groupe_50" data-name="Groupe 50" transform="translate(-1332 -546)">
                                                                <rect id="Rectangle_40" data-name="Rectangle 40" width="18" height="18" transform="translate(1332 546)" fill="#fff"/>
                                                                <path id="Tracé_26" data-name="Tracé 26" d="M4234.907,1107.313l4.544-4.544-4.544-4.544" transform="translate(-2895.381 -547.786)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <fieldset style="border: 0px !important;display: none">
                                        <legend class=" mb-3">Carte ou QRcode</legend>

                                        <span class="mt-3">
                                                                Nombre de Ticket en QR Code
                                                        </span>
                                        <div class="input-counter mb-3">
                                            <input  style="height: 37px;text-align: right;" [ngModel]="ticketQrcode"
                                                    (ngModelChange)="valueChangeQrcode($event)" type="text" oninput="this.value = this.value.replace(/[^0-9]/g, '');"  min="0" max="{{dataCheckEvent.nbrTicketParPersonne || 2}}"
                                                    [value]="ticketQrcode">
                                        </div>

                                        <span>
                                                             Nombre de Ticket par Carte
                                                        </span>

                                        <div class="input-counter">
                                            <input style="height: 37px;text-align: right;" [ngModel]="ticketCarte" oninput="this.value = this.value.replace(/[^0-9]/g, '');"  min="0" max="{{dataCheckEvent.nbrTicketParPersonne || 2}}"
                                                   (ngModelChange)="valueChangeCarte($event)" type="text"
                                                   [value]="ticketCarte">
                                        </div>

                                    </fieldset>

                                    <div class="product-add-to-cart"></div>

                                    <div *ngIf="checkMaxticket">
                                        <b style="color: red;">
                                            {{errorMaxticket}}
                                        </b>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12">

                                <div class="product-details-desc">
                                    <div class="product-add-to-cart">
                                        <div class="product-meta">
                                                <span class="rStyle"><i class='bx bx-minus before'></i>
                                                    TOTAL TICKET :<span class="in-stock"> {{ticketNow  || 'in-stock'}}</span></span>
                                            <span class="rStyle" ><i
                                                class='bx bx-minus before'></i>
                                                                PRIX UNITAIRE :
                                                    <a class="in-stock">{{formatNumber(pu)  || 0}} &nbsp; FRS CFA </a></span>
                                            <span class="rStyle"><i
                                                class='bx bx-minus  before'></i>
                                                                MONTANT TOTAL :
                                                    <a class="in-stock">{{formatNumber(pt) || 0}} &nbsp; FRS CFA</a></span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <ul>
                            <li *ngFor="let cat of event.catickets" class="ligneLi">

                                <div class="display-ticket row catLib">
                                    <div class="col-md-8 payCat">
                                        <a style="cursor: pointer;color: #fff;" *ngIf="cat.caticketqtenow<cat.caticketqte"
                                           (click)="goToRouting(cat)"
                                           href="javascript:void(0)" class="join-now-btn"> {{cat.caticketlib}} :
                                            {{formatNumber(cat.caticketarif)}} F CFA
                                        </a>
                                        <a *ngIf="cat.caticketqtenow>=cat.caticketqte" style="cursor: pointer; color: #fff;"
                                           class="join-now-btn"> {{cat.caticketlib}} :
                                            {{formatNumber(cat.caticketarif)}} F CFA
                                        </a>
                                        <svg style="float: right;margin-top: 12px;" xmlns="http://www.w3.org/2000/svg" width="17.982" height="12.845" viewBox="0 0 17.982 12.845">
                                            <path id="ticket-svgrepo-com" d="M75.56,204.845H64v-3.853a2.569,2.569,0,0,0,0-5.138V192H75.56v3.211h1.284V192h5.138v3.853a2.569,2.569,0,0,0,0,5.138v3.853H76.845v-3.211H75.56Zm0-8.349v3.853h1.284V196.5Z" transform="translate(-64 -192)" fill="#092055"/>
                                        </svg>
                                    </div>
                                    <div class="col-md-4 text-end btnBuy">
                                        <!--                                            <button nz-button nzType="primary" *ngIf="cat.caticketqtenow<cat.caticketqte"-->
                                        <!--                                                    (click)="goToRouting(cat)">primary</button>-->

                                        <a  *ngIf="cat.caticketqtenow<cat.caticketqte"
                                            (click)="goToRouting(cat)"
                                            href="javascript:void(0)" class="btn btn-primary textAchat" style=" position: relative; top: 15px; color: #fff; background-color:#092055; border: 1px solid #092055;"> Acheter maintenant
                                        </a>
                                        &nbsp;<strong class="join-now-btn"  *ngIf="cat.caticketqtenow>=cat.caticketqte"
                                                      style="    color: red !important;
    width: 85%;
    margin-top: 16px;
    background: #fff;
    height: 38px;
    text-align: center;
    padding: 7px;">GUICHET FERME</strong>
                                    </div>



                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="widget widget_events_details"
                         *ngIf="event.eventorg || event.eventinvite || event.eventpart ">
                        <h3 class="widget-title" style="color: #6bff00;">Organisateur</h3>
                        <ul>
                            <li *ngIf="event.eventorg" class="aColor"><span style="color: #dfd1d1 !important">Organisateur :</span> {{event.eventorg}} </li>
                            <li *ngIf="event.eventinvite" class="aColor"><span style="color: #dfd1d1 !important">Invité:</span> {{event.eventinvite}}</li>
                            <li *ngIf="event.eventpart" class="aColor"><span style="color:#dfd1d1 !important">Partenaire:</span> {{event.eventpart}}</li>
                        </ul>
                    </div>


                </aside>
            </div>
        </div>
    </div>

    <br><br><br><br><br><br>
</div>
