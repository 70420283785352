<app-header-billeterie></app-header-billeterie>

<app-banner-two-billeterie [dataEvents]="rows[0]"></app-banner-two-billeterie>

<div *ngFor="let item of dataCatEvents; let i=index" class="{{((i+1)==dataCatEvents.length) ?'lastEvent':''}}">
    <app-section-categorie [dataItems]="item"></app-section-categorie>
</div>


<app-boxes-billeterie></app-boxes-billeterie>

<div style="margin-top: -3%;">
<div *ngFor="let item of dataCatEventsSecond" >
    <app-section-categorie [dataItems]="item"></app-section-categorie>
</div>
</div>


<div *ngIf="!loadingCheck" class="d-flex justify-content-center">
    <div class="spinner-border" style="font-size: 30px;height: 80px;width: 80px;color: red;" role="status">
        <span class="sr-only"></span>
    </div>
</div>

<section style="background-color: #60BA07;display: none"  >
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-12" *ngIf="dataFreeEvent.length">
                <!--                <ng-image-slider [images]="dataItems" #nav></ng-image-slider>-->
                <div class="cont">
                    <div class="slider">
                        <button class="prev" (click)="prev()">&#9650;</button>
                        <div class="slider-view">
                            <ul class="slider-wrapper">
                                <li class="slide " *ngFor="let img of dataFreeEvent">
                                    <img class="im" src="{{img.url}}" alt="Image 1">
                                </li>
                                <!--                                <li class="slide active"><img class="im" src="https://cdn.rawgit.com/huijing/filerepo/gh-pages/lw1.jpg" alt="Image 1"></li>-->
                                <!--                                <li class="slide"><img class="im" src="https://i.pinimg.com/236x/7c/39/76/7c39762f38650923ef6bf47f2e1d527f.jpg" alt="Image 2"></li>-->
                                <!--                                <li class="slide"><img class="im" src="https://i.pinimg.com/236x/7c/39/76/7c39762f38650923ef6bf47f2e1d527f.jpg" alt="Image 3"></li>-->
                                <!--                                <li class="slide"><img class="im" src="https://thumbs.dreamstime.com/b/bench-park-beautiful-morning-light-background-48336462.jpg" alt="Image 4"></li>-->
                                <!--                                <li class="slide"><img class="im" src="https://png.pngtree.com/thumb_back/fw800/background/20240401/pngtree-blue-sneaker-made-of-fabric-with-a-white-sole-on-a-image_15649145.jpg" alt="Image 5"></li>-->
                                <!--                           -->
                            </ul>
                        </div>
                        <button #nextButton class="next" (click)="next()" >&#9660;</button>
                    </div>

                </div>
            </div>
            <div class="col-lg-8 col-sm-12">
                <div class="row">
                    <div class="col-lg-4 col-sm-12">
                        <p class="titleFree">Événements gratuits</p>
                    </div>
                    <div class="col-8">
                        <hr class="lineFree">
                    </div>

                    <div class="col-12" style="margin-top: 11%;">
                        <div class="row">
                            <div class="col-lg-7 col-sm-12">
                                <img src="{{currentLibreImg}}" class="imgPresentFree" alt="">
                            </div>
                            <div class="col-lg-5 col-sm-12">
                             <div>
                                 <span class="badge bgCat" id="myCat" *ngIf="currentLibreImg">EN CONCERT LIVE</span><br><br>
                                 <strong *ngIf="currentLibreImg" id="myEvent"
                                         style="font-size: 28px !important; color:#fff !important;text-shadow: 0 0 12px #000;">
                                     {{eventitre}}
                                 </strong>
                                 <br>

                                 <p class="textEventFree" *ngIf="currentLibreImg">{{textEventFree}}</p>
                             </div>

                                <br>
                                <button *ngIf="currentLibreImg" type="button" (click)="achetTicket()"
                                        class="btn btn-warning text-nowrap d-inline-flex position-relative me-4 rubBtn"
                                        style="margin-top: 5%;background: #33A442;">
                        <span style="padding: 1px;margin-left: 9px;margin-right: 9px;"><b>
                           <svg xmlns="http://www.w3.org/2000/svg" width="17.982" height="12.844" viewBox="0 0 17.982 12.844">
                              <path id="ticket-svgrepo-com" d="M75.56,204.845H64v-3.853a2.569,2.569,0,0,0,0-5.138V192H75.56v3.211h1.284V192h5.138v3.853a2.569,2.569,0,0,0,0,5.138v3.853H76.845v-3.211H75.56Zm0-8.349v3.853h1.284V196.5Z" transform="translate(-64 -192)" fill="#fff"/>
                            </svg>
                                &nbsp;&nbsp;
                     Acceder</b>  &nbsp;&nbsp; <svg xmlns="http://www.w3.org/2000/svg" width="11.859" height="19.719" viewBox="0 0 11.859 19.719">
                              <path id="Tracé_32" data-name="Tracé 32" d="M-1952.072,320.821l7.031,7.031-7.031,7.031" transform="translate(1954.9 -317.993)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
                            </svg>

                    </span>
                                </button>
                                <br><br>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>


</section>


<!--<div *ngIf="loadingCheck">-->
<!--    <div *ngFor="let item of rows">-->
<!--        <app-course-billeterie [items]="item"></app-course-billeterie>-->
<!--        &lt;!&ndash;-->
<!--        <app-homeone-courses></app-homeone-courses>-->
<!--        &ndash;&gt;-->

<!--    </div>-->
<!--</div>-->


<!--<app-footer *ngIf="!(location == '/login' || location == '/register' || location == '/coming-soon')"></app-footer>-->
