import {BrowserModule} from '@angular/platform-browser';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CountUpModule} from 'ngx-countup';
import {TabsModule} from 'ngx-tabset';
import {NgxScrollTopModule} from 'ngx-scrolltop';
import {StickyNavModule} from 'ng2-sticky-nav';
import {LightboxModule} from 'ngx-lightbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomePageOneComponent} from './components/pages/home-page-one/home-page-one.component';
import {HomePageTwoComponent} from './components/pages/home-page-two/home-page-two.component';
import {FooterComponent} from './components/common/footer/footer.component';
import {PreloaderComponent} from './components/common/preloader/preloader.component';
import {HomeoneAboutComponent} from './components/pages/home-page-one/homeone-about/homeone-about.component';
import {
    HomeoneMainBannerComponent
} from './components/pages/home-page-one/homeone-main-banner/homeone-main-banner.component';
import {HeaderStyleOneComponent} from './components/common/header-style-one/header-style-one.component';
import {CategoriesStyleOneComponent} from './components/common/categories-style-one/categories-style-one.component';
import {FunfactsComponent} from './components/common/funfacts/funfacts.component';
import {HomeoneCoursesComponent} from './components/pages/home-page-one/homeone-courses/homeone-courses.component';
import {PartnerStyleOneComponent} from './components/common/partner-style-one/partner-style-one.component';
import {InstructorsStyleOneComponent} from './components/common/instructors-style-one/instructors-style-one.component';
import {
    BecomeInstructorPartnerComponent
} from './components/common/become-instructor-partner/become-instructor-partner.component';
import {FeedbackStyleOneComponent} from './components/common/feedback-style-one/feedback-style-one.component';
import {BlogComponent} from './components/common/blog/blog.component';
import {HeaderStyleTwoComponent} from './components/common/header-style-two/header-style-two.component';
import {
    HometwoMainBannerComponent
} from './components/pages/home-page-two/hometwo-main-banner/hometwo-main-banner.component';
import {HometwoAboutComponent} from './components/pages/home-page-two/hometwo-about/hometwo-about.component';
import {HometwoCoursesComponent} from './components/pages/home-page-two/hometwo-courses/hometwo-courses.component';
import {OurMissionComponent} from './components/common/our-mission/our-mission.component';
import {InstructorsStyleTwoComponent} from './components/common/instructors-style-two/instructors-style-two.component';
import {WebinarCountdownComponent} from './components/common/webinar-countdown/webinar-countdown.component';
import {
    StudentsFeedbackFormComponent
} from './components/common/students-feedback-form/students-feedback-form.component';
import {HomePageThreeComponent} from './components/pages/home-page-three/home-page-three.component';
import {HomePageFourComponent} from './components/pages/home-page-four/home-page-four.component';
import {HomePageFiveComponent} from './components/pages/home-page-five/home-page-five.component';
import {HomePageSixComponent} from './components/pages/home-page-six/home-page-six.component';
import {HomePageSevenComponent} from './components/pages/home-page-seven/home-page-seven.component';
import {PartnerStyleTwoComponent} from './components/common/partner-style-two/partner-style-two.component';
import {OfferComponent} from './components/common/offer/offer.component';
import {CategoriesStyleTwoComponent} from './components/common/categories-style-two/categories-style-two.component';
import {
    HomethreeCoursesComponent
} from './components/pages/home-page-three/homethree-courses/homethree-courses.component';
import {HomethreeAboutComponent} from './components/pages/home-page-three/homethree-about/homethree-about.component';
import {FeedbackFormComponent} from './components/common/feedback-form/feedback-form.component';
import {
    HomethreeMainBannerComponent
} from './components/pages/home-page-three/homethree-main-banner/homethree-main-banner.component';
import {HeaderStyleThreeComponent} from './components/common/header-style-three/header-style-three.component';
import {
    HomefourMainBannerComponent
} from './components/pages/home-page-four/homefour-main-banner/homefour-main-banner.component';
import {HomefourCoursesComponent} from './components/pages/home-page-four/homefour-courses/homefour-courses.component';
import {BoxesComponent} from './components/common/boxes/boxes.component';
import {FeedbackStyleTwoComponent} from './components/common/feedback-style-two/feedback-style-two.component';
import {HomefiveCoursesComponent} from './components/pages/home-page-five/homefive-courses/homefive-courses.component';
import {
    HomefiveMainBannerComponent
} from './components/pages/home-page-five/homefive-main-banner/homefive-main-banner.component';
import {
    InstructorsStyleThreeComponent
} from './components/common/instructors-style-three/instructors-style-three.component';
import {FeaturesComponent} from './components/common/features/features.component';
import {HomesixCoursesComponent} from './components/pages/home-page-six/homesix-courses/homesix-courses.component';
import {
    CategoriesStyleThreeComponent
} from './components/common/categories-style-three/categories-style-three.component';
import {
    HomesixMainBannerComponent
} from './components/pages/home-page-six/homesix-main-banner/homesix-main-banner.component';
import {HeaderStyleFourComponent} from './components/common/header-style-four/header-style-four.component';
import {
    HomesevenMainBannerComponent
} from './components/pages/home-page-seven/homeseven-main-banner/homeseven-main-banner.component';
import {
    HomesevenCoursesComponent
} from './components/pages/home-page-seven/homeseven-courses/homeseven-courses.component';
import {HowItWorksComponent} from './components/common/how-it-works/how-it-works.component';
import {AboutPageOneComponent} from './components/pages/about-page-one/about-page-one.component';
import {AboutPageTwoComponent} from './components/pages/about-page-two/about-page-two.component';
import {OurStoryComponent} from './components/common/our-story/our-story.component';
import {OurValuesComponent} from './components/common/our-values/our-values.component';
import {InstructorsPageOneComponent} from './components/pages/instructors-page-one/instructors-page-one.component';
import {InstructorsPageTwoComponent} from './components/pages/instructors-page-two/instructors-page-two.component';
import {
    InstructorsPageThreeComponent
} from './components/pages/instructors-page-three/instructors-page-three.component';
import {
    InstructorsDetailsPageComponent
} from './components/pages/instructors-details-page/instructors-details-page.component';
import {GalleryPageComponent} from './components/pages/gallery-page/gallery-page.component';
import {EventsPageComponent} from './components/pages/events-page/events-page.component';
import {EventsDetailsPageComponent} from './components/pages/events-details-page/events-details-page.component';
import {PricingPageComponent} from './components/pages/pricing-page/pricing-page.component';
import {FeedbackPageComponent} from './components/pages/feedback-page/feedback-page.component';
import {PartnerPageComponent} from './components/pages/partner-page/partner-page.component';
import {LoginPageComponent} from './components/pages/login-page/login-page.component';
import {RegisterPageComponent} from './components/pages/register-page/register-page.component';
import {FaqPageComponent} from './components/pages/faq-page/faq-page.component';
import {ErrorPageComponent} from './components/pages/error-page/error-page.component';
import {ComingSoonPageComponent} from './components/pages/coming-soon-page/coming-soon-page.component';
import {BlogGridPageComponent} from './components/pages/blog-grid-page/blog-grid-page.component';
import {
    BlogRightSidebarPageComponent
} from './components/pages/blog-right-sidebar-page/blog-right-sidebar-page.component';
import {BlogFullWidthPageComponent} from './components/pages/blog-full-width-page/blog-full-width-page.component';
import {BlogDetailsPageComponent} from './components/pages/blog-details-page/blog-details-page.component';
import {ShopGridPageComponent} from './components/pages/shop-grid-page/shop-grid-page.component';
import {ShopFullWidthPageComponent} from './components/pages/shop-full-width-page/shop-full-width-page.component';
import {ProductsDetailsPageComponent} from './components/pages/products-details-page/products-details-page.component';
import {CartPageComponent} from './components/pages/cart-page/cart-page.component';
import {CheckoutPageComponent} from './components/pages/checkout-page/checkout-page.component';
import {ContactPageComponent} from './components/pages/contact-page/contact-page.component';
import {CategoryPageOneComponent} from './components/pages/category-page-one/category-page-one.component';
import {CategoryPageTwoComponent} from './components/pages/category-page-two/category-page-two.component';
import {CategoryPageThreeComponent} from './components/pages/category-page-three/category-page-three.component';
import {CoursesListPageComponent} from './components/pages/courses-list-page/courses-list-page.component';
import {
    CoursesGridStyleOneColumnsTwoPageComponent
} from './components/pages/courses-grid-style-one-columns-two-page/courses-grid-style-one-columns-two-page.component';
import {
    CoursesGridStyleOneColumnsThreePageComponent
} from './components/pages/courses-grid-style-one-columns-three-page/courses-grid-style-one-columns-three-page.component';
import {
    CoursesGridStyleOneColumnsFourPageComponent
} from './components/pages/courses-grid-style-one-columns-four-page/courses-grid-style-one-columns-four-page.component';
import {
    CoursesGridStyleTwoColumnsTwoPageComponent
} from './components/pages/courses-grid-style-two-columns-two-page/courses-grid-style-two-columns-two-page.component';
import {
    CoursesGridStyleTwoColumnsThreePageComponent
} from './components/pages/courses-grid-style-two-columns-three-page/courses-grid-style-two-columns-three-page.component';
import {
    CoursesGridStyleTwoColumnsFourPageComponent
} from './components/pages/courses-grid-style-two-columns-four-page/courses-grid-style-two-columns-four-page.component';
import {
    CoursesGridStyleThreeColumnsTwoPageComponent
} from './components/pages/courses-grid-style-three-columns-two-page/courses-grid-style-three-columns-two-page.component';
import {
    CoursesGridStyleThreeColumnsThreePageComponent
} from './components/pages/courses-grid-style-three-columns-three-page/courses-grid-style-three-columns-three-page.component';
import {
    CoursesGridStyleThreeColumnsFourPageComponent
} from './components/pages/courses-grid-style-three-columns-four-page/courses-grid-style-three-columns-four-page.component';
import {CoursesDetailsPageComponent} from './components/pages/courses-details-page/courses-details-page.component';
import {MyDashboardPageComponent} from './components/pages/my-dashboard-page/my-dashboard-page.component';
import {OrdersPageComponent} from './components/pages/orders-page/orders-page.component';
import {DownloadsPageComponent} from './components/pages/downloads-page/downloads-page.component';
import {EditAddressPageComponent} from './components/pages/edit-address-page/edit-address-page.component';
import {EditAccountPageComponent} from './components/pages/edit-account-page/edit-account-page.component';
import {
    EditBillingAddressPageComponent
} from './components/pages/edit-billing-address-page/edit-billing-address-page.component';
import {
    EditShippingAddressPageComponent
} from './components/pages/edit-shipping-address-page/edit-shipping-address-page.component';
import {HeaderBilleterieComponent} from './components/billeterie/common/header-billeterie/header-billeterie.component';
import {HomeBilleterieComponent} from './components/billeterie/body/home-billeterie/home-billeterie.component';
import {ConcertBilleterieComponent} from './components/billeterie/body/concert-billeterie/concert-billeterie.component';
import {ComedieBilleterieComponent} from './components/billeterie/body/comedie-billeterie/comedie-billeterie.component';
import {TheatreBilleterieComponent} from './components/billeterie/body/theatre-billeterie/theatre-billeterie.component';
import {
    FestivalBilleterieComponent
} from './components/billeterie/body/festival-billeterie/festival-billeterie.component';
import {
    SpectacleBilleterieComponent
} from './components/billeterie/body/spectacle-billeterie/spectacle-billeterie.component';
import {LoisirsBilleterieComponent} from './components/billeterie/body/loisirs-billeterie/loisirs-billeterie.component';
import {SportsBilleterieComponent} from './components/billeterie/body/sports-billeterie/sports-billeterie.component';
import {AutresBilleterieComponent} from './components/billeterie/body/autres-billeterie/autres-billeterie.component';
import {LoginBilleterieComponent} from './components/billeterie/body/login-billeterie/login-billeterie.component';
import {
    InscrireBilleterieComponent
} from './components/billeterie/body/inscrire-billeterie/inscrire-billeterie.component';
import {
    NotificationBilleterieComponent
} from './components/billeterie/body/notification-billeterie/notification-billeterie.component';
import {
    BannerOneBilleterieComponent
} from './components/billeterie/body/home-billeterie/banner-one-billeterie/banner-one-billeterie.component';
import {
    CoursesAllBilleterieComponent
} from './components/billeterie/body/home-billeterie/courses-all-billeterie/courses-all-billeterie.component';
import {
    CourseBilleterieComponent
} from './components/billeterie/body/home-billeterie/course-billeterie/course-billeterie.component';
import {
    BannerTwoBilleterieComponent
} from './components/billeterie/body/home-billeterie/banner-two-billeterie/banner-two-billeterie.component';
import {
    BoxesBilleterieComponent
} from './components/billeterie/body/home-billeterie/boxes-billeterie/boxes-billeterie.component';
import {
    SliderBilleterieComponent
} from './components/billeterie/body/home-billeterie/slider-billeterie/slider-billeterie.component';
// import {NgSpinnerModule} from 'ng-bootstrap-spinner';
import {NgLoadingSpinnerInterceptor, NgLoadingSpinnerModule} from 'ng-loading-spinner';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {
    EventdetailsBilleterieComponent
} from './components/billeterie/body/eventdetails-billeterie/eventdetails-billeterie.component';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {environment} from 'src/environments/environment';
import {EventwithoutkeyService} from './components/billeterie/body/home-billeterie/eventwithoutkey.service';
import {
    DashboardBilleterieComponent
} from './components/billeterie/body/dashboard-billeterie/dashboard-billeterie.component';
import {TicketBilleterieComponent} from './components/billeterie/body/ticket-billeterie/ticket-billeterie.component';
import {FowardBilleterieComponent} from './components/billeterie/body/foward-billeterie/foward-billeterie.component';
import {ReceiveBilleterieComponent} from './components/billeterie/body/receive-billeterie/receive-billeterie.component';
import {CarteBilleterieComponent} from './components/billeterie/body/carte-billeterie/carte-billeterie.component';
import {InfosBilleterieComponent} from './components/billeterie/body/infos-billeterie/infos-billeterie.component';
import {DatePipe} from '@angular/common';
import {PaymentBilleterieComponent} from './components/billeterie/body/payment-billeterie/payment-billeterie.component';
import {QRCodeModule} from 'angularx-qrcode';
import {ToastrModule} from 'ngx-toastr';
import {AngularCountdownDateTimeModule} from 'angular-countdown-date-time';
import {
    CheckpayBilleterieComponent
} from './components/billeterie/body/checkpay-billeterie/checkpay-billeterie.component';
import {
    EmailcheckBilleterieComponent
} from './components/billeterie/body/emailcheck-billeterie/emailcheck-billeterie.component';
import {UnlockBilleterieComponent} from './components/billeterie/body/unlock-billeterie/unlock-billeterie.component';
import {NewsBilleterieComponent} from './components/billeterie/body/news-billeterie/news-billeterie.component';
import {
    OrganisateurBilleterieComponent
} from './components/billeterie/body/organisateur-billeterie/organisateur-billeterie.component';
import {RinitpasswordComponent} from './components/billeterie/body/rinitpassword/rinitpassword.component';
import {ProposBilleterieComponent} from './components/billeterie/body/propos-billeterie/propos-billeterie.component';
import {MentionBilleterieComponent} from './components/billeterie/body/mention-billeterie/mention-billeterie.component';
import {FaqBilleterieComponent} from './components/billeterie/body/faq-billeterie/faq-billeterie.component';
import {
    ConditionBilleterieComponent
} from './components/billeterie/body/condition-billeterie/condition-billeterie.component';
import {
    PolitiqueBilleterieComponent
} from './components/billeterie/body/politique-billeterie/politique-billeterie.component';
import {VerifySmsComponent} from './components/billeterie/body/verify-sms/verify-sms.component';
import {DesabonneComponent} from './components/billeterie/body/desabonne/desabonne.component';
import {
    SectionCategorieComponent
} from './components/billeterie/body/home-billeterie/section-categorie/section-categorie.component';
import {NgImageSliderModule} from "ng-image-slider";
import {SuccessComponent} from './components/billeterie/body/success/success.component';
import {NavbarComponent} from './components/billeterie/body/navbar/navbar.component';
import {NgxSpinnerModule} from "ngx-spinner";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzSelectModule} from "ng-zorro-antd/select";
import {NgxFlagPickerModule} from "ngx-flag-picker/src/lib/ngx-flag-picker.module";
import {NzStepsModule} from "ng-zorro-antd/steps";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import {NzModalModule} from "ng-zorro-antd/modal";
import {CondUseNbhComponent} from "./components/billeterie/body/cond-use-nbh/cond-use-nbh.component";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzTabsModule} from "ng-zorro-antd/tabs";
import {NzSwitchModule} from "ng-zorro-antd/switch";
import {CustomNumberPipe} from "./components/core/custom-number.pipe";
import {MaxLengthDirective} from "./components/billeterie/body/ticket-billeterie/max-lenght.directive";
// import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {MaskApplierService, NgxMaskModule} from 'ngx-mask'
import {NzCollapseModule} from "ng-zorro-antd/collapse";
import {NzAlertModule} from "ng-zorro-antd/alert";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzTagModule} from "ng-zorro-antd/tag";
import { EventParcComponent } from './components/billeterie/body/event-parc/event-parc.component';
import {NzRadioModule} from "ng-zorro-antd/radio";
import { SuccesWaveComponent } from './components/billeterie/body/succes-wave/succes-wave.component';
import { ErrorWaveComponent } from './components/billeterie/body/error-wave/error-wave.component';

import { SwiperModule } from 'swiper/angular';
@NgModule({
    declarations: [
        AppComponent,
        HomePageOneComponent,
        HomePageTwoComponent,
        FooterComponent,
        PreloaderComponent,
        HomeoneAboutComponent,
        HomeoneMainBannerComponent,
        HeaderStyleOneComponent,
        CategoriesStyleOneComponent,
        FunfactsComponent,
        HomeoneCoursesComponent,
        PartnerStyleOneComponent,
        InstructorsStyleOneComponent,
        BecomeInstructorPartnerComponent,
        FeedbackStyleOneComponent,
        BlogComponent,
        HeaderStyleTwoComponent,
        HometwoMainBannerComponent,
        HometwoAboutComponent,
        HometwoCoursesComponent,
        OurMissionComponent,
        InstructorsStyleTwoComponent,
        WebinarCountdownComponent,
        StudentsFeedbackFormComponent,
        HomePageThreeComponent,
        HomePageFourComponent,
        HomePageFiveComponent,
        HomePageSixComponent,
        HomePageSevenComponent,
        PartnerStyleTwoComponent,
        OfferComponent,
        CategoriesStyleTwoComponent,
        HomethreeCoursesComponent,
        HomethreeAboutComponent,
        FeedbackFormComponent,
        HomethreeMainBannerComponent,
        HeaderStyleThreeComponent,
        HomefourMainBannerComponent,
        HomefourCoursesComponent,
        BoxesComponent,
        FeedbackStyleTwoComponent,
        HomefiveCoursesComponent,
        HomefiveMainBannerComponent,
        InstructorsStyleThreeComponent,
        FeaturesComponent,
        HomesixCoursesComponent,
        CategoriesStyleThreeComponent,
        HomesixMainBannerComponent,
        HeaderStyleFourComponent,
        HomesevenMainBannerComponent,
        HomesevenCoursesComponent,
        HowItWorksComponent,
        AboutPageOneComponent,
        AboutPageTwoComponent,
        OurStoryComponent,
        OurValuesComponent,
        InstructorsPageOneComponent,
        InstructorsPageTwoComponent,
        InstructorsPageThreeComponent,
        InstructorsDetailsPageComponent,
        GalleryPageComponent,
        EventsPageComponent,
        EventsDetailsPageComponent,
        PricingPageComponent,
        FeedbackPageComponent,
        PartnerPageComponent,
        LoginPageComponent,
        RegisterPageComponent,
        FaqPageComponent,
        ErrorPageComponent,
        ComingSoonPageComponent,
        BlogGridPageComponent,
        BlogRightSidebarPageComponent,
        BlogFullWidthPageComponent,
        BlogDetailsPageComponent,
        ShopGridPageComponent,
        ShopFullWidthPageComponent,
        ProductsDetailsPageComponent,
        CartPageComponent,
        CheckoutPageComponent,
        ContactPageComponent,
        CategoryPageOneComponent,
        CategoryPageTwoComponent,
        CategoryPageThreeComponent,
        CoursesListPageComponent,
        CoursesGridStyleOneColumnsTwoPageComponent,
        CoursesGridStyleOneColumnsThreePageComponent,
        CoursesGridStyleOneColumnsFourPageComponent,
        CoursesGridStyleTwoColumnsTwoPageComponent,
        CoursesGridStyleTwoColumnsThreePageComponent,
        CoursesGridStyleTwoColumnsFourPageComponent,
        CoursesGridStyleThreeColumnsTwoPageComponent,
        CoursesGridStyleThreeColumnsThreePageComponent,
        CoursesGridStyleThreeColumnsFourPageComponent,
        CoursesDetailsPageComponent,
        MyDashboardPageComponent,
        OrdersPageComponent,
        DownloadsPageComponent,
        EditAddressPageComponent,
        EditAccountPageComponent,
        EditBillingAddressPageComponent,
        EditShippingAddressPageComponent,
        HeaderBilleterieComponent,
        HomeBilleterieComponent,
        ConcertBilleterieComponent,
        ComedieBilleterieComponent,
        TheatreBilleterieComponent,
        FestivalBilleterieComponent,
        SpectacleBilleterieComponent,
        LoisirsBilleterieComponent,
        SportsBilleterieComponent,
        AutresBilleterieComponent,
        LoginBilleterieComponent,
        InscrireBilleterieComponent,
        NotificationBilleterieComponent,
        BannerOneBilleterieComponent,
        CoursesAllBilleterieComponent,
        CourseBilleterieComponent,
        BannerTwoBilleterieComponent,
        BoxesBilleterieComponent,
        SliderBilleterieComponent,
        EventdetailsBilleterieComponent,
        DashboardBilleterieComponent,
        TicketBilleterieComponent,
        FowardBilleterieComponent,
        ReceiveBilleterieComponent,
        CarteBilleterieComponent,
        InfosBilleterieComponent,
        PaymentBilleterieComponent,
        CheckpayBilleterieComponent,
        EmailcheckBilleterieComponent,
        RinitpasswordComponent,
        UnlockBilleterieComponent,
        NewsBilleterieComponent,
        OrganisateurBilleterieComponent,
        RinitpasswordComponent,
        ProposBilleterieComponent,
        MentionBilleterieComponent,
        FaqBilleterieComponent,
        ConditionBilleterieComponent,
        PolitiqueBilleterieComponent,
        VerifySmsComponent,
        DesabonneComponent,
        SectionCategorieComponent,
        SuccessComponent,
        NavbarComponent,
        CondUseNbhComponent,
        CustomNumberPipe,
        MaxLengthDirective,
        EventParcComponent,
        SuccesWaveComponent,
        ErrorWaveComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(), // ToastrModule added
        CarouselModule,
        CountUpModule,
        TabsModule,
        NgxScrollTopModule,
        // NgSpinnerModule,
        // NgbModule,
        NgLoadingSpinnerModule,
        StickyNavModule,
        LightboxModule,
        QRCodeModule,
        HttpClientModule,
        ReactiveFormsModule,
        AngularCountdownDateTimeModule,
        NgxSpinnerModule,
        LoggerModule.forRoot({
            serverLoggingUrl: environment.serverLoggingUrl,
            level: NgxLoggerLevel.TRACE,
            serverLogLevel: NgxLoggerLevel.ERROR
        }),
        LoggerModule.forRoot({
            level: environment.state === 'production' ? NgxLoggerLevel.OFF :
                environment.state === 'developpement' ? NgxLoggerLevel.TRACE : NgxLoggerLevel.TRACE,
            serverLogLevel: NgxLoggerLevel.OFF
        }),

        FormsModule,
        NgImageSliderModule,
        NzInputModule,
        NzSelectModule,
        NgxFlagPickerModule,
        NzStepsModule,
        NzIconModule,
        NzButtonModule,
        NzCheckboxModule,
        NzModalModule,
        NzTabsModule,
        NzSwitchModule,
        NgxMaskModule.forRoot(),
        NzCollapseModule,
        NzAlertModule,
        NzTableModule,
        NzTagModule,
        NzRadioModule,
        SwiperModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: NgLoadingSpinnerInterceptor, multi: true},
        DatePipe,
        NzMessageService,
        MaskApplierService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
