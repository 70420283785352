import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'src/environments/environment';
import {EventwithoutkeyService} from '../home-billeterie/eventwithoutkey.service';
import {Spectateur, UserLogin} from '../spectateur';

@Component({
    selector: 'app-emailcheck-billeterie',
    templateUrl: './emailcheck-billeterie.component.html',
    styleUrls: ['./emailcheck-billeterie.component.scss']
})
export class EmailcheckBilleterieComponent implements OnInit {


    data: UserLogin = new UserLogin();
    public loginForm;
    public loginFormCf;
    checking: boolean = false;
    checkingMessage: string = "";
    key;
    dContry: any = [];
    current: number = 0;

    constructor(private _activatedRoute: ActivatedRoute, private toastr: ToastrService,
                public fb: UntypedFormBuilder, private router: Router,
                private gestserv: EventwithoutkeyService, private logger: NGXLogger) {
    }

    ngOnInit(): void {
        localStorage.clear();

        this._activatedRoute.queryParams.forEach((params: Params) => {
            if (params['key']) {
                this.key = params['key'];
            }
        });

        this.loginForm = this.fb.group({
            'indicatifPays': ['225', Validators.required],
            'username': [null, Validators.required],
        });
        this.loginFormCf = this.fb.group({
            'otp': [null, Validators.required],
            'pwd': [null, Validators.required],
            'cfpwd': [null, Validators.required],
        });
        this.loadPays();
    }


    login() {

        if (this.current === 0) {

            if (!this.loginForm.valid) {
                this.checkingMessage = "Veuillez renseigner votre telephone";
                this.toastr.error('Echec!', "Veuillez renseigner votre telephone");
                return null;
            }
            this.checking = true;
            let request: string = `${this.loginForm.get('indicatifPays').value}${this.loginForm.get('username').value}`;
            this.gestserv.post({}, "billeterie/:session/:startpasswdrenew/:" + request).subscribe(data => {
                    this.logger.trace('INFOS CNX', data);

                    switch (data.status) {
                        case 200: {
                            this.checking = false;
                            this.current = 1;
                            // this.toastr.success('SUCCES', "UN CODE DE REINITIALISATION VOUS A ETE ENVOYE PAR SMS");
                            break;
                        }
                    }
                },
                error => {
                    switch (error.status) {
                        case 200: {
                            this.checking = false;
                            this.current = 1;
                            this.toastr.success('SUCCES', "UN CODE DE REINITIALISATION VOUS A ETE ENVOYE PAR SMS");
                            break;
                        }
                        default: {
                            this.toastr.error('Echec!', 'compte inconnu !');
                            this.checking = false;
                            break;
                        }
                    }
                });
        } else {
            if (!this.loginFormCf.valid) {
                this.toastr.error('Echec!', "Veuillez renseigner tous les champs");
                return null;
            }
            if (!this.estQueDesChiffres(this.loginFormCf.get('pwd').value)) {
                this.toastr.error('Echec!', "Votre mot de passe doit contenir que des chiffres.");
                return;
            }
            if (this.loginFormCf.get('pwd').value.length < 5) {
                this.toastr.error('Echec!', "Votre mot de passe doit faire cinq chiffre.");
                return;
            }
            if (this.loginFormCf.get('pwd').value != this.loginFormCf.get('cfpwd').value) {
                this.toastr.error('Echec!', "Les deux mots de passe ne correspondent pas.");
                return;
            }


            let request: any = {
                "codeValidation": this.loginFormCf.value.otp,
                "password": this.loginFormCf.value.pwd,
                "spectateurTel": `${this.loginForm.get('indicatifPays').value}${this.loginForm.get('username').value}`
            };
            this.checking = true;
            this.gestserv.post(request, "billeterie/:session/:renewpasswdForgetByTelephone").subscribe(data => {
                    this.checking = false;

                    switch (data.status) {
                        case 200: {
                            this.current = 0;
                            this.toastr.success('SUCCES', "Votre mot de passe a été modifié avec succès.");
                            setTimeout(() => {
                                this.router.navigate(["/connecter"]);
                            }, 1000)
                            break;
                        }
                    }
                },
                error => {
                    this.checking = false;
                    switch (error.status) {
                        case 200: {
                            this.current = 0;
                            this.toastr.success('SUCCES', "Votre mot de passe a été modifié avec succès.");
                            setTimeout(() => {
                                this.router.navigate(["/connecter"]);
                            }, 1000)
                            break;
                        }
                        default: {
                            this.toastr.error('Echec!', error.error.err || 'Une erreur est survenue');
                            this.checking = false;
                            break;
                        }
                    }
                });
        }

    }

    estQueDesChiffres(chaine) {
        return /^\d+$/.test(chaine);
    }

    loadPays() {

        this.gestserv.get(`/billeterie/session/getAllListPays`).subscribe(data => {

                switch (data.status) {
                    case 200: {

                        if (data.body.length) {

                            this.dContry = data.body.map((d) => {
                                return {
                                    ...d,
                                    country: d.paysNom,
                                    indicatif: d.paysIndicatif,
                                    code: d.paysCode,
                                }
                            });
                        }

                        break;
                    }
                    default : {

                    }
                }
            },
            error => {

                switch (error.status) {
                    case 401: {
                        //this.router.navigate(['/authentication/signin']);
                        break;
                    }
                    default: {
                        this.logger.trace(error);
                        break;
                    }
                }
            });
    }

}

