import {AfterViewInit, Component, HostListener, Input, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {EventwithoutkeyService} from "../eventwithoutkey.service";
import {Router} from "@angular/router";
import * as moment from "moment";
import {animate, state, style, transition, trigger} from "@angular/animations";

declare let $: any;

@Component({
    selector: 'app-banner-two-billeterie',
    templateUrl: './banner-two-billeterie.component.html',
    styleUrls: ['./banner-two-billeterie.component.scss'],
    // animations: [
    //     trigger('slideInOut', [
    //         state('in', style({ transform: 'translateX(0)' })), // Visible state
    //         state('out', style({ transform: 'translateX(-100%)' })), // Hidden (or slide-out) state
    //         transition('in => out', animate('300ms ease-in-out')), // Animation for hiding
    //         transition('out => in', animate('300ms ease-in-out')) // Animation for showing
    //     ])
    // ]
    // animations: [
    //     trigger('slideInOut', [
    //         state('in', style({
    //             transform: 'translateX(0)'
    //         })),
    //         transition('void => in', [
    //             style({ transform: 'translateX(100%)' }), // L'image entre par la droite
    //             animate('1s ease-in-out') // Durée de la transition
    //         ]),
    //         transition('in => void', [
    //             animate('1s ease-in-out', style({ transform: 'translateX(-100%)' })) // L'image sort par la gauche
    //         ])
    //     ])
    // ]
})
export class BannerTwoBilleterieComponent implements OnInit, AfterViewInit {
    @Input() dataEvents: any = []
    // imgDefault: string = "../../../../../../assets/img/mix.jpg";
    //imgDefault: string = "";
    imgDefault: string = "../../../../../../assets/img/agoMusic.jpg";
    // imgDefault: string = "../../../../../../assets/img/mixDJ.png";
dataManuelImage:any=[
    {
        url:"assets/img/agoMusic.jpg",
        type:"CONCERT",
        titre:"PIERRE AKENDENGUE EN LIVE",
        description:"Pierre AKENDENGUE en concert\n" +
            "Pour ses 65 ans de carrière, Pierre AKENDENGUE vous réserve de belles surprises et une soirée inédite à son concert le 25 Avril au Sofitel Hôtel Ivoire à 20h.",
    },
    {
        url:"assets/img/com_nbh.jpg",
        type:"",
        titre:"",
        description:"",
    }
]
    dataManuelImage2: any[] = [
        {
            url:"assets/img/agoMusic.jpg",
            type:"CONCERT",
            titre:"PIERRE AKENDENGUE EN LIVE",
            description:"Pierre AKENDENGUE en concert\n" +
                "Pour ses 65 ans de carrière, Pierre AKENDENGUE vous réserve de belles surprises et une soirée inédite à son concert le 25 Avril au Sofitel Hôtel Ivoire à 20h.",
        },
        {
            url: "assets/img/com_nbh.jpg",
            type: "",
            titre: "",
            description: "",
        }
    ];
    // imgDefault: string = "../../../../../../assets/img/panel.JPG";
    rows: any = []
    params: any = []
    dataEvent: any = []
    dataAllEvent: any = []
    dataMenu: any = [
        {
            uri: "/",
            label: "Accueil",
        }, {
            uri: "/concert",
            label: "Découvrir",
        }, {
            uri: "/connecter",
            label: "Mon profil",
        }
    ]
    private currentImageIndex: number = 0;

    slideIndex = 0;
    timeoutId;

    query: string = '';
    suggestions: any = [];
    filteredSuggestions: string[] = [];
    showSuggestions: boolean = true;
    private indexParcours: number=0;

    indexParcourssss: number = 0;
    currentImage: any;
    intervalId: any;


    //========
    isButtonHidden = false;
    isCategoryHidden = false;
    currentBackgroundImage: string = '';
    currentTitle: string = '';
    currentDescription: any;

    slideState: 'in' | 'out' = 'in';

    showSlides(index: number) {
        this.slideIndex = index;
    }

    nextSlide() {
        this.slideIndex = (this.slideIndex + 1) % this.dataEvent.length;
    }

    prevSlide() {
        this.slideIndex = (this.slideIndex - 1 + this.dataEvent.length) % this.dataEvent.length;
    }



    constructor(private gestserv: EventwithoutkeyService, private router: Router) {
        // $(this).css('background-color', 'red');
        // $('.mission-area').css('background-image', `https://nbh-organisateur.com/digitalbackendorganisateur/event/image/download/XYOV2586 !important`)
        // $('.mission-area').css('background-image', 'url(https://nbh-organisateur.com/digitalbackendorganisateur/event/image/download/XYOV2586)');

    }

    ngOnInit(): void {

        if (this.dataManuelImage2.length > 0) {
            this.currentImage = this.dataManuelImage2[0]?.url; // Affiche la première image
            this.startSlideshow();
        }

        // $('#your-element').css('background-image', 'url('+this.otherImg+')');
        // $('.mission-area').css({
        //     'background-image': 'url('+this.otherImg+')',
        //     'background-size': 'cover', // or 'contain', 'auto', etc.
        //     'background-repeat': 'no-repeat',
        //     'background-position': 'center'
        // });
        this.params = this.dataEvents

    }
    toggleSlide() {
        this.slideState = this.slideState === 'in' ? 'out' : 'in';
    }
    startSlideshow(): void {
        // Ensure the data array is valid
        if (!this.dataManuelImage2 || this.dataManuelImage2.length === 0) {
            console.error('Aucune image disponible !');
            return;
        }

        const displayImage = (index: number) => {
            // Get the current element
            const currentElement = this.dataManuelImage2[index];


            // Validate the URL
            if (currentElement?.url && currentElement.url.trim() !== '') {
                this.toggleSlide()
                this.currentImage = currentElement.url;

                $('#myEvent').html(`<b>${currentElement?.titre}</b>`);
                $('#myContentEventt').html(`<b>${currentElement?.description}</b>`);
                if(!currentElement?.description){
                    $('#myCat').addClass('hidden');
                    $('#btnGo').addClass('hidden');
                }else{
                    $('#btnGo').removeClass('hidden');
                    $('#myCat').removeClass('hidden');
                }


            } else {
                this.currentImage = '';
            }
            const nextIndex = (index + 1) % this.dataManuelImage2.length;
            this.intervalId = setTimeout(() => displayImage(nextIndex), 10000); // 10 seconds per image
        };

        // Start the slideshow from the first image
        displayImage(0);
    }



    ngOnDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }
    ngAfterViewInit() {
       // $('#your-element').css('background-image', 'url(' + this.imgDefault + ')');
      //  this.loadCategorie();
        this.loadEvent();
        this.selMenu(0);


        /**
         * Demarrage manuel du slider
         */
      //  this.startProcessing();
    }

    missionSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        items: 1,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ]
    }

    selectNav(data: any, i: number) {
        for (let j = 0; j < this.rows.length; j++) {
            $('#navCatCheck' + j).removeClass("active");
        }
        $('#navCatCheck' + i).addClass('active');
        this.router.navigateByUrl('/concert?key=' + data.categoriecode);

    }

    loadCategorie() {
        this.gestserv.get("/billeterie/event/getAllcategorieEvent").subscribe(data => {
                switch (data.status) {
                    case 200: {
                        this.rows = data.body;
                        break;
                    }
                }
            },
            error => {
                switch (error.status) {
                    case 401: {
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });
    }


    selMenu(i: number) {
        for (let j = 0; j < this.dataMenu.length; j++) {
            $('#ligneMenu' + j).removeClass("activeItem");
        }
        $('#ligneMenu' + i).addClass('activeItem');
    }

    goTicket() {
        // return
        this.router.navigateByUrl('/check-event?key=XZVY15');
        // this.router.navigateByUrl('/check-event?key=' + this.params.eventcode);
    }

    loadEvent() {
        this.dataEvent = [];
        this.dataAllEvent = [];
        clearInterval(this.timeoutId);
        this.gestserv.get("/billeterie/allevent").subscribe(data => {
                switch (data.status) {
                    case 200: {
                        this.dataEvent = this.splitEventsByCategory(data.body);
                        this.dataEvent.forEach((e, index) => {
                            this.dataAllEvent = [
                                ...this.dataAllEvent,
                                ...e.events
                            ]
                        });
                        let pEvent: any = []
                        this.suggestions = [];
                        data.body.forEach((e) => {
                            pEvent = [...pEvent, ...e.events]
                            e.events.forEach((d) => {
                                this.suggestions.push({
                                    "eventitre": d.eventitre +" le "+moment(d.eventdebut).format("DD-MM-YYYY à HH:mm:ss"),
                                    "eventdebut": d.eventdebut,
                                    "eventlieu": d.eventlieu,
                                    "eventcode": d.eventcode,
                                    "url": d.url,
                                })
                            })
                        })
                        if(this.dataEvent.length){
                            this.params = this.dataEvent[0].events[0];
                        }

                        // this.timeoutId = setInterval(() => {
                        //     this.changeBackgroundImage();
                        // }, 10000);
                        break;
                    }


                }
            },
            error => {
                switch (error.status) {
                    case 401: {
                        //this.router.navigate(['/authentication/signin']);
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });
    }

    changeEvent(event: any, cat: string, actin: number = 0) {


        if (actin === 1) {
            clearInterval(this.timeoutId);
        }
        this.params = event;

      //  $('#your-element').css('background-image', 'url(' + event.url + ')');

        let myCat = document.getElementById("myCat");
        let myEvent = document.getElementById("myEvent");
        let myContentEvent = document.getElementById("myContentEvent");

        myEvent.innerHTML = event?.eventitre;
        myContentEvent.innerHTML = ` ${event.eventDesciption1} <br> <br>
                        Ticket disponible ici !`;
        if (!cat) return
        myCat.innerHTML = cat;
    }

    splitEventsByCategory(data) {
        const result = [];
        data.forEach(category => {
            const {categorie, events} = category;
            if (events.length > 2) {
                for (let i = 0; i < events.length; i += 2) {
                    let data = events.slice(i, i + 2);
                    data = data.map((e) => {
                        return {
                            ...e,
                            categorie: categorie
                        }
                    })
                    result.push({categorie, events: data});
                }
            } else {
                result.push(category);
            }
        });
        return result;
    }


    changeBackgroundImage() {

        this.currentImageIndex = (this.currentImageIndex + 1) % this.dataAllEvent.length;
        this.setBackgroundImage();
    }

    setBackgroundImage() {
        // $('#your-element').css('background-image', 'url(' + this.imgDefault + ')');
        const sliderWrapper = document.getElementById('your-element');
        let data = this.dataAllEvent[this.currentImageIndex];
        this.changeEvent(data, data?.categorie, 0)
       // $('#your-element').css('background-image', 'url(' + data?.url + ')');
        // sliderWrapper.style.transform = `translateX(-${this.currentImageIndex * 100}%)`;
    }

    // Méthode qui filtre les suggestions
    onSearch(): void {
        this.showSuggestions = true;
        const queryLower = this.query.toLowerCase();
        this.filteredSuggestions = this.suggestions.filter(suggestion => suggestion.eventitre.toLowerCase().includes(queryLower)
        );
    }

    // Sélection d'une suggestion
    selectSuggestion(data: any): void {
        this.router.navigateByUrl('/check-event?key=' + data.eventcode);
        // this.query = suggestion;
        // this.filteredSuggestions = [];
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event): void {
        this.showSuggestions = false;
        this.query = "";
        const clickedInside = (event.target as HTMLElement).closest('.search-container');
        if (!clickedInside) {
            //this.showSuggestions = false;
        }
    }


}
