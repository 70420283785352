import {Component} from '@angular/core';
import {dataMenu, imgProfil} from "../../../core/config-var";
import {Spectateur} from "../spectateur";
import {environment} from "../../../../../environments/environment";
import {ActivatedRoute, NavigationEnd, Params, Router, RouterStateSnapshot} from "@angular/router";
import {filter} from "rxjs/operators";

declare let $: any;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
    dMenu: any = dataMenu;
    imgP: string = imgProfil;
    u: any = [];
    currentUrl: string = "";
    key: string = "";
    itemApropos: any = {
        uri: '/propos',
        label: 'A Propos',
        is: true,
        icon: 'a'
    };
    isMenuOpen = false;
    urlOrgan: string = environment.urlOrgan;

    constructor(private router: Router, private _activatedRoute: ActivatedRoute) {
    }

    selectNav(i: number, data: any = []) {
        for (let j = 0; j < this.dMenu.length; j++) {
            $('#nav' + j).removeClass("active");
        }
        $('#nav' + i).addClass('active');
        if (data?.uri) {
            this.router.navigateByUrl(data.uri);
        }
    }


    ngOnInit(): void {

        this.selectNav(1, []);
        this.u = new Spectateur();

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.currentUrl = event.urlAfterRedirects;
        });
        this._activatedRoute.queryParams.forEach((params: Params) => {
            if (params['key']) {
                this.key = params['key'];
            }
        });

        this.u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        let path: string;
        this.dMenu = dataMenu.map((e) => {
            let r = !(e.uri == "/inscrire" && this.u);
            path = e.uri == "/connecter" && this.u ? "/ticket" : e.uri;
            return {
                ...e,
                is: r,
                uri: path
            }
        })
        if (!this.u) {
            if (this.key) return;
            // this.router.navigate(['/']);
        }
    }

    goToHome() {
        this.router.navigate(['/']);
    }

    logout() {
        localStorage.clear();
        this.u = null;
        this.router.navigate(['/connecter']);
    }

    cnx() {
        this.router.navigate(['/connecter'])
    }

    inscrire() {
        this.router.navigate(['/inscrire'])
    }

    profil() {
        this.router.navigate(['/achat'])
    }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;

        let burger: any = document.querySelector('.burger');
        let navLinks: any = document.querySelector('.nav-links');
        let links: any = document.querySelectorAll('.nav-links li');


        burger.classList.toggle('toggle');

        if (navLinks) {
            // Vérifier le style actuel de `display`
            const displayStyle = window.getComputedStyle(navLinks).display;

            if (displayStyle === 'none') {
                navLinks.style.display = 'flex';
                setTimeout(() => {
                    navLinks.classList.toggle('nav-active');
                    //  navLinks.classList.add('active');
                }, 10); // Petit délai pour activer la transition
            } else {
                // Supprimer la classe "active" pour masquer avec transition
                navLinks.classList.remove('active');
                setTimeout(() => {
                    navLinks.style.display = 'none';
                    navLinks.classList.toggle('nav-active');
                }, 300); // Correspond à la durée définie dans `transition`
            }
        } else {
        }
        return
        debugger
        burger.addEventListener('click', () => {
            navLinks.classList.toggle('nav-active');
            links.forEach((link, index) => {
                if (link.style.animation) {
                    link.style.animation = '';
                } else {
                    link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.3}s`;
                }
            });
            burger.classList.toggle('toggle');
        });
    }


    burger() {
        let burger: any = document.querySelector('.burger');
        let navLinks: any = document.querySelector('.nav-links');
        let links: any = document.querySelectorAll('.nav-links li');

        burger.addEventListener('click', () => {
            navLinks.classList.toggle('nav-active');
            links.forEach((link, index) => {
                if (link.style.animation) {
                    link.style.animation = '';
                } else {
                    link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.3}s`;
                }
            });
            burger.classList.toggle('toggle');
        });
    }
}
