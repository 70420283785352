<app-header-billeterie></app-header-billeterie>


<section class="mission-area ptb-100" id="myImg" style="padding-top: 0;height: 420px !important;z-index: 1;position: relative;">
    <section class="mission-area" style="background: rgba(0, 0, 0, 0.5) !important;height: 420px !important;">
        <app-navbar></app-navbar>
       <div class="container">

            <div class="row">
                <div class="col-12">
                        <strong class="enventInfo">
                            {{event?.eventitre}}
                        </strong>
                </div>
                <div class="col-12">
                    <p class="sTitle">{{event?.eventdesclevel1}}</p>
                </div>
            </div>
        </div>
    </section>
</section>



<div *ngIf="!loadingCheck" class="d-flex justify-content-center">
    <div class="spinner-border" style="font-size: 30px;
    height: 120px;
    width: 120px;
    color: red;" role="status">
        <span class="sr-only"></span>
    </div>
</div>

<!--height: 100%;-->
<div *ngIf="loadingCheck" class="events-details-area" style="background-color: #006837 !important;">
    <div class="container" style="border: 1px solid #fff;width: 100%; !important; background-color: #fff;    border-radius: 15px;margin-top: -50px;
    z-index: 9;
    position: relative;">
        <div class="row">
            <div class="col-lg-7 col-sm-12">
                <div class="events-details-header">
<!--                    <a routerLink="/" class="back-all-events"><i class='bx bx-chevrons-left'></i>Retour</a>-->
                    <ul class="events-info-meta d-none">
                        <li><i class="flaticon-timetable"></i> </li>
                    </ul>

                    <div class="events-meta">
                        <ul>
                            <li>
                                <div class="row">
                                    <div class="col-lg-3  ant-col-sm-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.295" height="26.295" viewBox="0 0 26.295 26.295">
                                            <g id="folder-with-files-svgrepo-com" transform="translate(0)">
                                                <path id="Tracé_17" data-name="Tracé 17" d="M2,8.508a13.2,13.2,0,0,1,.091-2.224A5.259,5.259,0,0,1,6.284,2.091,13.2,13.2,0,0,1,8.508,2c.508,0,.763,0,1.007.023A5.259,5.259,0,0,1,12.381,3.21c.189.157.369.336.728.7l.724.723A11.241,11.241,0,0,0,16.084,6.6a5.26,5.26,0,0,0,1.115.462,11.237,11.237,0,0,0,2.982.2h.491c3.461,0,5.191,0,6.316,1.012a3.924,3.924,0,0,1,.295.295c1.012,1.125,1.012,2.855,1.012,6.316v2.9c0,4.958,0,7.437-1.54,8.978s-4.019,1.54-8.978,1.54H12.518c-4.958,0-7.437,0-8.978-1.54S2,22.735,2,17.777Z" transform="translate(-2 -2)" fill="#092055" opacity="0.5"/>
                                                <path id="Tracé_18" data-name="Tracé 18" d="M23.151,7.257a6.687,6.687,0,0,0-.033-.827,3.944,3.944,0,0,0-3.4-3.4A9.912,9.912,0,0,0,18.55,3H9.988c.152.137.324.308.607.591l.724.723A11.237,11.237,0,0,0,13.57,6.281a5.259,5.259,0,0,0,1.115.462,11.239,11.239,0,0,0,2.982.2h.491A20.833,20.833,0,0,1,23.151,7.257Z" transform="translate(0.514 -1.685)" fill="#092055"/>
                                                <path id="Tracé_19" data-name="Tracé 19" d="M12.25,10.236a.986.986,0,0,1,.986-.986H19.81a.986.986,0,1,1,0,1.972H13.236A.986.986,0,0,1,12.25,10.236Z" transform="translate(1.226 0.282)" fill="#092055" fill-rule="evenodd"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="col-lg-9  ant-col-sm-9">
                                        <span class="cat">Categorie</span>
                                        <a href="javascript:void(0)" class="aColor">{{event.categorie}}</a>
                                    </div>
                                </div>
                                <!--                                <i class='bx bx-folder-open'></i>-->
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-lg-3  col-sm-6">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25.429" height="27.224" viewBox="0 0 25.429 27.224">
                                            <path id="date-input-svgrepo-com" d="M28.544,12.463H5.027a.915.915,0,0,0-.927.927V27a2.719,2.719,0,0,0,2.722,2.722H26.748A2.719,2.719,0,0,0,29.471,27V13.39A.915.915,0,0,0,28.544,12.463ZM12.267,25.148a.915.915,0,0,1-.927.927h-1.8a.915.915,0,0,1-.927-.927v-1.8a.915.915,0,0,1,.927-.927h1.8a.915.915,0,0,1,.927.927Zm6.314,0a.915.915,0,0,1-.927.927h-1.8a.915.915,0,0,1-.927-.927v-1.8a.915.915,0,0,1,.927-.927h1.8a.915.915,0,0,1,.927.927Zm0-6.314a.915.915,0,0,1-.927.927h-1.8a.915.915,0,0,1-.927-.927v-1.8a.915.915,0,0,1,.927-.927h1.8a.915.915,0,0,1,.927.927Zm6.372,0a.915.915,0,0,1-.927.927h-1.8a.915.915,0,0,1-.927-.927v-1.8a.915.915,0,0,1,.927-.927h1.8a.915.915,0,0,1,.927.927Zm1.8-13.612H24.489V4.3a1.8,1.8,0,1,0-3.591,0v.927H12.731V4.3a1.8,1.8,0,0,0-3.591,0v.927H6.822A2.719,2.719,0,0,0,4.1,7.945v.927a.915.915,0,0,0,.927.927H28.6a.915.915,0,0,0,.927-.927V7.945A2.808,2.808,0,0,0,26.748,5.222Z" transform="translate(-4.1 -2.5)" fill="#092055"/>
                                        </svg>
                                    </div>
                                    <div class="col-lg-9 col-sm-6">
                                        <span class="cat">Date et Heure</span>
                                        <a href="javascript:void(0)" class="aColor"> {{event.eventdebut | date: 'dd/MM/yyyy hh:mm'}}</a>
                                    </div>
                                </div>
<!--                                <i class='bx bx-group'></i>-->
                            </li>
                            <li   class="liPoint">
                                <div class="row">
                                    <div class="col-lg-2 col-sm-12">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.664" height="25.451" viewBox="0 0 18.664 25.451">
                                            <path id="locate-svgrepo-com_1_" data-name="locate-svgrepo-com(1)" d="M14.332,1A9.332,9.332,0,0,0,5,10.332c0,8.484,8.484,16.119,9.332,16.119s9.332-7.635,9.332-16.119A9.332,9.332,0,0,0,14.332,1Zm0,12.725a3.393,3.393,0,1,1,3.393-3.393A3.394,3.394,0,0,1,14.332,13.725Z" transform="translate(-5 -1)" fill="#092055"/>
                                        </svg>
                                    </div>
                                    <div class="col-lg-10 col-lg-10">
                                        <span class="cat">Lieu</span>
                                        <a href="javascript:void(0)" class="aColor">{{event.eventlieu}}</a>
                                    </div>
                                </div>
<!--                                <i class='bx bx-calendar'></i>-->
                            </li>
                        </ul>
                    </div>
                </div>
<!--            </div>-->

<!--            <div class="col-lg-6 col-md-12">-->
                <div class="events-details">
                    <div class="events-details-image">
                        <img [src]="event.url" class="imgEvent" alt="image">
                    </div>
                    <div class="events-details-desc">
                        <p>{{event.eventdesclevel1}}
                        </p>
                        <p>{{event.eventdesclevel2}}</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-sm-12">
                <aside class="widget-area widget-area-card eventCard">

                    <div class="widget widget_events_details">
                        <div class="row">
                            <div class="col-6">
                                <h3 class="widget-title" style="color: #092055;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.982" height="12.845" viewBox="0 0 17.982 12.845">
                                        <path id="ticket-svgrepo-com" d="M75.56,204.845H64v-3.853a2.569,2.569,0,0,0,0-5.138V192H75.56v3.211h1.284V192h5.138v3.853a2.569,2.569,0,0,0,0,5.138v3.853H76.845v-3.211H75.56Zm0-8.349v3.853h1.284V196.5Z" transform="translate(-64 -192)" fill="#60ba07"/>
                                    </svg>
                                    Catégories de Ticket</h3>
                            </div>
                            <div class="col-6"> <hr style="margin-left: -20px;"></div>
                        </div>




<!--                        <div class="ticket-container">-->
<!--                            &lt;!&ndash; VIP Ticket &ndash;&gt;-->
<!--                            <div class="ticket">-->
<!--                                <div class="ticket-info">-->
<!--                                    <span class="ticket-type">VIP : 200 Frs</span>-->
<!--                                    <span class="ticket-icon">🎟️</span>-->
<!--                                </div>-->
<!--                                <button class="buy-button">Acheter maintenant</button>-->
<!--                            </div>-->

<!--                            &lt;!&ndash; GRAND PUBLIC Ticket &ndash;&gt;-->
<!--                            <div class="ticket">-->
<!--                                <div class="ticket-info">-->
<!--                                    <span class="ticket-type">GRAND PUBLIC : 100 Frs</span>-->
<!--                                    <span class="ticket-icon">🎟️</span>-->
<!--                                </div>-->
<!--                                <button class="buy-button">Acheter maintenant</button>-->
<!--                            </div>-->
<!--                        </div>-->

                        <ul>
                            <li *ngFor="let cat of event.catickets" style="margin: 5px 0 !important; background: #fff; border: initial; border-radius: 10px;">

                                    <div class="display-ticket row catLib">
                                        <div class="col-md-8 payCat">
                                            <a style="cursor: pointer;color: #fff;" *ngIf="cat.caticketqtenow<cat.caticketqte"
                                                (click)="goToRouting(cat)"
                                               href="javascript:void(0)" class="join-now-btn"> {{cat.caticketlib}} :
                                                {{formatNumber(cat.caticketarif)}} Frs
                                            </a>
                                            <a *ngIf="cat.caticketqtenow>=cat.caticketqte" style="cursor: pointer; color: #fff;"
                                               class="join-now-btn"> {{cat.caticketlib}} :
                                                {{formatNumber(cat.caticketarif)}} Frs
                                           </a>
                                            <svg style="float: right;margin-top: 12px;" xmlns="http://www.w3.org/2000/svg" width="17.982" height="12.845" viewBox="0 0 17.982 12.845">
                                              <path id="ticket-svgrepo-com" d="M75.56,204.845H64v-3.853a2.569,2.569,0,0,0,0-5.138V192H75.56v3.211h1.284V192h5.138v3.853a2.569,2.569,0,0,0,0,5.138v3.853H76.845v-3.211H75.56Zm0-8.349v3.853h1.284V196.5Z" transform="translate(-64 -192)" fill="#092055"/>
                                            </svg>
                                        </div>
                                        <div class="col-md-4 text-end" style="width: 50% !important;">
<!--                                            <button nz-button nzType="primary" *ngIf="cat.caticketqtenow<cat.caticketqte"-->
<!--                                                    (click)="goToRouting(cat)">primary</button>-->

                                            <a  *ngIf="cat.caticketqtenow<cat.caticketqte"
                                                (click)="goToRouting(cat)"
                                                href="javascript:void(0)" class="btn btn-primary" style=" position: relative; top: 15px; color: #fff; background-color:#092055; border: 1px solid #092055;"> Acheter maintenant
                                            </a>
                                            &nbsp;<strong class="join-now-btn"  *ngIf="cat.caticketqtenow>=cat.caticketqte"
                                                          style="    color: red !important;
    width: 85%;
    margin-top: 16px;
    background: #fff;
    height: 38px;
    text-align: center;
    padding: 7px;">GUICHET FERME</strong>
                                        </div>



                                    </div>
                            </li>
                        </ul>
                    </div>


                    <!--
     (click)="goToRouting('/check-event?key='+it.eventcode)"
-->
                    <div class="widget widget_events_details">
                        <div class="row">
                            <div class="col-4">
                                <h3 class="widget-title" style="color: #092055;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.632" height="15.633" viewBox="0 0 15.632 15.633">
                                        <path id="info-square-svgrepo-com" d="M9.816,17.632c-3.685,0-5.527,0-6.672-1.145S2,13.5,2,9.816,2,4.289,3.145,3.145,6.132,2,9.816,2s5.527,0,6.672,1.145,1.145,2.987,1.145,6.672,0,5.527-1.145,6.672S13.5,17.632,9.816,17.632Zm0-3.322a.586.586,0,0,0,.586-.586V9.035a.586.586,0,1,0-1.172,0v4.69A.586.586,0,0,0,9.816,14.311Zm0-8.4a.782.782,0,1,1-.782.782A.782.782,0,0,1,9.816,5.908Z" transform="translate(-2 -2)" fill="#60ba07" fill-rule="evenodd"/>
                                    </svg>
                                    Détails</h3>
                            </div>
                            <div class="col-8"> <hr class="lineHr"></div>
                        </div>

                        <ul>
                            <li style="color:  #092055;line-height: 0;"><span style="color:  #092055;">Debut :</span> {{event.eventdebut | date: 'dd/MM/yyyy hh:mm'}}</li>
<!--                            <li *ngIf="event.eventfin" style="color: #092055;line-height: 0;"><span style="color:  #092055;">Fin :</span> {{event.eventfin | date: 'dd/MM/yyyy hh:mm'}}</li>-->
                            <li *ngIf="event.eventgeo" style="color:  #092055;line-height: 0;"><span style="color:  #092055;">Voir :</span> <a [href]="event.eventgeo"
                                    target="_blank" style="color:  #092055;">geolocation</a></li>

                            <!--
                            <li><span>Events Category:</span> <a routerLink="/events">Design</a></li>
                            -->
                        </ul>
                    </div>
                    <div class="widget widget_events_details"
                        *ngIf="event.eventorg || event.eventinvite || event.eventpart ">
                        <h3 class="widget-title" style="color: #6bff00;">Organisateur</h3>
                        <ul>
                            <li *ngIf="event.eventorg" class="aColor"><span style="color: #dfd1d1 !important">Organisateur :</span> {{event.eventorg}} </li>
                            <li *ngIf="event.eventinvite" class="aColor"><span style="color: #dfd1d1 !important">Invité:</span> {{event.eventinvite}}</li>
                            <li *ngIf="event.eventpart" class="aColor"><span style="color:#dfd1d1 !important">Partenaire:</span> {{event.eventpart}}</li>
                        </ul>
                    </div>

                    <!--
                          <div class="widget widget_events_details">
                        <h3 class="widget-title">Venue</h3>
                        <ul>
                            <li><a href="#" target="_blank">27 Division St, New York, NY 10002, USA</a></li>
                            <li><a href="#" target="_blank">+ Google Map</a></li>
                        </ul>
                    </div>
                    -->


<!--                    <div class="widget widget_instagram">-->
<!--                        <h3 class="widget-title"></h3>-->
<!--                        <ul>-->
<!--                            <li *ngFor="let url of event.urls"><a href="#" target="_blank" class="d-block"><img-->
<!--                                        [src]="url" style="height: auto; width: 100%;" style="border-radius: 10px;" alt="image"></a></li>-->


<!--                        </ul>-->
<!--                    </div>-->




                <div *ngIf="isConnect">

                    <div *ngIf="loadingCommentaireCheck" class="d-flex justify-content-center">
                        <div class="spinner-border" style="font-size: 30px;
                            height: 30px;
                            width: 30px;
                            color: red;" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>


                    <div *ngIf="!loadingCommentaireCheck" class="comments-area">
                        <div class="spaced-dotted-line"></div>
                        <div class="comment-respond">
                            <h3 class="comment-reply-title aColor" style="font-family: Montserrat;font-size: 20px">Des questions sur l'évènement ?</h3>
                            <br>
                            <form class="comment-form" [formGroup]="comentaireForm">
                                <p class="comment-form-comment">
                                    <label>Commentaire</label>
                                    <textarea [formControl]="comentaireForm.controls['message']" name="message"
                                              id="message" cols="45" placeholder="Votre commentaire..." rows="5"
                                              maxlength="220" required="required"></textarea>
                                </p>
<!--                                <p class="form-submit">-->
<!--                                    <input type="submit" [disabled]="!comentaireForm.valid" class="submit"-->
<!--                                           value="Envoyer">-->
<!--                                </p>-->
                                <button class="bntComm" (click)="saveCommentaire()" nz-button nzType="primary">Soumettre</button>

                            </form>
                        </div>
                    </div>

                </div>

                </aside>
            </div>
        </div>
    </div>
    <br>
</div>

<!--Les lignes de commentaires-->
<section class="mission-area" style=" background-color: #000; display: none">
    <br><br>
   <div class="line" style="width: 38%;
    margin-left: auto;
    margin-right: auto;display: none"></div>

    <div class="container">
    <div class="comments-section">
        <h2 >COMMENTAIRES ET AVIS</h2>

        <div *ngIf="!loadingCommentaireCheck" class="comments-area">
            <h3  class="comments-title aColor"> {{commentaires.length}} Commentaire(s):</h3>
            <ol *ngIf="commentaires.length>0" class="comment-list">

                <li *ngFor="let item of commentaires" class="comment" style="border-bottom: none !important;">
                    <article class="comment-body">
                        <footer class="comment-meta">
                            <div class="comment-author vcard">
                                <b class="fn aColor">MOI</b>
                                <span class="says">says:</span>
                            </div>
                            <div class="comment-metadata">
                                <a>
                                    <time>
                                        {{item.sender.date | date: 'dd/MM/yyyy' }}
                                    </time>
                                </a>
                            </div>
                        </footer>
                        <div class="comment-content">
                            <p>{{item.sender.message}}</p>
                        </div>
                        <ol *ngIf="item.sender.hasresponse" class="children">
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <b class="fn">REPONSE </b>
                                            <span class="says"></span>
                                        </div>

                                    </footer>

                                    <div class="comment-content">
                                        <p>

                                            {{item.sender.reponse}}

                                        </p>
                                    </div>

                                </article>
                            </li>
                        </ol>

                    </article>

                </li>
            </ol>
        </div>


        <div class="comment " *ngIf="commentaires.length==0">
            <p style="text-align: center">Aucun commentaire pour ce évènement.</p>
            <br><br><br>
        </div>
<!--        <div class="comment">-->
<!--            <img src="{{img}}" alt="Profil">-->
<!--            <p>In ad minim veniam, quis nostrud exercitation ullamco... (texte de commentaire)</p>-->
<!--        </div>-->

    </div>
    </div>
    <div class="container" style="width: 38% !important;" *ngIf="commentaires.length>0">
        <br><br><br>
        <div class="line"></div>
        <div class="button">
            <span>Voir tous les avis</span>
        </div>
        <div class="line"></div>

        <br><br><br>
    </div>
</section>
