import {Component, OnInit} from '@angular/core';
import {dataMenu, imgProfil} from "../../../core/config-var";
import {Spectateur} from "../spectateur";
import {environment} from "../../../../../environments/environment";
import {ActivatedRoute, Params, Route, Router} from "@angular/router";

declare let $: any;

@Component({
    selector: 'app-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
    dMenu: any = [];
    dataUsers: any = [];
    imgP: string = imgProfil;
    mont: number;
    key: any;
    dataNav: any = [
        {
            uri: "/achat",
            label: "Mes achats",
        }, {
            uri: "/ticket",
            label: "Mes tickets",
        }, {
            uri: "/receive",
            label: "Tickets reçu",
        }, {
            uri: "/foward",
            label: "Tickets transférés",
        }, {
            uri: "/carte",
            label: "Mes cartes",
        }, {
            uri: "/notification",
            label: "Notifications",
        }, {
            uri: "/infos",
            label: "Info personnelle",
        }
    ];
    constructor(private _activatedRoute: ActivatedRoute,private route:Router) {
    }

    selectNav(i: number) {
        for (let j = 0; j < this.dMenu.length; j++) {
            $('#nav' + j).removeClass("active");
        }
        $('#nav' + i).addClass('active');
    }


    ngOnInit(): void {
        let u = new Spectateur();
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        this.dataUsers = u;
        //  window.location.reload();
        this.selectNav(1);
        this._activatedRoute.queryParams.forEach((params: Params) => {
            if (params['key'] && params['mont']) {
                this.key = params['key'];
                this.mont = parseInt(params['mont']);
            }
        });

        console.log(this.dataUsers)
    }


    _lettre(spectateurnom: string) {
        return spectateurnom?.substring(0, 1).toUpperCase();
    }

    _split(caticket: string) {

        let d = caticket.split(" ");
        let r: string = "";
        if (d.length > 1) {
            r = d[0].substring(0, 1) + "" + d[1].substring(0, 1)
        } else {
            r = caticket;
        }
        return r;
    }

    mesTicket() {
        this.route.navigateByUrl('/ticket')
    }

    separe(s: string | null) {
        return s.toString().replace(/,/g, ' ')
    }
}
