import {DatePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {EventwithoutkeyService} from '../home-billeterie/eventwithoutkey.service';
import {environment} from "../../../../../environments/environment";
import {dataContry} from "../../../core/config-var";
import Swal from 'sweetalert2'

@Component({
    selector: 'app-inscrire-billeterie',
    templateUrl: './inscrire-billeterie.component.html',
    styleUrls: ['./inscrire-billeterie.component.scss']
})
export class InscrireBilleterieComponent implements OnInit {

    public registerForm;
    public codeForm;
    checking: boolean = false;
    checkingMessage: string = "";

    checkingNom: boolean = false;
    checkingNomMessage: string = "";

    checkingPays: boolean = false;
    checkingPaysMessage: string = "";

    checkingCodeMessage: string = "";
    checkingCode: boolean = false;

    checkingPrenom: boolean = false;
    checkingPrenomMessage: string = "";


    checkingTel: boolean = false;
    checkingTelMessage: string = "";

    checkingEmail: boolean = false;
    checkingEmailMessage: string = "";


    checkingPsswd: boolean = false;
    checkingPsswdMessage: string = "";
    confcheckingPsswd: boolean = false;
    confcheckingPsswdMessage: string = "";

    checkingDate: boolean = false;
    checkingDateMessage: string = "";


    checkingSex: boolean = false;
    checkingSexMessage: string = "";

    myDatepipe!: any;

    senderSms: number = 0;
    dataContry: any = []; //dataContry;

    dataRegister: any = [];
    isLoad: boolean = false;


    COUNTRIES = [
        {name: 'United States', code: 'us'},
        {name: 'France', code: 'fr'},
        {name: 'Germany', code: 'de'},
        {name: 'Spain', code: 'es'},
        {name: 'Italy', code: 'it'},
        // Ajoutez d'autres pays selon vos besoins
    ];
    selectedCountry = this.COUNTRIES[0];
    current: number = 0;
    currentIcon: string = 'ci';
    currentInd: string = '225';
    isCheckedButton = false;
    isDisabledButton = false;
    isVisible = false;

    constructor(public fb: UntypedFormBuilder, private router: Router,
                private gestserv: EventwithoutkeyService, private toastr: ToastrService,
                private logger: NGXLogger, datepipe: DatePipe
    ) {
        this.myDatepipe = datepipe;
    }

    ngOnInit(): void {
        localStorage.clear();
        this.registerForm = this.fb.group({
            'spectateurnom': [null, ],
            'spectateurprenom': [null, ],
            'spectateuremail': [null],
            'spectateurtel': [null, Validators.required],
            'spectateurpsswd': [null, Validators.required],
            'confspectateurpsswd': [null, Validators.required],
            'spectateurdatenais': [null, ],
            'spectateursexe': [null, ],
            'indicatifPays': [null, Validators.required],
            'isCondition': [false, Validators.required],
        });
        this.codeForm = this.fb.group({
            'code': [null, Validators.required],
        });
        this.registerForm.get('spectateurtel').valueChanges.subscribe(data => {
            this.phoneNumberLength(data);
        });
        this.loadPays();
    }
    registerSendPhone() {

        let spectateurnom = this.registerForm.get('spectateurnom').value;
        let spectateurprenom = this.registerForm.get('spectateurprenom').value;
        let spectateuremail = this.registerForm.get('spectateuremail').value;
        let spectateurtel = this.registerForm.get('spectateurtel').value;
        let spectateurpsswd = this.registerForm.get('spectateurpsswd').value;
        let confspectateurpsswd = this.registerForm.get('confspectateurpsswd').value;
        let spectateurdatenais = this.registerForm.get('spectateurdatenais').value;
        let spectateursexe = this.registerForm.get('spectateursexe').value;
        let indicatifPays = this.registerForm.get('indicatifPays').value;

       let payload:any={
           "indicatifPays": indicatifPays,
           "numeroTelUnique": spectateurtel,
           "spectateurConfirmPsswd": "",
           "spectateurcode": "",
           "spectateurdatenais": "",
           "spectateuremail": "",
           "spectateurnom": "",
           "spectateurpaysindicatif": "string",
           "spectateurpaysname": this.dataContry.find((d) => d.indicatif == indicatifPays)?.country,
           "spectateurprenom": "",
           "spectateurpsswd": "",
           "spectateursexe": "",
           "spectateurtel": indicatifPays + "" + spectateurtel.trim(),
           "token": "string"
       }

        this.isLoad = true;
        this.gestserv.post(payload, "billeterie/:session/:addSpect").subscribe(data => {
                this.isLoad = false;
                this.dataRegister = data.body;
                switch (data.status) {
                    case 201: {
                        this.current += 1;
                        break;
                    }
                }
            },
            error => {
                //this.loader.close();
                this.isLoad = false;
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    text: `${error.error.err || 'Une erreur est survenue, ressayer svp.'}`,
                });
                // switch (error.status) {
                //     case 423: {
                //         this.isLoad = false;
                //         this.checkingMessage = "Le numéro de téléphone est déjà utilisé.";
                //         this.toastr.error('Echec!', "Le numéro de téléphone est déjà utilisé.");
                //         this.checking = true;
                //         break;
                //     }
                //     default:{
                //         console.log(error)
                //         this.toastr.error('Echec!', error?.error);
                //         }
                // }


            });


    }

    checkOTP() {
        if(!this.isCheckedButton){
            Swal.fire({
                icon: "info",
                title: "Conditions d'utilisation",
                text: "Pour créer votre compte, veuillez lire et accepter les conditions d'utilisation.",
            });
            return
        }
        let spectateurnom = this.registerForm.get('spectateurnom').value;
        let spectateurprenom = this.registerForm.get('spectateurprenom').value;
        let spectateurtel = this.registerForm.get('spectateurtel').value;
        let confspectateurpsswd = this.registerForm.get('confspectateurpsswd').value;
        let spectateurdatenais = this.registerForm.get('spectateurdatenais').value;
        let indicatifPays = this.registerForm.get('indicatifPays').value;


       let payload:any={
           "indicatifPays": indicatifPays,
           "numeroTelUnique": spectateurtel,
           "spectateurConfirmPsswd": "",
           "spectateurcode": "",
           "spectateurdatenais": "",
           "spectateuremail": "",
           "spectateurnom": "",
           "spectateurpaysindicatif": "string",
           "spectateurpaysname": this.dataContry.find((d) => d.indicatif == indicatifPays)?.country,
           "spectateurprenom": "",
           "spectateurpsswd": "",
           "spectateursexe": "",
           "spectateurtel": indicatifPays + "" + spectateurtel.trim(),
           "token": "string"
       }

this.isLoad=true;

        this.gestserv.get(`billeterie/:session/:validationspectateurbycode/:${this.codeForm.get('code').value}/:${indicatifPays + "" + spectateurtel.trim()}`).subscribe(data => {
                this.isLoad = false;
                this.dataRegister = data.body;
                console.log("data.body ======",data.body)
                switch (data.status) {
                    case 201: {
                        this.current += 1;
                        break;
                    }
                    case 200: {
                        this.current += 1;
                        break;
                    }
                }
            },
            error => {
                //this.loader.close();
                console.log(error)
                switch (error.status) {
                    case 423: {
                        this.isLoad = false;
                        this.checkingMessage = "Le code n'est pas correct.";
                        this.toastr.error('Echec!', "Le code n'est pas correct.");
                        this.checking = true;
                        break;
                    }
                    default:{

                        this.toastr.error('Echec!', "Le code n'est pas correct.");
                        }
                }


            });


    }
    register() {
        this.checking = false;
        this.checkingMessage = "";
        this.logger.trace(this.registerForm);

        this.checkingMessage = "";
        this.checking = false;

        this.checkingNomMessage = "";
        this.checkingNom = false;

        this.checkingPrenomMessage = "";
        this.checkingPrenom = false;

        this.checkingEmailMessage = "";
        this.checkingEmail = false;

        this.checkingTelMessage = "";
        this.checkingTel = false;

        this.checkingPsswdMessage = "";
        this.checkingPsswd = false;

        this.checkingDateMessage = "";
        this.checkingDate = false;

        this.checkingSexMessage = "";
        this.checkingSex = false;
        console.log("this.registerForm ===",this.registerForm.value)
        // if (!this.registerForm.valid) {
        //     this.checkingMessage = "Veuillez renseigner tous les champs obligatoire";
        //     this.toastr.error('Echec!', "Veuillez renseigner tous les champs obligatoire");
        //
        //     this.checking = true;
        //     return;
        // }

        let spectateurnom = this.registerForm.get('spectateurnom').value;
        let spectateurprenom = this.registerForm.get('spectateurprenom').value;
        let spectateuremail = this.registerForm.get('spectateuremail').value;
        let spectateurtel = this.registerForm.get('spectateurtel').value;
        let spectateurpsswd = this.registerForm.get('spectateurpsswd').value;
        let confspectateurpsswd = this.registerForm.get('confspectateurpsswd').value;
        let spectateurdatenais = this.registerForm.get('spectateurdatenais').value;
        let spectateursexe = this.registerForm.get('spectateursexe').value;
        let indicatifPays = this.registerForm.get('indicatifPays').value;

        // if (!spectateurnom || spectateurnom.trim() === "") {
        //     this.checkingNomMessage = "Veuillez renseigner un nom";
        //     this.toastr.error('Echec!', 'Veuillez renseigner un nom');
        //
        //     this.checkingNom = true;
        //     return;
        // }
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];

        // if (parseInt(formattedDate.replace(new RegExp('-', 'g'), "")) <= parseInt(spectateurdatenais.replace(new RegExp('-', 'g'), ""))) {
        //     this.toastr.error('Echec!', "Veuillez revoir votre date de naissance svp");
        //     return;
        // }

        spectateurnom = spectateurnom.trim();

        // if (!spectateurprenom || spectateurprenom.trim() === "") {
        //     this.checkingPrenomMessage = "Veuillez renseigner un prenom";
        //     this.toastr.error('Echec!', "Veuillez renseigner un prenom");
        //
        //     this.checkingPrenom = true;
        //     return;
        // }
        spectateurprenom = spectateurprenom.trim();


        if (spectateuremail) {
            if (!this.validateEmail(spectateuremail)) {
                //  this.checkingEmailMessage = "Veuillez renseigner un email correct";
                // this.toastr.error('Echec!', "Veuillez renseigner un email correct");
                // this.checkingEmail = true;
                // return;
            } else {
                spectateuremail = spectateuremail.trim();
            }
        }


        if (!spectateurtel || spectateurtel.trim() === "") {
            this.checkingTelMessage = "Veuillez renseigner un telephone";
            this.toastr.error('Echec!', "Veuillez renseigner un telephone");

            this.checkingTel = true;
            return;
        }
        spectateurtel = indicatifPays + "" + spectateurtel.trim();


        if (!spectateurpsswd || spectateurpsswd.trim() === "") {
            this.checkingPsswdMessage = "Veuillez renseigner un mot de passe";
            this.toastr.error('Echec!', "Veuillez renseigner un mot de passe");

            this.checkingPsswd = true;
            return;
        }
        if (spectateurpsswd.trim().length < 5) {
            this.checkingPsswdMessage = "Votre mot de passe doit être composé de 5 chiffres";
            this.toastr.error('Echec!', "Votre mot de passe doit être composé de 5 chiffres");

            this.checkingPsswd = true;
            return;
        }
        if (spectateurpsswd != confspectateurpsswd) {
            this.confcheckingPsswdMessage = "Les deux mots de passe ne correspondent pas";
            this.toastr.error('Echec!', "Les deux mots de passe ne correspondent pas");

            this.confcheckingPsswd = true;
            return;
        }
        spectateurpsswd = spectateurpsswd.trim();

        // if (!spectateurdatenais) {
        //     this.checkingDateMessage = "Veuillez renseigner votre date de naissance";
        //     this.toastr.error('Echec!', "Veuillez renseigner votre date de naissance");
        //
        //     this.checkingDate = true;
        //     return;
        // }
        spectateurdatenais = spectateurdatenais.trim();

        // if (!spectateursexe || spectateursexe.trim() === "") {
        //     this.checkingSexMessage = "Veuillez choisir votre sexe";
        //     this.toastr.error('Echec!', "Veuillez choisir votre sexe");
        //
        //     this.checkingSex = true;
        //     return;
        // }
        spectateursexe = spectateursexe.trim();

        let spectateur: any = {};
        spectateur.spectateurnom = spectateurnom;
        spectateur.spectateurprenom = spectateurprenom;
        spectateur.spectateuremail = spectateuremail;
        spectateur.spectateurtel = spectateurtel;
        spectateur.spectateurTel = spectateurtel;
        spectateur.spectateurpsswd = spectateurpsswd;
        spectateur.password = spectateurpsswd;
        spectateur.indicatifPays = indicatifPays;
        spectateur.token = "";
        spectateur.oldpassword = "";
        spectateur.spectateurConfirmPsswd = "";
        spectateur.spectateurcode = "CC";
        spectateur.spectateurpaysname = this.dataContry.find((d) => d.indicatif == indicatifPays)?.country;

        spectateur.spectateurdatenais = this.myDatepipe.transform(spectateurdatenais, "dd-MM-yyyy");
        spectateur.spectateursexe = spectateursexe;
        spectateur.numeroTelUnique = this.registerForm.get('spectateurtel').value;

        // {
        //     "oldpassword": "string",
        //     "password": "string",
        //     "spectateurTel": "string"
        // }
        //this.logger.trace(this.myDatepipe.transform(spectateurdatenais, "dd-MM-yyyy"));

        this.logger.trace(spectateur);
        // let payload = {
        //     "to": "225" + spectateur.spectateurtel,
        //     "contente": "Votre code de validation est : " + this.generateAlphaNumericCode(5)
        // }
        this.isLoad = true;

        this.gestserv.post(spectateur, " billeterie/:session/:addPassWordSpect").subscribe(data => {
        // this.gestserv.post(spectateur, "billeterie/:session/:addSpect").subscribe(data => {
                this.isLoad = false;
                this.dataRegister = data.body;
                switch (data.status) {
                    case 201: {
                        this.senderSms = 1;
                        this.current += 1;
                        this.logger.trace('Retour login');
                        this.logger.trace(data.body);
                        // this.router.navigateByUrl('/connecter?key=' + spectateurtel);
                        break;
                    }
                }
            },
            error => {
                //this.loader.close();
                switch (error.status) {
                    case 423: {
                        this.isLoad = false;
                        this.logger.error('ERROR', error);
                        this.checkingMessage = "Le numéro de téléphone est déjà utilisé.";
                        this.toastr.error('Echec!', "Le numéro de téléphone est déjà utilisé.");
                        this.checking = true;
                        break;
                    }
                }


            });


    }

    verifCode() {
        this.checking = false;
        this.checkingMessage = "";
        this.logger.trace(this.registerForm);
        let codeValide = this.codeForm.get('code').value;
        if (!codeValide || codeValide.trim() === "") {
            this.checkingCodeMessage = "Veuillez renseigner le code de validation";
            this.toastr.error('Echec!', 'Veuillez renseigner le code');
            this.checkingCode = true;
            return;
        }
        this.isLoad = true;
        this.checking = false;
        this.checkingMessage = "";
        this.logger.trace(this.registerForm);

        let spectateurnom = this.registerForm.get('spectateurnom').value;
        let spectateurprenom = this.registerForm.get('spectateurprenom').value;
        let spectateuremail = this.registerForm.get('spectateuremail').value;
        let spectateurtel = this.registerForm.get('spectateurtel').value;
        let spectateurpsswd = this.registerForm.get('spectateurpsswd').value;
        let confspectateurpsswd = this.registerForm.get('confspectateurpsswd').value;
        let spectateurdatenais = this.registerForm.get('spectateurdatenais').value;
        let spectateursexe = this.registerForm.get('spectateursexe').value;
        let indicatifPays = this.registerForm.get('indicatifPays').value;
        spectateurnom = spectateurnom?.trim();
        spectateurprenom = spectateurprenom?.trim();

        if (!spectateurtel || spectateurtel?.trim() === "") {
            this.checkingTelMessage = "Veuillez renseigner un telephone";
            this.toastr.error('Echec!', "Veuillez renseigner un telephone");

            this.checkingTel = true;
            return;
        }
        spectateurtel = indicatifPays + "" + spectateurtel?.trim();

        if (!spectateurpsswd || spectateurpsswd?.trim() === "") {
            this.checkingPsswdMessage = "Veuillez renseigner un mot de passe";
            this.toastr.error('Echec!', "Veuillez renseigner un mot de passe");

            this.checkingPsswd = true;
            return;
        }
        if (spectateurpsswd.trim().length < 5) {
            this.checkingPsswdMessage = "Votre mot de passe doit être composé de 5 chiffres";
            this.toastr.error('Echec!', "Votre mot de passe doit être composé de 5 chiffres");

            this.checkingPsswd = true;
            return;
        }
        if (spectateurpsswd != confspectateurpsswd) {
            this.confcheckingPsswdMessage = "Les deux mots de passe ne correspondent pas";
            this.toastr.error('Echec!', "Les deux mots de passe ne correspondent pas");

            this.confcheckingPsswd = true;
            return;
        }
        spectateurpsswd = spectateurpsswd?.trim();
        spectateurdatenais = spectateurdatenais?.trim();

        spectateursexe = spectateursexe?.trim();

        let spectateur: any = {};
        spectateur.spectateurnom = spectateurnom;
        spectateur.spectateurprenom = spectateurprenom;
        spectateur.spectateuremail = spectateuremail;
        spectateur.spectateurtel = spectateurtel;
        spectateur.spectateurTel = spectateurtel;
        spectateur.spectateurpsswd = spectateurpsswd;
        spectateur.password = spectateurpsswd;
        spectateur.indicatifPays = indicatifPays;
        spectateur.token = "";
        spectateur.oldpassword = "";
        spectateur.spectateurConfirmPsswd = "";
        spectateur.spectateurcode = "CC";
        spectateur.spectateurpaysname = this.dataContry.find((d) => d.indicatif == indicatifPays)?.country;

        spectateur.spectateurdatenais = this.myDatepipe.transform(spectateurdatenais, "dd-MM-yyyy");
        spectateur.spectateursexe = spectateursexe;
        spectateur.numeroTelUnique = this.registerForm.get('spectateurtel').value;
        this.isLoad = true;

        this.gestserv.post(spectateur, "billeterie/:session/:addPassWordSpect").subscribe(data => {
        // this.gestserv.get(`billeterie/:session/:validationspectateurbycode/:${codeValide}/:${this.dataRegister.spectateurtel}`).subscribe(data => {
                // this.gestserv.post(spectateur, "/billeterie/session/add").subscribe(data => {
                this.logger.trace('INFOS CNX', data);
                this.isLoad = false;
                switch (data.status) {
                    case 200: {
                        //this.router.navigateByUrl('/connecter?key=' + spectateurtel);
                        this.senderSms = 2;
                        this.current += 1;

                        break;
                    }
                    case 201: {
                        //this.router.navigateByUrl('/connecter?key=' + spectateurtel);
                        this.senderSms = 2;
                        this.current += 1;

                        break;
                    }
                }
            },
            error => {
                //this.loader.close();
                this.isLoad = false;
                this.logger.error('ERROR', error);
                switch (error.status) {

                    case 423: {

                        this.checking = true;
                        break;
                    }

                    case 412: {

                        this.checking = true;
                        break;
                    }

                    default: {
                        this.checkingMessage = "Erreur survenue veuillez ressayer";
                        this.toastr.error('Echec!', 'Erreur survenue veuillez ressayer');

                        this.checking = true;
                        break;
                    }
                }
            });


    }


    validateEmail(value) {
        var input = document.createElement('input');

        input.type = 'email';
        input.required = true;
        input.value = value;

        return typeof input.checkValidity === 'function' ? input.checkValidity() : /\S+@\S+\.\S+/.test(value);
    }


    phoneNumberLength(data) {
        let regexpNumber = new RegExp("^[+0-9]{0,18}$");
        let keyValue = data || '';
        if (!regexpNumber.test(keyValue)) {
            // set the new value to the formControl
            this.registerForm.patchValue({
                spectateurtel: keyValue.slice(0, keyValue.length - 1)
            }, {
                emitEvent: false // it will make sure the valuechange won't trigger when applying new value to formcontrol.(line number 30 won't execute)
            });
            // this.keyValue = this.keyValue.slice(0, this.keyValue.length - 1);
            console.log(keyValue);
        }
    }

    generateAlphaNumericCode(length) {
        const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            result += charset.charAt(randomIndex);
        }

        return result;
    }

    onSendSMS(payload) {

        // this.http.post( "/api",payload).subscribe(data => {
        this.gestserv.postSend(payload, "/api/sendSms").subscribe(data => {
                // this.authService.postSender(payload, "/send").subscribe(data => {
                this.logger.trace('INFOS :::', data);

            },
            error => {
                this.logger.error('ERROR', error);

            });

    }

    connect() {
        this.router.navigateByUrl('/connecter');
    }

    changeInd(event: any) {
        this.currentIcon = "ci";// this.dataContry.find((d) => d.indicatif == event)?.code
    }

    pre(): void {
        this.current -= 1;
    }

    next(): void {
        if (this.current == 0) {
            let v: boolean = false;
            // {
            //     "": null,
            //     "spectateurprenom": null,
            //     "spectateuremail": "0505656661",
            //     "spectateurtel": null,
            //     "spectateurpsswd": "admin@0101",
            //     "spectateurdatenais": null,
            //     "spectateursexe": null,
            //     "indicatifPays": "225",
            //     "isCondition": false
            // }
            this.checkingPays = false;
            this.checkingTel = false;
            this.checkingNom = false;
            this.checkingPrenom = false;
            if (!this.registerForm.value.indicatifPays) {
                this.checkingPays = true;
                this.checkingPaysMessage = "Veuillez renseigner votre pays";
                v = true;
            }
            if (!this.registerForm.value.spectateurtel) {
                this.checkingTel = true;
                this.checkingTelMessage = "Veuillez renseigner le numéro de téléphone";
                v = true;
            }
            // if (!this.registerForm.value.spectateurnom) {
            //     this.checkingNom = true;
            //     this.checkingNomMessage = "Veuillez renseigner le nom";
            //     v = true;
            // }
            // if (!this.registerForm.value.spectateurprenom) {
            //     this.checkingPrenom = true;
            //     this.checkingPrenomMessage = "Veuillez renseigner le prenom";
            //     v = true;
            // }
            // indicatifPays
            // spectateurnom
            // spectateurprenom
            // spectateurtel
            console.log(this.registerForm.value)

this.registerSendPhone();
            if (v) return;
        }

       // this.current += 1;
    }

    done(): void {
        console.log('done');
    }

    updateAllChecked(e) {
        console.log(e)
    }

    openNewTab(url) {
        this.isVisible = true;
        // window.open(url, '_blank');
    }

    handleCancel() {
        this.isVisible = false;
    }

    loadPays() {

        this.gestserv.get(`/billeterie/session/getAllListPays`).subscribe(data => {

                switch (data.status) {
                    case 200: {

                        if (data.body.length) {

                            this.dataContry = data.body.map((d) => {
                                return {
                                    ...d,
                                    country: d.paysNom,
                                    indicatif: d.paysIndicatif,
                                    code: d.paysCode,
                                }
                            });
                        }

                        break;
                    }
                    default : {


                    }
                }
            },
            error => {

                switch (error.status) {
                    case 401: {
                        //this.router.navigate(['/authentication/signin']);

                        break;
                    }
                    default: {

                        this.logger.trace(error);
                        // $('#backConcert').css({
                        //     'background-image': 'url(' + this.imgDefault + ')',
                        //     'background-repeat': 'no-repeat',
                        //     'background-size': 'cover'
                        // });
                        break;
                    }
                }
            });
    }
}

function moment() {
    throw new Error('Function not implemented.');
}

