<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" id="myImgAchat" style="padding-top: 0;height: 350px !important;z-index: 1;position: relative;">
    <section class="mission-area" style="background: rgba(0, 0, 0, 0.5) !important;height: 350px !important;">
        <app-navbar></app-navbar>
        <div class="container">

            <div class="row">
<!--                <div class="col-12">-->

<!--                    <strong class="enventInfo">-->
<!--                        {{dataCheckEvent?.eventitre}}-->
<!--                    </strong>-->
<!--                </div>-->
<!--                <div class="col-12">-->
<!--                    <br>-->
<!--                    <p class="sTitle">{{dataCheckEvent?.eventdesclevel1}}</p>-->
<!--                </div>-->

                <div class="col-12">
<!--                    <p class="enventInfo">CHOIX DU NOMBRE DE TICKET PAR TYPE DE TICKET</p>-->
                </div>
            </div>
        </div>
    </section>
</section>
<!--height: 100%;-->
<div class="events-details-area" style="background-color: #006837;">


<div class="container" style="margin-top: -140px;z-index: 9;position: relative;">

<!--            <div class="row">-->
<!--                <div class="col-12">-->
<!--                    <strong class="enventInfo">-->
<!--                        {{dataCheckEvent?.eventitre}}-->
<!--                    </strong>-->
<!--                </div>-->
<!--                <div class="col-12">-->
<!--                    <p class="sTitle">{{dataCheckEvent?.eventdesclevel1}}</p>-->
<!--                </div>-->
<!--            </div>-->

            <div class="product-details-area pb-70">
                <div class="container">
                    <p class="labelText">CHOIX DU NOMBRE DE TICKET PAR TYPE DE TICKET</p>
                    <div class="row align-items-center" style="margin-top: 20px">
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <h5 class="textTotext">CARTE NBH VALIDE POUR CE TICKET</h5>
                            <div class="tickets-section">
                                <div class="ticket-grid">
                                    <div class="ticket">
                                        <img src="assets/img/carte/babatche.jpeg" alt="Carte Babatchê">
                                    </div>
                                    <div class="ticket">
                                        <img src="assets/img/carte/danhere.jpeg" alt="Carte Babatchê Gold">
                                    </div>

<!--                                       <div class="ticket"  *ngFor="let ticket of dataCarte">-->
<!--                                        <img [src]="viewImg(ticket)" alt="{{ticket.catcartelib}}">-->
<!--                                        <p>{{ticket.catcartelib}}</p>-->
<!--                                       </div>-->
<!--                                    <button nz-button nzType="default" style="margin-left: 50%">Voir plus...</button>-->

                                </div>
                            </div>

                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-12">
                            <div class="products-details-tab" style="background-color: #fff; box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;border-radius: 20px;">
                                <ngx-tabset  (onSelect)="doSomethingOnTabSelect($event)">
                                    <ngx-tab [disabled]="activeFrist" [active]="activeFrist" tabTitle="Nombre de ticket">
                                        <b style="text-align: center;color: #fff !important;display: block;">
                                            <i class='bx bx-check-circle icon-arrow before'></i> VEUILLEZ RENSEIGNER LE NOMBRE DE TICKET
                                        </b>
                                        <div class="row align-items">
                                            <div class="col-12">
                                                <div class="product-details-desc" style="background-color: #092055 !important;padding: 10px;border-radius: 12px; margin-top: 20px">

                                                    <div class="row">
                                                        <div class="col-lg-6 col-sm-12">
                                                            <div class="icon-section">
                                                                <div class="icon-box" style="margin-right: 20%;">
                                                                    <div class="icon">
                                                                        <svg  style="text-align: center;width: 100%;" xmlns="http://www.w3.org/2000/svg" width="54.254" height="43.403" viewBox="0 0 54.254 43.403">
                                                                            <g id="card-svgrepo-com" transform="translate(-2 -4)">
                                                                                <path id="Tracé_20" data-name="Tracé 20" d="M34.508,4H23.658C13.427,4,8.312,4,5.134,7.178,2.846,9.466,2.205,12.759,2.026,18.242H56.14c-.179-5.483-.82-8.775-3.108-11.064C49.854,4,44.738,4,34.508,4Z" transform="translate(0.044)" fill="#fff"/>
                                                                                <path id="Tracé_21" data-name="Tracé 21" d="M23.7,35.842H34.552c10.23,0,15.345,0,18.523-3.178s3.178-8.293,3.178-18.523q0-1.8-.005-3.391H2.005Q2,12.345,2,14.141c0,10.23,0,15.345,3.178,18.523S13.471,35.842,23.7,35.842Z" transform="translate(0 11.561)" fill="#fff"/>
                                                                                <path id="Tracé_22" data-name="Tracé 22" d="M5.25,17.285A2.035,2.035,0,0,1,7.285,15.25H18.135a2.035,2.035,0,1,1,0,4.069H7.285A2.035,2.035,0,0,1,5.25,17.285Z" transform="translate(5.566 19.268)" fill="#092055" fill-rule="evenodd"/>
                                                                                <path id="Tracé_23" data-name="Tracé 23" d="M11.75,17.285a2.035,2.035,0,0,1,2.035-2.035h4.069a2.035,2.035,0,1,1,0,4.069H13.785A2.035,2.035,0,0,1,11.75,17.285Z" transform="translate(16.699 19.268)" fill="#092055" fill-rule="evenodd"/>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                    <div class="arrows">

                                                                        <svg (click)="down('c')" class="arrow" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                                            <g id="Groupe_49" data-name="Groupe 49" transform="translate(-1332 -546)">
                                                                                <rect id="Rectangle_40" data-name="Rectangle 40" width="18" height="18" transform="translate(1332 546)" fill="#fff"/>
                                                                                <path id="Tracé_26" data-name="Tracé 26" d="M4239.46,1098.224l-4.553,4.553,4.553,4.553" transform="translate(-2897 -547.777)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                            </g>
                                                                        </svg>
                                                                        <span class="number">{{ticketCarte || '0'}}</span>
                                                                        <svg  (click)="up('c')" class="arrow" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                                            <g id="Groupe_50" data-name="Groupe 50" transform="translate(-1332 -546)">
                                                                                <rect id="Rectangle_40" data-name="Rectangle 40" width="18" height="18" transform="translate(1332 546)" fill="#fff"/>
                                                                                <path id="Tracé_26" data-name="Tracé 26" d="M4234.907,1107.313l4.544-4.544-4.544-4.544" transform="translate(-2895.381 -547.786)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>

                                                                <div class="icon-box">
                                                                    <div class="icon">
                                                                        <svg  id="qr-code-svgrepo-com_1_" data-name="qr-code-svgrepo-com(1)" xmlns="http://www.w3.org/2000/svg" width="56.857" height="56.857" viewBox="0 0 56.857 56.857">
                                                                            <path id="Tracé_24" data-name="Tracé 24" d="M0,0H56.857V56.857H0Z" fill="none"/>
                                                                            <path id="Tracé_25" data-name="Tracé 25" d="M33.8,36.167V33.8H26.691V26.691H33.8v4.738h4.738v4.738H36.167V40.9H31.429v4.738H26.691V38.536h4.738V36.167Zm11.845,9.476H36.167V40.9H40.9V36.167h4.738ZM3,3H21.952V21.952H3ZM7.738,7.738v9.476h9.476V7.738ZM26.691,3H45.643V21.952H26.691Zm4.738,4.738v9.476H40.9V7.738ZM3,26.691H21.952V45.643H3Zm4.738,4.738V40.9h9.476V31.429Zm30.8-4.738h7.107v4.738H38.536ZM10.107,10.107h4.738v4.738H10.107Zm0,23.691h4.738v4.738H10.107ZM33.8,10.107h4.738v4.738H33.8Z" transform="translate(4.107 4.107)" fill="#fff"/>
                                                                        </svg>
                                                                    </div> <!-- Use FontAwesome or image for the correct icon -->
                                                                    <div class="arrows">

                                                                        <svg (click)="down('q')" class="arrow" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                                            <g id="Groupe_49" data-name="Groupe 49" transform="translate(-1332 -546)">
                                                                                <rect id="Rectangle_40" data-name="Rectangle 40" width="18" height="18" transform="translate(1332 546)" fill="#fff"/>
                                                                                <path id="Tracé_26" data-name="Tracé 26" d="M4239.46,1098.224l-4.553,4.553,4.553,4.553" transform="translate(-2897 -547.777)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                            </g>
                                                                        </svg>
                                                                        <span class="number">{{ticketQrcode || '0'}}</span>
                                                                        <svg  (click)="up('q')" class="arrow" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                                            <g id="Groupe_50" data-name="Groupe 50" transform="translate(-1332 -546)">
                                                                                <rect id="Rectangle_40" data-name="Rectangle 40" width="18" height="18" transform="translate(1332 546)" fill="#fff"/>
                                                                                <path id="Tracé_26" data-name="Tracé 26" d="M4234.907,1107.313l4.544-4.544-4.544-4.544" transform="translate(-2895.381 -547.786)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-12 sepChoix">
                                                                <b class="contText">CARTE OU QR CODE</b>
                                                        </div>
                                                    </div>

                                                    <fieldset style="border: 0px !important;display: none">
                                                        <legend class=" mb-3">Carte ou QRcode</legend>

                                                        <span class="mt-3">
                                                                Nombre de Ticket en QR Code
                                                        </span>
                                                        <div class="input-counter mb-3">
                                                            <input  style="height: 37px;text-align: right;" [ngModel]="ticketQrcode"
                                                                    (ngModelChange)="valueChangeQrcode($event)" type="text" oninput="this.value = this.value.replace(/[^0-9]/g, '');"  min="0" max="{{dataCheckEvent.nbrTicketParPersonne || 2}}"
                                                                    [value]="ticketQrcode">
                                                        </div>

                                                        <span>
                                                             Nombre de Ticket par Carte
                                                        </span>

                                                        <div class="input-counter">
                                                            <input style="height: 37px;text-align: right;" [ngModel]="ticketCarte" oninput="this.value = this.value.replace(/[^0-9]/g, '');"  min="0" max="{{dataCheckEvent.nbrTicketParPersonne || 2}}"
                                                                   (ngModelChange)="valueChangeCarte($event)" type="text"
                                                                   [value]="ticketCarte">
                                                        </div>

                                                    </fieldset>

                                                    <div class="product-add-to-cart"></div>

                                                    <div *ngIf="checkMaxticket">
                                                        <b style="color: red;">
                                                            {{errorMaxticket}}
                                                        </b>
                                                    </div>
                                                </div>

                                            </div>


                                            <div class="col-12">

                                                <div class="product-details-desc">
                                                    <div class="product-add-to-cart">
                                                        <div class="product-meta">
                                                <span class="rStyle"><i class='bx bx-minus before'></i>
                                                    TOTAL TICKET :
                                                    <span class="in-stock"> {{ticketNow}}</span></span>
                                                            <span class="rStyle" *ngIf="isPay"><i
                                                                class='bx bx-minus before'></i>
                                                                PRIX UNITAIRE :
                                                    <a class="in-stock">{{formatNumber(pu)}} &nbsp; FRS CFA </a></span>
                                                            <span class="rStyle" *ngIf="isPay"><i
                                                                class='bx bx-minus  before'></i>
                                                                MONTANT TOTAL :
                                                    <a class="in-stock">{{formatNumber(pt)}} &nbsp; FRS CFA</a></span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-12">
                                                <div class="product-add-to-cart" style="display: grid !important;place-items: center!important;">

                                                    <button (click)="NextStep1()" class="btnNext" nz-button nzType="primary">Suivant</button>

<!--                                                    <button (click)="NextStep1()" type="submit" class="default-btn"><i-->
<!--                                                        class='bx bx-plus icon-arrow before'></i><span class="label">-->
<!--                                                    SUIVANT-->
<!--                                                </span></button>-->

                                                </div>
                                            </div>
                                        </div>



                                    </ngx-tab>

                                    <ngx-tab [disabled]="activeSecond" [active]="activeSecond" tabTitle="Bénéficiaires">

                                        <div *ngIf="!responseLevel1" class="row align-items">

                                            <div class="col-lg-12 col-md-12">
                                                <div class="product-details-desc">
                                                    <div class="product-add-to-cart">
                                                        <b style="color: red;">Veuillez finaliser l'etape 1 et valider le bouton
                                                            suivant</b>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div *ngIf="responseLevel1" class="row align-items">
                                            <div class="col-12">
                                            <nz-tabset [nzSize]="'small'">
                                                <nz-tab nzTitle="Bénéficiaire {{i+1}}" *ngFor="let beneficiaire of beneficiaires.controls; let i=index">
                                                            <div class="row" style="margin-bottom: 19px">
<!--                                                                *ngIf="i===0"-->
                                                                <div class="col-12">
                                                                    <div class="row">
                                                                        <div class="col-8" style="display: flex">

                                                                            <nz-switch [(ngModel)]="dataPayTicket[i].pourMoi" (ngModelChange)="chEng($event,i)"></nz-switch>
                                                                            <label (click)="chEng1(!pourMoi,i)" style="color: #009A14;margin-top: 0px !important;margin-left: 11px;cursor: pointer">Acheter pour moi</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12">
                                                                    <label  style="color: #009A14">Nom : <code>*</code></label>
                                                                    <input nz-input class="form-control" [(ngModel)]="dataPayTicket[i].nom" type="text" />
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12">
                                                                    <label  style="color: #009A14">Prénoms : <code>*</code></label>
                                                                    <input nz-input class="form-control" [(ngModel)]="dataPayTicket[i].prenom" type="text" />
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12">
                                                                    <label  style="color: #009A14">Numéro de téléphone utilisé : <code>*</code></label>
                                                                    <nz-input-group nzCompact>
                                                                        <nz-select [(ngModel)]="dataPayTicket[i].indicatifPays">
                                                                            <nz-option  [nzLabel]="'+'+data.indicatif" [nzValue]="data.indicatif" *ngFor="let data of dContry"></nz-option>
                                                                        </nz-select>
                                                                        <input type="text" class="form-control noBorder" [(ngModel)]="dataPayTicket[i].telephone" style="width: 69%;margin-top: 0;height: 40px !important;" />
                                                                    </nz-input-group>
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12">
                                                                    <div  *ngIf="ticketCarte">
                                                                        <label  style="color: #009A14">Numéro de la carte NBH : </label>
                                                                        <input nz-input  [(ngModel)]="dataPayTicket[i].carte" type="text"
                                                                               class="form-control" style="text-transform: uppercase">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                </nz-tab>
                                            </nz-tabset>
                                            </div>
                                            <div class="col-lg-8 col-md-8">
                                                <div class="product-details-desc">
                                                    <div class="product-add-to-cart">


                                                        <div class="row">
                                                            <div class="col-lg-12 col-md-12">


<!--                                                                <form [formGroup]="beneficiaireForm">-->


<!--                                                                    <div formArrayName="beneficiaires">-->

<!--                                                                        <div *ngFor="let beneficiaire of beneficiaires.controls; let i=index"-->
<!--                                                                             [formGroupName]="i" class="billing-details"-->
<!--                                                                             style="margin-bottom: 23px;">-->

<!--                                                                            <fieldset>-->
<!--                                                                                <legend>Beneficiaire {{i+1}} - Ticket</legend>-->

<!--                                                                                        <label>Nom <span-->
<!--                                                                                            class="required">*</span></label>-->
<!--                                                                                        <input formControlName="nom" type="text"-->
<!--                                                                                               class="form-control">-->

<!--                                                                                        <label>Prenom <span-->
<!--                                                                                            class="required">*</span></label>-->
<!--                                                                                        <input formControlName="prenom" type="text"-->
<!--                                                                                               class="form-control">-->
<!--&lt;!&ndash;                                                                                <div class="col-12">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                    <div class="form-group">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                        <label  class="labelDis">Pays bénéficiaire <span class="required">*</span></label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                        <select formControlName="indicatifPays"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                                class="form-control inpForm form-control">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                            <option *ngFor="let data of dContry" [value]="data.indicatif">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                                <span class="flag-icon flag-icon-{{ data.code }}"></span>   {{data.country}}&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                            </option>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                        </select>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                                                                <ngx-flag-picker&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                    [selectedCountryCode]="selectedCountryCode"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                    [countryCodes]="countryCodes"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                    (changedCountryCode)="changeSelectedCountryCode($event)">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                </ngx-flag-picker>&ndash;&gt;-->



<!--                                                                                        <label>Numéro de tel utilisé à la création du compte NBH <span-->
<!--                                                                                            class="required">*</span></label>-->
<!--                                                                                <nz-input-group nzCompact>-->
<!--                                                                                    <nz-select [(ngModel)]="indicatifPays" formControlName="indicatifPays">-->
<!--                                                                                        <nz-option  [nzLabel]="'+'+data.indicatif" [nzValue]="data.indicatif" *ngFor="let data of dContry"></nz-option>-->
<!--                                                                                    </nz-select>-->
<!--                                                                                    <input type="text" formControlName="telephone" style="width: 75%;margin-top: 0;height: 35px !important;" />-->
<!--                                                                                </nz-input-group>-->
<!--&lt;!&ndash;                                                                                        <input formControlName="telephone"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                               type="text" class="form-control">&ndash;&gt;-->


<!--                                                                                <div  *ngIf="ticketCarte">-->
<!--                                                                                        <label>Numéro de la carte NBH </label>-->
<!--                                                                                        <input formControlName="carte" type="text"-->
<!--                                                                                               class="form-control" style="text-transform: uppercase">-->
<!--                                                                                </div>-->


<!--                                                                            </fieldset>-->


<!--                                                                            <h3 style="display: none" class="title">Beneficiaire {{i+1}} - Ticket</h3>-->
<!--                                                                            <div class="row" style="display: none">-->
<!--                                                                                <div class="col-lg-6 col-md-6">-->
<!--                                                                                    <div class="form-group">-->
<!--                                                                                        <label>Nom <span-->
<!--                                                                                            class="required">*</span></label>-->
<!--                                                                                        <input formControlName="nom" type="text"-->
<!--                                                                                               class="form-control">-->

<!--                                                                                    </div>-->

<!--                                                                                </div>-->
<!--                                                                                <div class="col-lg-6 col-md-6">-->
<!--                                                                                    <div class="form-group">-->
<!--                                                                                        <label>Prenom <span-->
<!--                                                                                            class="required">*</span></label>-->
<!--                                                                                        <input formControlName="prenom" type="text"-->
<!--                                                                                               class="form-control">-->
<!--                                                                                    </div>-->
<!--                                                                                </div>-->
<!--                                                                                <div class="col-lg-6 col-md-6">-->
<!--                                                                                    <div class="form-group">-->
<!--                                                                                        <label  class="labelDis">Pays bénéficiaire <span class="required">*</span></label>-->
<!--                                                                                        <select formControlName="indicatifPays"-->
<!--                                                                                                class="form-control inpForm form-control">-->
<!--                                                                                            <option *ngFor="let data of dContry" [value]="data.indicatif">-->
<!--                                                                                                <span class="flag-icon flag-icon-{{ data.code }}"></span>   {{data.country}}-->
<!--                                                                                            </option>-->
<!--                                                                                        </select>-->
<!--                                                                                    </div>-->
<!--                                                                                </div>-->
<!--                                                                                <div class="col-lg-6 col-md-6">-->
<!--                                                                                    <div class="form-group">-->
<!--                                                                                        <label>Numéro de tel utilisé à la création du compte NBH <span-->
<!--                                                                                            class="required">*</span></label>-->
<!--                                                                                        <input formControlName="telephone"-->
<!--                                                                                               type="text" class="form-control">-->
<!--                                                                                    </div>-->
<!--                                                                                </div>-->
<!--                                                                                <div class="col-lg-6 col-md-6" *ngIf="ticketCarte">-->
<!--                                                                                    <div class="form-group">-->
<!--                                                                                        <label>Numéro de la carte NBH </label>-->
<!--                                                                                        <input formControlName="carte" type="text"-->
<!--                                                                                               class="form-control">-->
<!--                                                                                    </div>-->
<!--                                                                                </div>-->


<!--                                                                            </div>-->
<!--                                                                        </div>-->

<!--                                                                    </div>-->

<!--                                                                </form>-->

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="spaced-dotted-line"></div>
                                                <br>
                                            </div>
                                            <div class="col-lg-6 col-sm-12">
                                                <div class="product-add-to-cart">

                                                    <div class="products-details-tab-content">
                                                        <ul class="additional-information">
                                                            <li *ngIf="isPay">
                                                                <i class='bx bx-minus'></i> <b class="recapEleme">TARIF UNITAIRE &nbsp;:&nbsp; </b> <span class="recapEleme"  style="color: #006837">{{formatNumber(pu)}} FCFA</span>
                                                            </li>
                                                            <li><i class='bx bx-minus'></i> <b class="recapEleme">TICKET TOTAL &nbsp;:&nbsp;</b><span class="recapEleme" style="color: #006837">{{ticketNow}}</span> </li>
                                                            <li><i class='bx bx-minus'></i> <b class="recapEleme">TICKET QR_CODE &nbsp;:&nbsp;</b><span class="recapEleme" style="color: #006837"> {{ticketQrcode}}</span></li>
                                                            <li><i class='bx bx-minus'></i> <b class="recapEleme">TICKET CARTE &nbsp;:&nbsp;</b><span class="recapEleme" style="color: #006837"> {{ticketCarte}}</span></li>
                                                            <li *ngIf="responseLevel1.checkPromoQte && isPay"><i class='bx bx-minus before'></i>
                                                                <b class="recapEleme">REDUCTION SUR QUANTITE &nbsp;:&nbsp;</b><span class="recapEleme" style="color: #006837"> {{responseLevel1.reduction}}</span>
                                                            </li>

                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-12">
                                                <p  *ngIf="isPay" class="tottal" style="text-align: center">
                                                    <b style="color: #092055;font-size: 20px">TOTAL A PAYER &nbsp;:&nbsp;</b> <span style="color: #006837;font-size: 20px">{{formatNumber(responseLevel1.total)}} FCFA</span></p>
                                                <div class="row align-items" style="margin-top: 78px;">
                                                    <div class="col-6">
                                                        <button (click)="PrecStep2()" class="preVious" nz-button nzType="primary">
                                                            <span class="label">Précédent</span>
                                                        </button>
                                                    </div>
                                                    <div class="col-6">
                                                        <button class="NextTo" nz-button nzType="primary" (click)="checkSecondFormulaire()">
                                                            <span class="label">Suivant</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>





                                        </div>


                                    </ngx-tab>

                                    <ngx-tab [disabled]="activeThree" [active]="activeThree" tabTitle="Récapitulatif">
                                        <div *ngIf="!checkFinale" class="row align-items">
                                            <div class="col-lg-12 col-md-12">
                                                <div class="product-details-desc">
                                                    <div class="product-add-to-cart">
                                                        <b style="color: red;">Veuillez finaliser les etapes précedantes et valider
                                                            le bouton
                                                            suivant</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="checkFinale" class="row align-items">
                                            <div class="col-12">
                                                <div class="order-details">

                                                    <fieldset>
                                                        <legend class="mb-3">Récapitulatif des informations</legend>
                                                        <div class="order-table table-responsive">
                                                            <table>
                                                                <thead>
                                                                <tr>
                                                                    <th>Beneficiaire(s)</th>
                                                                    <th>Contact</th>
                                                                    <th>N° de carte du bénéficiaire</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr *ngFor="let b of responseStep2.items; let i=index">
                                                                    <td>{{b.nom }} {{b.prenom }}</td>
                                                                    <td>{{b.telephone }}</td>
                                                                    <td>{{b.carte }}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </fieldset>

                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="payment-box">
                                                    <div *ngIf="hasReduction" class="payment-method">
                                                        <p *ngIf="isPay">
                                                            <label for="direct-bank-transfer">
                                                                <b>TOTAL SANS REDUCTION : </b> {{responseStep2.total}}
                                                            </label>
                                                        </p>

                                                        <p *ngIf="isPay">
                                                            <label for="direct-bank-transfer">
                                                                <b>REDUCTION : </b> {{responseStep2.reduction}}
                                                            </label>
                                                        </p>

                                                        <p *ngIf="isPay">
                                                            <label for="direct-bank-transfer" style="font-size: 20px">
                                                                <b>TOTAL A PAYER : </b> {{responseStep2.montantpayer}}
                                                            </label>
                                                        </p>

                                                    </div>
                                                    <div *ngIf="hasReduction && isPay" class="payment-method">
                                                        <br>
                                                        <p>
                                                            <label for="direct-bank-transfer">
                                                                <b>REDUCTIONS </b>
                                                            </label>
                                                        </p>
                                                        <div *ngFor="let r of responseStep2.allreductions">
                                                            <p>
                                                                <label for="direct-bank-transfer" style="color: #fff !important;margin-left: 25%">
                                                                    <b>{{r.reductionlib}} </b> {{r.reductionvalue}}
                                                                </label>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="!hasReduction && isPay" class="payment-method">
                                                        <p>
                                                            <label for="direct-bank-transfer"  style="font-size: 20px">
                                                                <b>TOTAL A PAYER : </b> {{responseStep2.montantpayer}}
                                                            </label>
                                                        </p>
                                                    </div>

                                                    <div *ngIf="isOpean" class="cart-buttons">
                                                        <div class="row align-items-center">
                                                            <div class="col-lg-7 col-sm-7 col-md-7">
                                                                <div class="shopping-coupon-code">
                                                                    <form [formGroup]="openForm">
                                                                        <input type="number" class="form-control"
                                                                               placeholder="Montant Libre" [formControl]="openForm.controls['montant']"
                                                                               id="coupon-code">
                                                                    </form>

                                                                    <button class="default-btn"
                                                                            (click)="saveTicket()">FINALISER</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <br>
                                                    <a *ngIf="!isOpean" (click)="saveTicket()" style="cursor: pointer;"
                                                       class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span
                                                        class="label">FINALISER</span><i
                                                        class="bx bx-paper-plane icon-arrow after"></i></a>
                                                </div>

                                            </div>
                                        </div>






                                    </ngx-tab>

                                </ngx-tabset>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

</div>
