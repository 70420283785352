<section class="mission-area ptb-100" [@slideInOut]="'in'" id="your-element"
         style="padding-top: 0; padding-bottom: 5px;height: 752px !important;">
    <section class="mission-area" style="background: rgba(0, 0, 0, 0.3) !important;height: 752px !important;">
        <app-navbar></app-navbar>

        <div class="container mb-30" class="btmLigne">
            <div class="col-12" style="margin-top: 50px !important;">
                <nav class="navCat">
                    <a href="javascript:void(0)" (click)="selectNav(data,i)" *ngFor="let data of rows;let i=index"
                       id="navCatCheck{{i}}">{{data.categorielib}}</a>
                </nav>
            </div>

        </div>

        <div class="container wContent" style="margin-bottom: 85px !important;">

            <div style="display: none" class="row">
                <div class="col-lg-6 col-sm-12">
                    <span class="badge bgCat" id="myCat">EN CONCERT LIVE</span><br><br>
                    <strong id="myEvent"
                            style="font-size: 40px !important;color:#fff !important;text-shadow: 0 0 12px #000;">
                        DJ MIX EN CONCERT LIVE
                    </strong>
                    <br>
                    <p style="font-size: 15px !important; color:#fff !important;    text-shadow: 0 0 12px #000;text-align: justify">
                        <span id="myContentEvent">Le lorem ipsum est, en imprimerie,
                            une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page,
                            le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée.
                            Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.
                        </span>
                    </p>
                    <button type="button" (click)="goTicket()"
                            class="btn btn-warning text-nowrap d-inline-flex position-relative me-4 rubBtn"
                            style="margin-top: 5%;background: #33A442;border: 2px solid #33A442;">
                        <span style="padding: 1px;margin-left: 9px;margin-right: 9px;"><b>
                            <svg
                                xmlns="http://www.w3.org/2000/svg" width="17.982" height="12.845"
                                viewBox="0 0 17.982 12.845">
                        <path id="ticket-svgrepo-com"
                        d="M75.56,204.845H64v-3.853a2.569,2.569,0,0,0,0-5.138V192H75.56v3.211h1.284V192h5.138v3.853a2.569,2.569,0,0,0,0,5.138v3.853H76.845v-3.211H75.56Zm0-8.349v3.853h1.284V196.5Z"
                        transform="translate(-64 -192)"/>
                        </svg> &nbsp;&nbsp;
                     Acheter le ticket</b>  &nbsp;&nbsp; <svg style="font-size: 10px" xmlns="http://www.w3.org/2000/svg" width="11.859" height="19.719" viewBox="0 0 11.859 19.719">
                      <path id="Tracé_10" data-name="Tracé 10" d="M-1952.072,320.821l7.031,7.031-7.031,7.031" transform="translate(1954.9 -317.993)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
                    </svg>
                    </span>
                    </button>
                </div>
<!--                display: flex;-->
                <div class="col-lg-6 col-sm-12" style="float: right;justify-content: right;">
                    <div class="row">
                        <div class="col-12" style="justify-content: right;display: flex;">
                            <nz-input-group [nzSuffix]="suffixIconSearch">
                                <input type="text" [(ngModel)]="query"
                                       (input)="onSearch()"
                                       autocomplete="off" nz-input placeholder="Rechercher..." />
                            </nz-input-group>
                            <ng-template #suffixIconSearch>
                                <span nz-icon nzType="search"></span>
                            </ng-template>
                        </div>
                        <div class="col-12" style="justify-content: right;display: flex;">
                            <div *ngIf="filteredSuggestions.length > 0 && showSuggestions" class="suggestions">
                                <div *ngFor="let suggestion of filteredSuggestions" (click)="selectSuggestion(suggestion)">
                                    <img src="{{suggestion.url}}" alt="" style="width: 72px;"> {{ suggestion.eventitre }}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="containerPost" style="display: none">


                        <!--                        <div class="slide" *ngFor="let slide of dataEvent; let i = index"-->
                        <!--                             [style.display]="i === slideIndex ? 'flex' : 'none'">-->
                        <!--                            <div class="event" *ngFor="let event of slide.events">-->
                        <!--                                <img (click)="changeEvent(event,slide.categorie,1)" [src]="event.url" [alt]="event.alt"-->
                        <!--                                     style="width: 100%;height: 100%;cursor: pointer">-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="controls">-->
                        <!--                            <button class="btnCtrl" (click)="prevSlide()">&#10094;</button>-->
                        <!--                            <button class="btnCtrl" (click)="nextSlide()">&#10095;</button>-->
                        <!--                        </div>-->
                    </div>

                    <div class="poster-wrapper" style="display: none">

                        <div class="poster">
                            <div class="poster-item">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX2gZDplStDpM26qVw5MSpXYCcOkdDA3AOOg&s"
                                    alt="Abidjan Laguna Show">
                                <div class="caption">ABIDJAN LAGUNA SHOW</div>
                            </div>
                            <div class="poster-item">
                                <img src="https://cdn.pixabay.com/photo/2013/07/13/10/51/football-157930_640.png"
                                     alt="FEMUA 15">
                                <div class="caption">FEMUA 15</div>
                            </div>
                        </div>


                    </div>
                    <!--                    <div class="controls">-->
                    <!--                        <button class="prev" onclick="prevSlide()">&#9664; Previous</button>-->
                    <!--                        <button class="next" onclick="nextSlide()">Next &#9654;</button>-->

                    <!--                    </div>-->

                </div>
            </div>

        </div>
        <!--        <app-slider-billeterie></app-slider-billeterie>-->
        <div style="display: none" class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>

    </section>
</section>

<section>
    <div class="container">
        <div class="conLine">
            <div class="banner"></div>
            <div class="text">
                <img src="assets/img/logoNbh.png" alt="NBH Logo" style="width: 50% !important;">
            </div>
            <div class="logo">
                <h1 class="textNbh">La solution de billetterie </h1>
                <h1 class="textNbh">idéale pour vos évènements.</h1>
                <!--                <img src="assets/img/logoNbh.png" alt="NBH Logo">-->
            </div>
        </div>
        <div class="text_bot">
            <p style=" font-family: 'Cocon', serif;color: #1D1D1D">Une plateforme révolutionnaire disponible sur tout type d'appareils.<br>
                Où que vous soyez, découvrez tous les évènements disponibles, payez vos tickets et rendez-vous avec
                votre carte ou votre code QR
                <br> pour profiter sans aucun souci.
            </p>
        </div>
        <!--                <strong style="font-size: 25px !important;font-family: Poppins, sans-serif; color:#319608 !important;-->
        <!--           text-shadow: 0 0 1px #000; text-align: center;display: block;">-->
        <!--                    VOTRE BILLETERIE EN LIGNE <br>-->
        <!--                    <span style="color: #b37d0f !important;">N1</span>  EN COTE D'IVOIRE.-->
        <!--                </strong>-->
        <app-slider-billeterie></app-slider-billeterie>

    </div>
</section>
