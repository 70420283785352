export const environment = {
    production: false,
    state: 'local',
    googleMapsApiKey: '',
    //backend: 'http://localhost:4200', // Put your backend here
    // backend: 'http://localhost:9276', // Put your backend here
    //backend: 'https://tickey.ci/BilleterieDigitaleSpectateur-v-1.0.0/',
    //backend:'http://51.91.99.88:8080/BilleterieDigitaleSpectateur-v-1.0.0',
    serverLoggingUrl: '',

    //INDIQUE L'ADRESSE DU BACK END SPECTATEUR
    //apiUrl:'http://localhost:9276',

    // apiUrl:'http://51.91.99.88:8080/BilleterieDigitaleSpectateur-v-1.0.0',
    //apiUrl:'https://tickey.ci/BilleterieDigitaleSpectateur-v-1.0.0/',

    //apiUrl:' http://nbh.ci:8025/digitalbackendspectateur/',

    // Ancien Url
    //apiUrl:'https://tickey.ci/BilleterieDigitaleSpectateur-v-1.0.0/',

    //apiUrl:'http://57.129.6.156:8080/digitalbackendspectateur',
    //apiUrl:'http://57.129.6.156:8090/api/digitalbackendspectateur',

    apiUrl: 'https://nbh.ci/endpoint-nbh/:',
    // apiUrl: 'https://nbh.ci:4848/digitalbackendspectateur',
    apiUrlSms: 'https://nbh.ci',
    //apiUrl: 'http://51.75.200.156:9214',
    // apiUrl: 'https://backsepctateur.digibit.ci',


    keylocalstorage: 'curentspectateur-billeterie',
    keyNormal: 'RZHWMF35',
    keyShared: 'CHNQVO36',
    keyCarte: 'QFECC112',
    keyQrcode: 'KCQHZ113',
    categorieTicketPayant: 'NKMXCF21',
    categorieTicketGratuit: 'IHPHWW23',
    categorieTicketLibre: 'DTCJET22',

    cinetpayApikey: '964013949618a6a3597a644.40844633',
    cinetpaySiteid: '704090',
    cinetpayCheckUrl: 'https://api-checkout.cinetpay.com/v2/payment/check',


    dataSendSMS: {
        username: 'ali.ouattara08@yahoo.com',
        password: 'QSmlF476',
        sender_id: 'Ventoo',
    },

    APP_PASS: 'SàFçNMCëÎBAïÎNWiÏCA=',
    APP_TOKEN_NAME: '_164_',
    APP_TOKEN_ACHAT: '_164_',
    urlOrgan: 'https://nbh-organisateur.com/#/session/connexion',
    key_pay: 'pk_live_1dff8534aef1c61c42a76a1350dc6361f4265d0f'
};
//  apiUrl: 'https://billetbro.connecto-ci.com',
