import { Component } from '@angular/core';

@Component({
  selector: 'app-error-wave',
  templateUrl: './error-wave.component.html',
  styleUrls: ['./error-wave.component.scss']
})
export class ErrorWaveComponent {

}
