<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 250px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color:  #0B2154 !important;height: 250px !important;">
        <app-navbar></app-navbar>
        <div class="container">
            <div class="menu">
                <div class="menu-title">
                    <span>Mes cartes</span>
                </div>
                <div class="profile">
                    <div class="row _user">
                        <div class="col-4">
                            <svg style="    width: 98%;" xmlns="http://www.w3.org/2000/svg" width="104" height="106" viewBox="0 0 104 106">
                                <g id="Groupe_58" data-name="Groupe 58" transform="translate(-449.57 -116.254)">
                                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="52" cy="52" r="52" transform="translate(449.57 116.255)" fill="#60ba07"/>
                                    <text id="G" transform="translate(501.57 200.254)" fill="#cecece" font-size="87" font-family="Montserrat-Bold, Montserrat" font-weight="700" letter-spacing="0.041em"><tspan x="-32.625" y="0">{{_lettre(dataUsers?.spectateurnom)}}</tspan></text>
                                </g>
                            </svg>

                        </div>
                        <div class="col-8 _inf_user">
                            <span class="profile-text">{{dataUsers?.spectateurnom}} {{dataUsers?.spectateurprenom}}</span> <br>
                            <span class="profile-text">{{dataUsers?.spectateurtel}}</span><br>
                            <span class="profile-text">{{dataUsers?.spectateuremail}}</span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>
</section>

<div class="courses-details-area pb-70" style="background-color:#006837 ;min-height: 100%;">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <nav class="navCat">
                    <a style="cursor: pointer" [routerLink]="data.uri"  *ngFor="let data of dataNav;let i=index" id="nav{{i}}">{{data.label}}</a>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-8">
                <div class="courses-details-desc">


                    <div class="contLLne">
                        <div class="title">Carte n° : {{numcarte}}</div>
                        <div class="content">
                            <div class="card-section">
                                <div
                                    class="card {{i === currentCardIndex ?'visible':'' }}"
                                    *ngFor="let card of cartes; let i = index"
                                    [ngClass]=""
                                    (click)="checkTicketCarte(card)">
                                    <img [src]="card.url || 'assets/img/carte/babatche.jpeg'" alt="{{card?.categorie}}">
                                </div>

                            </div>
                            <div class="arrow" (click)="nextCard()">&#9654;</div>
                            <div class="tickets-section">
                                <div class="tickets-title">Les tickets de la carte</div>
                                <div class="tickets">
                                    <div class="ticket" *ngFor="let ticket of EventBycartes" (click)="shareCarte(ticket)" style="cursor: pointer">
                                        <img [src]="ticket.image" alt="{{ticket.event}}">
                                        <div class="price">{{ticket.price}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="btn btnCl" style="margin-left: 2%; margin-top: 6%;" (click)="addCarte()">Ajouter une carte</button>
                    </div>


                </div>
            </div>
            <div class="col-lg-2">

            </div>
        </div>
    </div>
</div>



<div class="modal" *ngIf="isOpen">
    <div class="modal-content">
        <div class="modal-header">
            <span>{{ 'AJOUTER UNE CARTE A MON COMPTE'}}</span>
        </div>
        <div class="modal-body">

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>ENTREZ LE CODE DE LA CARTE <span class="required">*</span></label>
                            <input type="text" [(ngModel)]="codecarte"
                                   class="form-control">
                        </div>
                    </div>
                </div>


        </div>
        <div class="modal-footer">
            <div class="row">
                <div class="col-6">
                    <button class="btn btnCl" style="width: 126px;" (click)="closeModal()">Fermer</button>
                </div>
                <div class="col-6">
                    <button class="btn btnCl" style="width: 126px;background: #096dd9;border-color: #096dd9;color: #fff !important;" (click)="saveCard()">Valider</button>
                </div>
            </div>

        </div>
    </div>
</div>






<nz-modal
    [(nzVisible)]="isVisible"
    nzTitle="CARTE {{dataDetails.categorie}} N° {{dataDetails.cartecode}}"
    nzOkText=""
    nzCancelText="Fermer"
    [nzCentered]="true"
    (nzOnOk)="handleOk()"
    (nzOnCancel)="handleCancel()"
    [nzMaskClosable]="false"
    nzWidth="650px"
    [nzFooter]="null"
>
    <ng-container *nzModalContent>

        <p *ngIf="dataDetails.cartenable" class="finTxt">Statut  : <nz-tag [nzColor]="'#87d068'">{{dataDetails.cartestatuscode}}</nz-tag> </p>
        <p *ngIf="!dataDetails.cartenable" class="finTxt">Statut  : <nz-tag [nzColor]="'#f50'">{{dataDetails.cartestatuscode}}</nz-tag> </p>
        <p class="finTxt">Categorie  : {{dataDetails.categorie}}</p>
        <p class="finTxt">Numéro de carte  : {{dataDetails.cartecode}}</p>
        <p class="finTxt">Date d'activation  : {{dataDetails.cartedateDebutUtilisation}}</p>
        <p class="finTxt">Date d'expiration  : {{dataDetails.cartedateFinUtilisation}}</p>
        <p class="finTxt">
            <button nz-button nzType="primary" (click)="checkCarte()">{{dataDetails.cartenable ?'Desactiver':'Activer'}} la carte</button>

<!--            <nz-switch-->
<!--        [ngModel]="cartenable"-->
<!--        [nzCheckedChildren]="checkedTemplate"-->
<!--        [nzUnCheckedChildren]="unCheckedTemplate"-->
<!--        (ngModelChange)="checkCarte($event)"-->
<!--    ></nz-switch>-->
<!--    <ng-template #checkedTemplate> <span nz-icon [nzType]="'check'"></span></ng-template>-->
<!--    <ng-template #unCheckedTemplate> <span nz-icon [nzType]="'close'"></span></ng-template>  Cocher pour desactiver la carte-->
</p>


    </ng-container>
</nz-modal>





<nz-modal
    [(nzVisible)]="isTicket"
    nzTitle="Transfert de ticket N° {{ligneTicket.ticketcode}}"
    nzOkText=""
    nzCancelText="Fermer"
    [nzCentered]="true"
    (nzOnOk)="handleOk()"
    (nzOnCancel)="handleCancel()"
    [nzMaskClosable]="false"
    nzWidth="650px"
    [nzFooter]="null"
>
    <ng-container *nzModalContent>


        <p class="finTxt">Evenement  : {{ligneTicket.event}}</p>
        <p class="finTxt">Categorie de ticket  : {{ligneTicket.caticket}}</p>


        <div class="row">
            <div class="col-6">
                <label>N° Carte beneficiaire</label>
                <input autocomplete="off" nz-input placeholder="N° Carte beneficiaire" [(ngModel)]="beneficiaire" type="text" />
            </div>
            <div class="col-6">
                <label>Mot de passe</label>
                <input nz-input  autocomplete="off" placeholder="Mot de passe" [(ngModel)]="password" type="password" />
            </div>
        </div>
        <br><br>
        <button nz-button nzType="primary" class="float-right" (click)="shareTicket()">Transferer</button>
    </ng-container>
</nz-modal>




<!--{-->
<!--"lockFlag": 1,-->
<!--"ticketcode": "ZFNUJTXXX308",-->
<!--"achatcode": "KYZATILIV811",-->
<!--"typeticket": "CARTE",-->
<!--"typeticketcode": "QFECC112",-->
<!--"beneficiairecarte": "CI242569KI",-->
<!--"beneficiairetel": "2250150030925",-->
<!--"beneficiairenom": "string",-->
<!--"beneficiaireprenom": "string",-->
<!--"spectateurcode": "MAWMFXMBC105",-->
<!--"spectateurtypecode": "XUXBA117",-->
<!--"spectateurtype": "STANDARD",-->
<!--"ticketstep": 1,-->
<!--"eventcode": "BQIP80",-->
<!--"event": "PIERRE AKENDENGUE À ABIDJAN",-->
<!--"caticket": "VIP",-->
<!--"caticketcode": "PPCP1663",-->
<!--"hasreduction": true,-->
<!--"ticketreduction": 0,-->
<!--"ticketmontantnormal": 600,-->
<!--"ticketmontantpayer": 600,-->
<!--"provenance": "EN LOCAL",-->
<!--"acheteurcode": "LTUIAHV220",-->
<!--"modecode": "RZHWMF35",-->
<!--"mode": "NORMAL",-->
<!--"organecode": "EBAWNR78",-->
<!--"organe": "ONS CI",-->
<!--"ticketenable": true,-->
<!--"ticketdatecreation": "2025-03-06T00:18:53.911+0000",-->
<!--"lieudespectacleCode": "JWMVCJ1321",-->
<!--"sallecode": "IJZYWZ1657",-->
<!--"primaryKey": {-->
<!--"ticketid": "4215f140-c4ec-468b-9f5b-8121784a569d"-->
<!--}-->
<!--    }-->
