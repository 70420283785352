import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'src/environments/environment';
import {EventwithkeyService} from '../home-billeterie/eventwithkey.service';
import {Carte, Spectateur} from '../spectateur';
import Swal from "sweetalert2";

declare let $: any;

@Component({
    selector: 'app-carte-billeterie',
    templateUrl: './carte-billeterie.component.html',
    styleUrls: ['./carte-billeterie.component.scss']
})
export class CarteBilleterieComponent implements OnInit {


    cartes: Carte[] = [];
    dataUsers: any = [];
    loadingCartecheck: any = false;

    showCarte: Carte;

    showListCarte: any = true;

    createCarte: any = false;

    createCardForm;

    statusCardForm;

    statusCardCheckBool: boolean = false;

    dataMenu: any = [
        {
            uri: "/",
            label: "Accueil",
        }, {
            uri: "/concert",
            label: "Découvrir",
        }, {
            uri: "/connecter",
            label: "Mon profil",
        }
    ]
    dataNav: any = [
        {
            uri: "/achat",
            label: "Mes achats",
        }, {
            uri: "/ticket",
            label: "Mes tickets",
        },
        // {
        //     uri: "/receive",
        //     label: "Tickets reçu",
        // },
        {
            uri: "/foward",
            label: "Tickets transférés",
        }, {
            uri: "/carte",
            label: "Mes cartes",
        }, {
            uri: "/notification",
            label: "Notifications",
        }, {
            uri: "/infos",
            label: "Info personnelle",
        }
    ];
    imgDefault: string = "../../../../../../assets/img/party.jpg";
    currentCardIndex = 0;
    cards = [
        'assets/img/carte/babatche.jpeg',
        'assets/img/carte/danhere.jpeg',
        // Add more card image paths as needed
    ];

    tickets = [
        {image: 'assets/img/carte/farot.jpeg', price: 'VIP : 20,000 F'},
        {image: 'assets/img/carte/farot.jpeg', price: 'VIP : 20,000 F'},
        // Add more tickets as needed
    ];
    isOpen: boolean = false;
    codecarte: string = "";
    numcarte: string='';
     EventBycartes: any=[];
    isVisible: boolean=false;
    dataDetails: any=[];
    cartenable: boolean=true;
    ligneTicket: any=[];
    isTicket:  boolean=false;
    beneficiaire: string="";
    password: string="";

    constructor(private _activatedRoute: ActivatedRoute, private router: Router,
                private gestwithkey: EventwithkeyService, public fb: UntypedFormBuilder,
                private toastr: ToastrService,
                private logger: NGXLogger) {
    }

    ngOnInit(): void {
        this.showCard(this.currentCardIndex);
        this.createCardForm = this.fb.group({
            'cartecode': [null, Validators.required]
        });

        this.statusCardForm = this.fb.group({
            'cartepasswd': [null, Validators.required],
            'step': [null, Validators.required]
        });

        let u = new Spectateur();
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        this.dataUsers = u
        if (!u) {
            this.router.navigate(['/'])
        }
        this.loadCarte();
        $('#imgProfil').css({
            'background-image': 'url(' + this.imgDefault + ')',
            'background-size': 'cover'
        });

    }


    showCard(index: number) {
        const cards = document.querySelectorAll('.card');
        cards.forEach((card, i) => {
            card.classList.toggle('visible', i === index);
        });
    }

    nextCard() {
        this.currentCardIndex = (this.currentCardIndex + 1) % this.cartes.length;
     //   console.log("this.cards.length ====",this.cartes.length)
        let ddCarte = this.cartes[this.currentCardIndex];
        this.numcarte = ddCarte.cartecode;
        this.loadEventByCarte(ddCarte.cartecode);
        this.showCard(this.currentCardIndex);
    }

    showMyCarte(carte: any) {
        this.showCarte = carte;
        this.showListCarte = false;
    }

    closeMyCarte() {
        this.showCarte = null;
        this.showListCarte = true;
    }

    showCreate() {
        this.createCarte = true;
    }

    closeCreate() {
        this.createCarte = false;
    }


    changeCardStatus(i: any) {
        this.statusCardCheckBool = true;
        this.statusCardForm = this.fb.group({
            'cartepasswd': [null, Validators.required],
            'step': [i, Validators.required]
        });

    }

    cancelCardStatus() {
        this.statusCardCheckBool = false;
    }

    logout() {
        localStorage.clear();
        this.router.navigate(['/'])
    }

    saveChangeStatus(step) {
        console.log("-- step -- => " + step);
        let u: Spectateur;
        console.log(this.statusCardForm.value);
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        if (!this.statusCardForm.valid) {
            this.toastr.error('Echec!', 'veuillez renseigner votre mot de passe');
            return;
        }

        let message: string;

        if (step === 1) {
            message = "carte debloqué ";
        }

        if (step === 2) {
            message = "carte bloqué ";
        }


        if (u) {
            let request: any = this.statusCardForm.value;
            request.usercode = u.spectateurcode;
            request.cartecode = this.showCarte.cartecode;

            this.gestwithkey.post(request, "/billeterie/carte/changestatus", u.token).subscribe(data => {
                    this.logger.trace('INFOS CNX', data);

                    switch (data.status) {
                        case 200: {
                            this.createCarte = false;
                            this.toastr.success('Succes!', message);
                            this.cancelCardStatus();
                            this.loadCarte();
                            break;
                        }
                    }
                },
                error => {
                    //this.loader.close();

                    this.logger.error('ERROR', error);
                    switch (error.status) {

                        case 417: {
                            this.toastr.error('Echec!', 'Mot de passe incorrect');
                            break;
                        }


                        /*
                        case 412: {
                          this.toastr.error('Echec!', 'Carte deja liée ou pas activé');
                          break;
                        }
                        */

                        default: {
                            this.toastr.error('Echec!', 'Echec ');
                            break;
                        }
                    }
                });
        }
    }

    saveCard() {
        let u: Spectateur;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        // if (!this.createCardForm.valid) {
        //     return;
        // }

        if (u && this.codecarte) {
            let request: any = {
                cartecode:this.codecarte,
                spectateurcode:u.spectateurcode
            }//this.createCardForm.value;

            this.gestwithkey.post(request, "/billeterie/carte/save", u.token).subscribe(data => {
                    this.logger.trace('INFOS CNX', data);

                    switch (data.status) {
                        case 200: {
                            this.isOpen = false;
                            this.createCarte = false;
                            this.toastr.success('Succes!', 'Carte crée avec succès');
                            this.loadCarte();
                            break;
                        }
                    }
                },
                error => {
                    //this.loader.close();

                    this.logger.error('ERROR', error);
                    switch (error.status) {
                        case 200: {
                            this.isOpen = false;
                            this.createCarte = false;
                            this.toastr.success('Succes!', error?.error?.text || 'Carte crée avec succès');
                            this.loadCarte();
                            break;
                        }
                        case 423: {
                            this.toastr.error('Echec!', error.error);
                            break;
                        }


                        case 412: {
                            this.toastr.error('Echec!', 'Carte deja liée ou pas activé');
                            break;
                        }

                        default: {
                            this.toastr.error('Echec!', 'Echec ');
                            break;
                        }
                    }
                });
        }
    }

    loadCarte() {
        let u: Spectateur;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));

        this.logger.info(u);
        this.loadingCartecheck = true;
        this.showCarte = null;
        this.showListCarte = true;

        if (u) {
            // billeterie/carte/all/{}
            this.gestwithkey.get("/billeterie/carte/all/" + u.spectateurcode, u.token).subscribe(data => {
                    this.selectNav(3)
                    switch (data.status) {
                        case 200: {
                            this.loadingCartecheck = false;
                            this.cartes = data.body;
                            let ddCarte = this.cartes[0];
                            this.numcarte = ddCarte.cartecode;
                            this.loadEventByCarte(ddCarte.cartecode);
                            break;
                        }
                    }
                },
                error => {
                    this.selectNav(3)
                    switch (error.status) {
                        case 401: {
                            //this.router.navigate(['/authentication/signin']);
                            this.loadingCartecheck = false;
                            break;
                        }
                        default: {
                            this.logger.error("JE VIENS DE VERIFIER");
                            this.cartes = [];
                            this.loadingCartecheck = false;
                            this.logger.error(error);
                            break;
                        }
                    }
                });
        }
    }
    loadEventByCarte(codeCarte) {
        let u: Spectateur;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));

        this.logger.info(u);
        this.loadingCartecheck = true;
        this.showCarte = null;
        this.showListCarte = true;
        this.EventBycartes = [];
        if (u) {
            // /MAWMFXMBC105/CI242570WG
            this.gestwithkey.get("billeterie/:carte/:allTicketByCarteAndSpectateurCode/:" + u.spectateurcode+"/:"+codeCarte, u.token).subscribe(data => {
                    this.selectNav(3)
                    switch (data.status) {
                        case 200: {
                            this.EventBycartes = data.body;

                           console.log(this.EventBycartes);
                            break;
                        }
                    }
                },
                error => {
                });
        }
    }

    loadCarteTicket(dataCarte) {
        let u: Spectateur;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));

        if (u && dataCarte?.codecarte) {
            // ---/{codespectateur}/{codecarte}
            this.gestwithkey.get(`/billeterie/carte/allTicketByCarteAndSpectateurCode/${u.spectateurcode}/${dataCarte?.codecarte}`, u.token).subscribe(data => {
                    this.selectNav(3)
                    switch (data.status) {
                        case 200: {

                            this.tickets = data.body;

                            break;
                        }
                    }
                },
                error => {
                    this.selectNav(3)
                    switch (error.status) {
                        case 401: {
                            //this.router.navigate(['/authentication/signin']);
                            this.loadingCartecheck = false;
                            break;
                        }
                        default: {

                            this.logger.error(error);
                            break;
                        }
                    }
                });
        }
    }

    selectNav(i: number) {
        for (let j = 0; j < this.dataMenu.length; j++) {
            $('#nav' + j).removeClass("active");
        }
        $('#nav' + i).addClass('active');
    }

    checkTicketCarte(card: any) {
        this.cartenable = card.cartenable
        console.log(card)
        this.isVisible=true
        this.dataDetails = card
        console.log("card ==", card)
        this.loadCarteTicket(card)

    }

    closeModal() {
        this.isOpen = false;
    }

    addCarte() {
        this.isOpen = true;
    }

    _lettre(spectateurnom: string) {
        return spectateurnom?.substring(0, 1)?.toUpperCase();
    }

    _split(caticket: string) {

        let d = caticket.split(" ");
        let r: string = "";
        if (d.length > 1) {
            r = d[0].substring(0, 1) + "" + d[1].substring(0, 1)
        } else {
            r = caticket;
        }
        return r;
    }

    handleOk() {

    }
    handleCancel() {
this.isVisible=false
this.isTicket=false
    }

    checkCarte() {

        // this.cartenable = !!e
        // console.log(e)

        Swal.fire({
            title: "Activation/Desactivation",
            text: "Êtes vous sûr de vouloir desactiver votre carte ?",
            icon: "question",

            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmer",
            cancelButtonText:"Fermer"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Confirmation",
                    text: "Entrez votre mot de passe",
                    showCancelButton: true,
                    confirmButtonText: "Confirmer",
                    input: "password",
                    inputAttributes: {
                        autocapitalize: "off",

                    },
                    cancelButtonText:"Fermer"
                }).then((res)=>{
                    if (res.isConfirmed) {
                        console.log(res)
                        this.changestatus({cartepasswd:res.value})
                    }
                });
            }
        });

    }
    changestatus(dataChange) {
        let u: Spectateur;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));


            let payload = {
                "cartecode": this.dataDetails.cartecode,
                "cartepasswd": dataChange.cartepasswd,
                "step": this.dataDetails.cartenable ? 1 : 2,
                "usercode": u.spectateurcode
            }
        console.log("payload ===",payload)
        $('#cover-spin').show(0);
            this.gestwithkey.post(payload,`/billeterie/carte/changestatus`,u.token).subscribe(data => {
                    $('#cover-spin').hide(0);
                    switch (data.status) {
                        case 200: {
                            this.loadCarte()
                            this.isVisible=false;
                            Swal.fire({
                                icon: "success",
                                title: "Echec",
                                text: 'Executer avec succès',
                            });

                            break;
                        }
                    }
                },
                error => {
                    $('#cover-spin').hide(0);
                    Swal.fire({
                        icon: "error",
                        title: "Succès",
                        text: error?.error?.err,
                    });
                });

    }

    shareCarte(ticket: any) {
     this.beneficiaire="";
      this.password="";
        this.isTicket=true;
        this.ligneTicket = ticket
        console.log(ticket)
    }

    shareTicket() {
        let u: Spectateur;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));


        let payload ={
            "beneficiaire": this.beneficiaire,
            "password": this.password,
            "spectateurcode": u.spectateurcode,
            "ticketcode": this.ligneTicket.ticketcode,
            "type": "QFECC112"
        }
        console.log("payload ===",payload)
        $('#cover-spin').show(0);
        this.gestwithkey.post(payload,`/billeterie/ticket/sharebycard`,u.token).subscribe(data => {
                $('#cover-spin').hide(0);
                switch (data.status) {
                    case 200: {
                        this.loadCarte()
                        this.isTicket=false;
                        Swal.fire({
                            icon: "success",
                            title: "Succès",
                            text: 'Ticket transferé avec succès',
                        });

                        break;
                    }
                }
            },
            error => {
                $('#cover-spin').hide(0);
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    text: error?.error?.err,
                });
            });
    }
}
