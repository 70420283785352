<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 350px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color: #006837 !important;height: 350px !important;">
        <app-navbar></app-navbar>
        <div class="container" style="display: flex;justify-content: center">
            <h1 style="margin-top: auto;margin-bottom: auto;color: #0B2154 !important;font-family: Montserrat;font-size: 30px;line-height: 0.9;">Politique de confidentialité</h1> <img class="imgApropos" src="{{imgPay}}">
        </div>
    </section>
</section>

<div class="courses-details-area  pb-70"  style="background-color: #0B2154;min-height: 100%;">
    <div class="container">

        <div class="row">
            <div class="col-lg-2">

            </div>

            <div class="col-lg-8">

                <h3 style="text-align: center;margin-top: 15px;text-transform: uppercase">
                    Politique de Confidentialité des Utilisateurs
                </h3>

                <div class="courses-details-desc">
<!--                    <h3>-->
<!--                        VEUILLEZ LIRE ATTENTIVEMENT CETTE POLITIQUE DE CONFIDENTIALITÉ-->
<!--                        (CETTE « POLITIQUE DE CONFIDENTIALITÉ ») AVANT D&#39;UTILISER CE SITE WEB,-->
<!--                        APPLICATION OU TOUT AUTRE SERVICE DE www.nbh.ci.-->
<!--                    </h3>-->
                    <p>
                        Veuillez lire attentivement cette politique de confidentialité (la « Politique de Confidentialité ») avant d'utiliser ce site Web, l'application ou tout autre service de
                        <a href="www.nbh.ci" style="color: #fff">www.nbh.ci</a>.
                        <br>
                        NBH est un produit de la Nouvelle Billetterie Hybride, spécialisée dans le développement informatique et les technologies avancées. Nous offrons une billetterie moderne pour divers événements avec un système de contrôle d'accès automatique ou semi-automatique.
                        La protection de vos informations personnelles est cruciale pour nous. Nous avons mis en place cette politique pour assurer la sécurité et la confidentialité de vos données. Cette politique s'applique à tous les services de NBH disponibles sur notre site Web et nos applications, désignés collectivement comme les « Services de la Société ». En utilisant nos Services, vous acceptez cette politique de confidentialité.

                    </p>
<!--                    <p>-->
<!--                        La protection de la confidentialité de vos informations personnelles est importante pour notre-->
<!--                        société et pour garantir que vos informations personnelles sont conservées en toute sécurité et-->
<!--                        en toute confidentialité, nous avons développé la politique de confidentialité suivante. Cette-->
<!--                        politique de confidentialité s&#39;applique à l&#39;utilisation de tout site www.nbh.ci, y-->
<!--                        compris-->
<!--                        www.nbh.ci (le &quot;site Web&quot;), où cette politique de confidentialité apparaît dans le-->
<!--                        pied de-->
<!--                        page et à tous les produits, services, fonctionnalités, contenus ou applications de Digibit.ci-->
<!--                        où-->
<!--                        cet avis de confidentialité est référencé ou un lien vers cette politique de confidentialité est-->
<!--                        fourni, qui sont tous désignés ici collectivement comme les services de la société et-->
<!--                        individuellement comme un service de la société. Notre politique de confidentialité-->
<!--                        s&#39;applique-->
<!--                        à toutes les interactions entre vous et la société, car il est possible que, lors de ces-->
<!--                        interactions,-->
<!--                        nous puissions acquérir vos informations personnelles.-->
<!--                    </p>-->

                    <h3 style="text-transform: uppercase">Informations Personnelles Collectées</h3>
                    <p>
                        Lors de la vente de billets, nous collectons les informations personnelles suivantes pour faciliter vos transactions et contrôles :
                    </p>
                    <ul>
                        <li><b>Données personnelles : </b> Nom complet, adresse e-mail, numéro(s) de téléphone, nom d'utilisateur, mot de passe, etc...</li>
                        <li><b>Informations sur les transactions : </b> Détails des achats de billets et interactions avec les événements.</li>
                    </ul>
                    <p>
                        Nous collectons ces informations lorsque vous vous inscrivez, achetez des billets, participez à des promotions ou vous abonnez à nos services. Vous pouvez également acheter des billets en personne si vous préférez rester anonyme.
                    </p>
                    <h3  style="text-transform: uppercase">Utilisation et Partage des Informations Personnelles</h3>

                    <p>
                        Nous utilisons vos informations personnelles pour :
                    </p>
                    <ul>
                        <li><b> Traitement des transactions : </b>Effectuer les achats et communiquer avec vous concernant vos commandes.</li>
                        <li>
                            <b>Partage avec des tiers : </b> Vos informations peuvent être partagées avec des processeurs de paiement pour compléter les transactions et avec les hôtes d'événements pour vous informer des détails de l'événement.
                        </li>
                        <li>
                            <b>Fournisseurs de services :</b>  Nous partageons des informations avec des fournisseurs de services qui nous aident à gérer nos opérations et à vous fournir nos services, sous réserve qu'ils offrent un niveau similaire de protection des données.
                        </li>
                    </ul>

                    <h3   style="text-transform: uppercase">Objectif de la collecte des informations de genre et de date de naissance</h3>
                    <p>
                        1. &nbsp;<b>	Optimiser l'expérience utilisateur :</b> En analysant les données démographiques de manière agrégée, nous pouvons identifier les préférences générales de différents groupes d'âge et de genre. Cela nous permet d'améliorer les services et fonctionnalités en fonction des attentes et des besoins de nos utilisateurs.
                    </p>
                    <p>
                        2. &nbsp; <b>Adapter le contenu et les fonctionnalités :</b>	 En connaissant mieux les segments démographiques, nous pouvons offrir des suggestions de contenu plus pertinentes et adapter les offres ou les événements en fonction de l'âge et des intérêts.
                    </p>
                    <p>
                        3. &nbsp; <b>Améliorer les analyses statistiques : </b>	Les informations démographiques nous permettent d'obtenir des statistiques globales et anonymisées sur les types d'utilisateurs qui interagissent avec notre application. Ces statistiques sont cruciales pour le développement de notre produit et pour garantir qu'il reste attractif et pertinent.
                    </p>
                    <h3   style="text-transform: uppercase">
                        Respect de la confidentialité et protection des données
                    </h3>

                    <p>
                        Nous nous engageons à respecter strictement les normes de confidentialité de l'App Store et la réglementation en vigueur (RGPD par exemple). Toutes les données sont <b>traitées de manière anonyme et agrégée </b> , sans aucun lien avec l'identité personnelle de l'utilisateur. Les utilisateurs sont informés de la collecte de ces données et peuvent refuser de les fournir s'ils le souhaitent.
                    </p>
                    <h3   style="text-transform: uppercase">Cookies et Informations Techniques</h3>
                    <p>
                        Nous utilisons des cookies et des technologies similaires pour :
                    </p>
                    <ul>
                        <li><b>Collecte de données techniques : </b> Adresse IP, type de navigateur, système d'exploitation, durée de la visite.</li>
                        <li><b>Amélioration des services : </b> Analyser et améliorer les performances et la fonctionnalité de nos services.</li>
                    </ul>
                    <p>
                        Nous associons parfois ces informations techniques avec vos données personnelles pour mieux vous identifier et personnaliser votre expérience.
                    </p>
                    <h3   style="text-transform: uppercase">Réseaux Sociaux et Données de Localisation</h3>
                    <ul>
                        <li><b>Réseaux sociaux : </b> Vous pouvez lier vos comptes de réseaux sociaux à nos services. Nous pouvons partager des informations avec ces réseaux sociaux et accéder à certaines données que vous avez choisies de partager avec eux.</li>
                    <li><b>Données de localisation : </b>	Nous pouvons collecter vos données de localisation pour améliorer votre expérience. Vous pouvez activer ou désactiver cette fonctionnalité via les paramètres de votre appareil.</li>
                    </ul>

                <h3  style="text-transform: uppercase">Conservation des Données</h3>

                    <p>
                        Nous conservons vos informations personnelles pour la durée nécessaire aux fins spécifiées et en conformité avec les exigences légales, fiscales ou commerciales. La durée maximale de conservation est  de <b> 6 ans</b> après votre dernière transaction, sauf si prolongée pour des raisons spécifiques.
                    </p>
             <h3  style="text-transform: uppercase">Sécurité des Données</h3>

                    <p>
                        Nous mettons en place des mesures de sécurité pour protéger vos informations contre les accès non autorisés, les modifications et les divulgations. Cela inclut le cryptage et la sécurité physique de nos installations. Cependant, aucune méthode de sécurité n'est infaillible, et nous ne pouvons garantir une protection absolue contre les cyberattaques.
                    </p>

                    <h3  style="text-transform: uppercase">Liens Externes</h3>

                    <p>
                        Nos services peuvent contenir des liens vers des sites Web que nous ne contrôlons pas. Nous ne sommes pas responsables des pratiques de confidentialité de ces sites. Nous vous encourageons à lire leurs politiques de confidentialité.
                    </p>
                <h3   style="text-transform: uppercase">Informations sur les Mineurs</h3>
                    <p>
                        Nous ne collectons pas sciemment d'informations personnelles d'enfants de moins de 13 ans sans le consentement parental. Si nous découvrons que des informations d'un enfant de moins de 13 ans ont été collectées, nous prendrons des mesures pour les supprimer.
                    </p>
                    <h3  style="text-transform: uppercase">Suppression de Compte</h3>
                    <p>
                        Pour supprimer votre compte, envoyez un e-mail à <a href="javascript:void(0)" style="color: #fff"></a> supporttechnique@nbh.ci
                    </p>
                    <h3 style="text-transform: uppercase">Conditions d'Utilisation</h3>
                    <p>
                        Veuillez consulter nos Conditions d'utilisation pour les termes régissant l'utilisation de nos services.
                    </p>
                    <h3 style="text-transform: uppercase">Modifications de la Politique de Confidentialité</h3>
                    <p>
                        Nous pouvons modifier cette politique de confidentialité de temps à autre. La version la plus récente régira notre utilisation de vos informations. Nous vous informerons des changements importants par avis sur nos services ou par e-mail. Il est conseillé de consulter régulièrement cette page pour les mises à jour.
                    </p>


<!--                    <h3>-->
<!--                        AUTRES CIRCONSTANCES DANS LESQUELLES VOS DONNÉES PEUVENT ÊTRE-->
<!--                        PARTAGÉES-->
<!--                    </h3>-->
<!--                    <p>-->
<!--                        Transferts d&#39;entreprise. Au fil du temps, NBH ou ses filiales peuvent être vendues-->
<!--                        avec-->
<!--                        ses actifs, ou d&#39;autres transactions d&#39;entreprise peuvent avoir lieu. Dans un tel cas,-->
<!--                        nos-->
<!--                        informations client peuvent être l&#39;un des actifs commerciaux que nous transférons.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        Divulgation à des fins légales. Nous pouvons être tenus de partager vos informations-->
<!--                        personnelles et non personnelles en vertu d&#39;assignations, de mandats ou d&#39;ordonnances-->
<!--                        judiciaires ou gouvernementales. Si nous sommes tenus de le faire, nous obéirons bien sûr à la-->
<!--                        loi. De plus, nonobstant toute disposition contraire dans la présente politique de-->
<!--                        confidentialité, nous nous réservons le droit d&#39;utiliser, de divulguer ou de partager vos-->
<!--                        informations personnelles et non personnelles afin d&#39;enquêter, de prévenir ou de prendre des-->
<!--                        mesures concernant des activités illégales, des soupçons de fraude, des situations impliquant-->
<!--                        des menaces à la sécurité de toute personne, violations des conditions d&#39;utilisation-->
<!--                        applicables-->
<!--                        de la société ou tel que requis par la loi.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        Gestion de vos informations personnelles-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        NBH utilise le courrier électronique, les notifications et les sms comme canal de-->
<!--                        communication essentiel et principal avec les utilisateurs enregistrés des services de-->
<!--                        l&#39;entreprise. En nous fournissant votre adresse e-mail et ou votre numéro de téléphone, vous-->
<!--                        accordez à NBH (et à ses fournisseurs de services) la permission de communiquer avec-->
<!--                        vous à toutes fins que NBH juges pertinents pour votre utilisation des Services de-->
<!--                        l&#39;entreprise ou votre relation avec NBH, y compris, mais sans s&#39;y limiter, les-->
<!--                        messages-->
<!--                        système, les mises à jour de produits, les annonces de service et les autres messages marketing-->

<!--                        que vous avez choisi de recevoir. Vous pouvez choisir de ne pas recevoir les messages-->
<!--                        marketing que nous pouvons vous envoyer.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        Les utilisateurs qui ne souhaitent pas recevoir d&#39;e-mails marketing sont priés de contacter-->
<!--                        NBH par e-mail info@digibit.ci  Les utilisateurs ne peuvent pas se désabonner des-->
<!--                        messages système, des mises à jour de produits et des annonces de service, qui contiennent-->
<!--                        des informations importantes sur les avis de service et les responsabilités.NBH-->
<!--                        communique occasionnellement avec ses utilisateurs par e-mail ou par notification système-->
<!--                        pour fournir des informations sur les services demandés et sur les problèmes liés à leur-->
<!--                        compte.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        En plus de fournir un mécanisme permettant aux utilisateurs de se désabonner des e-mails-->
<!--                        marketing, www.nbh.ci s&#39;efforce de fournir aux utilisateurs un accès à leurs informations-->
<!--                        personnelles. Les utilisateurs peuvent accéder, mettre à jour, corriger ou supprimer leurs-->
<!--                        informations personnelles en se connectant à leur compte sur le site Web Ticteq.com ou sur-->
<!--                        un autre service de la société, selon le cas. Dans de nombreux cas, les utilisateurs peuvent-->
<!--                        également être en mesure d&#39;accéder, de mettre à jour, de corriger ou de supprimer des-->
<!--                        informations personnelles sur les sites Web des hôtes d&#39;événements.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        Si vous ne souhaitez pas que des informations soient collectées via l&#39;utilisation de-->
<!--                        cookies, il-->
<!--                        existe une procédure simple dans la plupart des navigateurs qui permet à un utilisateur-->
<!--                        d&#39;accepter ou de rejeter la plupart des cookies. Certains cookies appelés objets partagés-->
<!--                        locaux-->
<!--                        ou Flash Cookies peuvent ne pas être gérés à l&#39;aide des paramètres de votre navigateur-->
<!--                        Web. Les informations sur le rejet de ces cookies sont disponibles directement sur le site Web-->
<!--                        d&#39;Adobe. Si vous configurez votre navigateur ou les options d&#39;Adobe Flash pour ne pas-->
<!--                        accepter les cookies ou les objets locaux partagés, vous ne pourrez peut-être pas profiter de-->
<!--                        certaines fonctionnalités des Services de la Société.-->
<!--                    </p>-->


<!--                    <h3>-->
<!--                        COMBIEN DE TEMPS VOS INFORMATIONS PERSONNELLES SONT-ELLES-->
<!--                        CONSERVÉES ?-->
<!--                    </h3>-->
<!--                    <p>-->
<!--                        Vos informations personnelles seront conservées aux fins et utilisations énoncées dans la-->
<!--                        présente politique de confidentialité et aussi longtemps qu&#39;il existe une exigence légale,-->
<!--                        fiscale, comptable, de gestion des risques ou commerciale. Différentes périodes de-->
<!--                        conservation s&#39;appliquent à différents types d&#39;enregistrements et de-->
<!--                        données ; cependant, la-->
<!--                        durée maximale de conservation de vos données personnelles est de 6 ans à compter de la date-->
<!--                        de votre dernière transaction. Cette période peut être prolongée si les circonstances-->
<!--                        l&#39;exigent-->
<!--                        ou jusqu&#39;à ce qu&#39;une requête, une enquête ou un litige particulier soit entièrement-->
<!--                        résolu.-->
<!--                    </p>-->

<!--                    <h3>-->
<!--                        DÉCLARATION SUR LA QUALITÉ ET LA SÉCURITÉ DES DONNÉES-->
<!--                    </h3>-->
<!--                    <p>-->
<!--                        NBH prend la sécurité de vos informations personnelles très au sérieux et utilise des-->
<!--                        mesures de sécurité pour protéger vos informations personnelles contre l&#39;accès non autorisé,-->
<!--                        la-->
<!--                        modification non autorisée et la divulgation de données. Nous utilisons des systèmes de-->
<!--                        sécurité des données pour crypter vos informations personnelles et financières afin de réduire-->
<!--                        le risque de perte, d&#39;utilisation abusive et d&#39;altération de vos informations, ou que-->
<!--                        vos-->
<!--                        informations soient obtenues par des personnes non autorisées. Nos mesures de sécurité-->
<!--                        électronique sont complétées par la sécurité physique de nos installations et un accès limité à-->
<!--                        certaines zones critiques, telles que nos emplacements informatiques. Digibit.ci peut traiter et-->
<!--                        conserver vos informations personnelles sur nos serveurs aux États-Unis où se trouvent nos-->

<!--                        centres de données. Cependant, en raison de la sophistication croissante des pirates-->
<!--                        informatiques et autres qui chercheraient à envahir nos systèmes informatiques dans le but de-->
<!--                        voler des informations, d&#39;endommager nos systèmes ou de nier la capacité de nos systèmes à-->
<!--                        fonctionner efficacement pour protéger les informations personnelles et financières comme-->
<!--                        prévu, nous ne faisons aucune promesses quant à la sécurité des informations personnelles ou-->
<!--                        financières en notre possession ou l&#39;impénétrabilité de nos systèmes informatiques dans des-->
<!--                        circonstances inhabituelles ou la résilience de notre système informatique à de futures-->
<!--                        attaques sophistiquées. Digibit.ci ne peut pas non plus protéger les données stockées sur votre-->
<!--                        propre ordinateur ou appareil personnel, ou sur tout ordinateur public sur lequel vous pourriez-->
<!--                        choisir d&#39;accéder aux Services de la Société. Capacité à fonctionner efficacement pour-->
<!--                        protéger les informations personnelles et financières comme prévu, nous ne faisons aucune-->
<!--                        promesse quant à la sécurité des informations personnelles ou financières en notre possession-->
<!--                        ou à l&#39;impénétrabilité de nos systèmes informatiques dans des circonstances inhabituelles ou-->
<!--                        à-->
<!--                        la résilience de notre système informatique face à de futures attaques sophistiquées.-->
<!--                    </p>-->
<!--                    <h3>-->
<!--                        LIENS VERS / DEPUIS D&#39;AUTRES SITES-->
<!--                    </h3>-->
<!--                    <p>-->
<!--                        Les services de notre société, y compris notre site Web, peuvent contenir des liens vers-->
<!--                        d&#39;autres sites Web que nous n&#39;exploitons pas et, inversement, d&#39;autres sites Web-->
<!--                        peuvent-->
<!--                        contenir des liens vers notre site Web. Nous ne sommes pas au courant et ne sommes pas-->
<!--                        responsables des politiques de confidentialité, des pratiques ou du contenu de ces autres sites-->
<!--                        Web. Nous encourageons les visiteurs à lire et à se familiariser avec les politiques de-->
<!--                        confidentialité maintenues par ces autres sites Web.-->
<!--                    </p>-->

<!--                    <h3>-->
<!--                        INFORMATION DES MINEURS &amp; ENFANTS.-->
<!--                    </h3>-->
<!--                    <p>-->
<!--                        Nous ne ciblons pas les enfants sur notre site Web ou les services de l&#39;entreprise et nos-->
<!--                        services de l&#39;entreprise ne collectent pas sciemment des informations personnelles-->
<!--                        d&#39;enfants de-->
<!--                        moins de treize (13) ans sans le consentement du parent ou du tuteur de l&#39;enfant. Lorsque la-->
<!--                        présente politique de confidentialité fait référence au consentement, cela nécessitera le-->
<!--                        consentement d&#39;un parent ou d&#39;un tuteur légal par rapport à toute personne n&#39;ayant-->
<!--                        pas atteint-->
<!--                        l&#39;âge spécifié par la loi applicable à la localisation de l&#39;enfant en question. Si nous-->
<!--                        apprenons-->
<!--                        qu&#39;un enfant n&#39;ayant pas atteint l&#39;âge de consentement applicable nous a fourni des-->
<!--                        informations personnelles ; Digibit.ci prendra les mesures nécessaires pour supprimer ces-->
<!--                        informations et résilier le compte de l&#39;enfant.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        Si vous avez moins de 13 ans ou l&#39;âge de consentement applicable dans votre juridiction,-->
<!--                        veuillez ne pas soumettre d&#39;informations personnelles en utilisant les services de la-->
<!--                        société ou-->
<!--                        nous demander de vous envoyer un e-mail. Si vous avez moins de 13 ans, vous devez obtenir-->
<!--                        l&#39;autorisation d&#39;un parent ou d&#39;un tuteur avant de nous envoyer un e-mail,-->
<!--                        d&#39;essayer d&#39;utiliser-->
<!--                        nos Services de l&#39;entreprise, de soumettre des informations via nos Services de-->
<!--                        l&#39;entreprise ou-->
<!--                        de nous demander de vous envoyer un e-mail.-->
<!--                    </p>-->

<!--                    <h3>-->
<!--                        CONDITIONS D&#39;UTILISATION-->
<!--                    </h3>-->
<!--                    <p>-->
<!--                        Veuillez également consulter nos Conditions d&#39;utilisation pour consulter les termes et-->
<!--                        conditions régissant l&#39;utilisation des Services digibit.ci applicables.-->
<!--                    </p>-->

<!--                    <h3>MODIFICATIONS DE LA POLITIQUE DE CONFIDENTIALITÉ.</h3>-->
<!--                    <p>-->
<!--                        NBH se réserve le droit de modifier cette politique de confidentialité de-->
<!--                        temps à autre. La version la plus récente de la politique régira notre utilisation de vos-->
<!--                        informations personnelles. Dans le cas où Digibit.ci, à sa seule discrétion, déterminerait que-->
<!--                        les mises à jour de la politique de confidentialité constituent un changement important, nous-->
<!--                        pouvons vous informer de ces changements dans un avis publié via les services de la société-->
<!--                        ou envoyer un e-mail à l&#39;adresse e-mail associée à votre compte utilisateur. Nonobstant ce-->
<!--                        qui-->
<!--                        précède, Ticteq.com n&#39;a aucune obligation d&#39;informer un utilisateur des modifications-->
<!--                        apportées à cette politique, et les utilisateurs doivent donc visiter périodiquement cette page-->
<!--                        pour revoir la politique alors en vigueur à laquelle vous êtes lié.-->
<!--                    </p>-->
                </div>

            </div>
            <div class="col-lg-2">

            </div>
        </div>
    </div>
</div>
