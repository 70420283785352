<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 250px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color:  #0B2154 !important;height: 250px !important;">
        <app-navbar></app-navbar>
        <div class="container">
            <div class="menu">
                <div class="menu-title">
                    <span>Mes tickets reçus</span>
                </div>
                <div class="profile">
                    <div class="row">
                        <div class="col-4">
                            <svg style="    width: 98%;" xmlns="http://www.w3.org/2000/svg" width="104" height="106" viewBox="0 0 104 106">
                                <g id="Groupe_58" data-name="Groupe 58" transform="translate(-449.57 -116.254)">
                                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="52" cy="52" r="52" transform="translate(449.57 116.255)" fill="#60ba07"/>
                                    <text id="G" transform="translate(501.57 200.254)" fill="#cecece" font-size="87" font-family="Montserrat-Bold, Montserrat" font-weight="700" letter-spacing="0.041em"><tspan x="-32.625" y="0">{{_lettre(dataUsers?.spectateurnom)}}</tspan></text>
                                </g>
                            </svg>

                        </div>
                        <div class="col-8">
                            <span class="profile-text">{{dataUsers?.spectateurnom}} {{dataUsers?.spectateurprenom}}</span> <br>
                            <span class="profile-text">{{dataUsers?.spectateurtel}}</span><br>
                            <span class="profile-text">{{dataUsers?.spectateuremail}}</span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>
</section>

<div class="courses-details-area pb-70" style="background-color:#006837 ;min-height: 100%;">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <nav class="navCat">
                    <a style="cursor: pointer" [routerLink]="data.uri"  *ngFor="let data of dataNav;let i=index" id="nav{{i}}">{{data.label}}</a>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-8">
                <h2 class="titleListe">Les tickets reçus :</h2>
                <div class="courses-details-desc">
                    <div class="contLine" >
                        <div class="event" *ngFor="let a of tickets" (click)="showMyTicket(a)">
                            <img src="{{a?.urlImageEvent || 'assets/img/party.jpg'}}" alt="Événement 1">
                            <div class="info">
                                <p class="title">{{a.event}}</p>
                                <p class="price">
                                    {{_split(a.caticket)}} : {{a.ticketmontantpayer | number}} FCFA
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">

            </div>
        </div>
    </div>
</div>





















<!--<div class="module">-->
<section class="mission-area" id="imgProfil" style="padding-top: 0;min-height: 812px;   height: 100% !important;display: none">
    <section class="mission-area" style="background: linear-gradient(to bottom, #006400, #004d00) !important; height: 160vh !important;">
        <app-navbar></app-navbar>
        <div class="container">



            <div class="contLine" *ngIf="showListTicket && !showTicket">
                <div class="event" *ngFor="let a of tickets" (click)="showMyTicket(a)">
                    <img src="assets/img/party.jpg" alt="Événement 1">
                    <div class="info">
                        <p class="title">{{a.event}}</p>
                        <p class="price">
                            {{a.caticket}} : {{a.ticketmontantpayer}} F &nbsp;&nbsp;{{a.typeticket}}  &nbsp;&nbsp;
                            <b style="float: right;font-weight: bold;"> &gt;</b>
                        </p>
                    </div>
                </div>
            </div>



<!--        <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "small" color = "#fff" type = "square-jelly-box" [fullScreen] = "false"><p style="color: white" > Chargement... </p></ngx-spinner>-->


            <div *ngIf="!showListTicket && showTicket" class="myDashboard-profile" style="margin-top: 40px;">
                <div *ngIf="showTicket.typeticket==='QR_CODE'" class="row align-items-center">
                    <div class="col-lg-4 col-md-4">
                        <qrcode [qrdata]="showTicket.ticketcode" [width]="356" [errorCorrectionLevel]="'M'"></qrcode>
                    </div>


                    <div class="col-lg-8 col-md-8">
                        <div class="profile-content">
                            <ul class="contact-info">
                                <li>
                                    <h3>{{ showTicket.event}}</h3>
                                </li>
                                <li>
                                    <p> <b>Categorie ticket : </b> {{ showTicket.caticket}}</p>
                                </li>
                                <li><b>Ref achat : </b>{{ showTicket.achatcode }}</li>
                                <li><b>Date achat : </b>{{ showTicket.ticketdatecreation | date: 'dd/MM/yyyy hh:mm' }}</li>
                                <li><b>Mode Achat : </b> {{ showTicket.provenance}}</li>
                                <li><b>Montant normal : </b> {{ showTicket.ticketmontantnormal}}</li>
                                <li><b>Reduction : </b> {{ showTicket.ticketreduction}}</li>
                                <li><b>Montant payé : </b> {{ showTicket.ticketmontantpayer}}</li>
                                <li><b>Beneficiaire Nom : </b> {{ showTicket.beneficiairenom}}</li>
                                <li><b>Montant Prenom : </b> {{ showTicket.beneficiaireprenom}}</li>
                                <li><b>Montant Contact : </b> {{ showTicket.beneficiairetel}}</li>

                                <li>
                                    <a (click)="loadTicket()" style="cursor: pointer; color: white;"
                                       class="myDashboard-logout">Fermer</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div *ngIf="showTicket.typeticket==='CARTE'" class="row align-items-center">



                    <div class="col-lg-12 col-md-12">
                        <div class="profile-content">
                            <ul class="contact-info">
                                <li>
                                    <h3>{{ showTicket.event}}</h3>
                                </li>
                                <li>
                                    <p> <b>Categorie ticket : </b> {{ showTicket.caticket}}</p>
                                </li>
                                <li><b>Code ticket : </b>{{ showTicket.ticketcode }}</li>
                                <li><b>Carte : </b>{{ showTicket.beneficiairecarte }}</li>


                                <li><b>Ref achat : </b>{{ showTicket.achatcode }}</li>
                                <li><b>Date achat : </b>{{ showTicket.ticketdatecreation | date: 'dd/MM/yyyy hh:mm' }}</li>
                                <li><b>Mode Achat : </b> {{ showTicket.provenance}}</li>
                                <li><b>Montant normal : </b> {{ showTicket.ticketmontantnormal}}</li>
                                <li><b>Reduction : </b> {{ showTicket.ticketreduction}}</li>
                                <li><b>Montant payé : </b> {{ showTicket.ticketmontantpayer}}</li>
                                <li><b>Beneficiaire Nom : </b> {{ showTicket.beneficiairenom}}</li>
                                <li><b>Montant Prenom : </b> {{ showTicket.beneficiaireprenom}}</li>
                                <li><b>Montant Contact : </b> {{ showTicket.beneficiairetel}}</li>
                                <li>
                                    <a (click)="loadTicket()" style="cursor: pointer; color: white;"
                                       class="myDashboard-logout">Fermer</a>
                                </li>

                            </ul>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </section>
</section>

<!--</div>-->

<!--<div class="my-dashboard-area ptb-100" style="display:none ">-->
<!--    <div class="container">-->

<!--        <div class="myDashboard-navigation">-->
<!--            <ul>-->
<!--                <li><a routerLink="/achat"><i class='bx bx-cart'></i> Mes achats</a></li>-->
<!--                <li><a routerLink="/ticket"><i class='bx bxs-dashboard'></i> Mes tickets</a></li>-->
<!--                <li><a routerLink="/foward"><i class='bx bx-download'></i>Tickets transferés</a></li>-->
<!--                <li><a routerLink="/receive" class="active"><i class='bx bx-recycle'></i> Tickets réçus</a></li>-->
<!--                <li><a routerLink="/carte"><i class='bx bx-credit-card'></i> Mes cartes</a></li>-->
<!--                <li><a routerLink="/notification"><i class='bx bx-notification'></i>Notification</a></li>-->
<!--                <li><a routerLink="/infos"><i class='bx bx-edit'></i> Infos personelles</a></li>-->
<!--                <li><a (click)="logout()" ><i class='bx bx-user-x'  ></i>Deconnexion</a></li>-->

<!--            </ul>-->
<!--        </div>-->






<!--        <div *ngIf="showListTicket && !showTicket" class="myDashboard-content">-->

<!--            <h3></h3>-->

<!--            <div class="product-add-to-cart" style="margin-bottom: 10px;">-->
<!--                <button (click)="loadTicket()" type="submit" class="default-btn"><span-->
<!--                        class="label">Rafraichir</span></button>-->
<!--            </div>-->

<!--            <div class="recent-orders-table table-responsive">-->
<!--                <table class="table">-->
<!--                    <thead>-->
<!--                        <tr>-->
<!--                            <th>Date achat</th>-->
<!--                            <th>Evenement</th>-->
<!--                            <th>Categorie ticket</th>-->
<!--                            <th>TYPE</th>-->
<!--                            <th>Actions</th>-->
<!--                        </tr>-->
<!--                    </thead>-->
<!--                    <tbody *ngIf="tickets && tickets.length>0">-->
<!--                        <tr *ngFor="let t of tickets">-->
<!--                            <td>{{t.ticketdatecreation | date: 'dd/MM/yyyy hh:mm'}}</td>-->
<!--                            <td>{{t.event}}</td>-->
<!--                            <td>{{t.caticket}}</td>-->
<!--                            <td>{{t.typeticket}}</td>-->
<!--                            <td><a class="view-button" style="cursor: pointer;" (click)="showMyTicket(t)">Voir</a></td>-->
<!--                        </tr>-->



<!--                    </tbody>-->
<!--                </table>-->
<!--            </div>-->
<!--        </div>-->


<!--    </div>-->

<!--</div>-->
