import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {environment} from 'src/environments/environment';
import {EventwithkeyService} from '../home-billeterie/eventwithkey.service';
import {EventwithoutkeyService} from '../home-billeterie/eventwithoutkey.service';
import {CommentaireDto, Spectateur} from '../spectateur';
import {cryptSession, decode64} from "../../../core/config";
import {NzModalService} from "ng-zorro-antd/modal";
import {MaskApplierService} from "ngx-mask";
import * as moment from "moment";

declare let $: any;

@Component({
    selector: 'app-eventdetails-billeterie',
    templateUrl: './eventdetails-billeterie.component.html',
    styleUrls: ['./eventdetails-billeterie.component.scss']
})
export class EventdetailsBilleterieComponent implements OnInit {
    dataMenu: any = [
        {
            uri: "/",
            label: "Accueil",
        }, {
            uri: "/concert",
            label: "Découvrir",
        }, {
            uri: "/connecter",
            label: "Mon profil",
        }
    ]

    event: any = [];
    loadingCheck: any = false;
    key: string;

    isConnect = false;


    loadingCommentaireCheck: any = false;


    commentaires: CommentaireDto[] = [];

    img: string = "https://static.vecteezy.com/ti/vecteur-libre/p3/1840612-image-profil-icon-male-icon-human-or-people-sign-and-symbol-vector-gratuit-vectoriel.jpg"
    public comentaireForm;

    panels = [
        {
            active: true,
            name: '1. Quels sont nos moyens de paiement ?',
            description: 'Vous pouvez payez en ligne par Wave, Mobile Money ou carte bancaire.',
            disabled: false
        },
        {
            active: false,
            disabled: false,
            description: 'Pour valider votre ticket, ouvrez votre application, allez dans la zone Mes tickets et faites scanner le QR code. Si vous possédez une carte qui contient votre ticket, faite scanner votre carte par les agents NBH à l\'entrée.',
            name: '2. Comment valider mon ticket le jour j ?'
        },
        {
            active: false,
            disabled: true,
            description: 'Avec NBH, vous avez la possibilité d’acheter des tickets en QR code ultra sécurisé mais aussi d’acheter votre ticket et l’ajouter à votre Carte NBH.',
            name: '3. Quels sont nos 2 types de tickets ?'
        },
        {
            active: false,
            disabled: true,
            description: 'Les cartes NBH sont des cartes magnétiques très sécurisées qui vous sert de portefeuille de tickets pour tous vos événements. Si vous possédez une carte NBH, vous acheter votre ticket sur nos plateformes et pour l’avoir sur votre carte, vous devez choisir l’option ticket en carte au lieu de tickets en QR code. Le jour de l’événement, les agents nbh ont le nécessaire pour vérifier que votre carte contient le ticket en question.\n',
            name: '4. Comment fonctionne nos cartes NBH ?'
        }
    ];

    pageUrl: string = encodeURIComponent(window.location.href);
    message: string = encodeURIComponent("Découvrez l'événement: ");

    messengerUrl: string = `https://www.messenger.com/share?link=${this.pageUrl}`;
    whatsappUrl: string = `https://wa.me/?text=${this.message}%20${this.pageUrl}`;
    instagramUrl: string = `https://www.instagram.com/`; // Instagram ne supporte pas le partage direct

    constructor(private _activatedRoute: ActivatedRoute, private router: Router, private gestserv: EventwithoutkeyService,
                private gestwithkey: EventwithkeyService, public fb: UntypedFormBuilder,
                private logger: NGXLogger, private modal: NzModalService, private maskApplierService: MaskApplierService) {
    }

    ngOnInit(): void {


        this.comentaireForm = this.fb.group({
            'message': [null, Validators.required],
        });

        let u: any = {};
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));


        this._activatedRoute.queryParams.forEach((params: Params) => {
            if (params['key']) {
                this.key = params['key'];
                this.loadEvent(params['key']);
                if (u !== null) {
                    this.isConnect = true;
                    this.loadCommentaire();
                }
            } else {
                this.router.navigate(['/']);
            }
        });
    }


    goToRouting(elem: any) {
        this.router.navigateByUrl('/checkpayment?key=' + elem.caticketcode + '&type=' + this.event.typeventcode + '&caticketlib=' + elem.caticketlib);
    }

    loadEvent(code) {
        this.loadingCheck = false;
        this.gestserv.get("/billeterie/event/" + code).subscribe(data => {
                switch (data.status) {
                    case 200: {
                        this.selMenu(1);
                        this.event = data.body;

                        let mapSession = cryptSession(JSON.stringify(this.event), decode64(environment.APP_PASS));
                        localStorage.setItem(environment.APP_TOKEN_ACHAT, mapSession)

                        $('#myImg').css({
                            'background-image': 'url(' + this.event.url + ')',
                            'background-repeat': 'no-repeat',
                            'background-size': 'cover'
                        });
                        this.logger.trace(this.event);
                        this.loadingCheck = true;
                        break;
                    }


                }
            },
            error => {
                switch (error.status) {
                    case 401: {
                        //this.router.navigate(['/authentication/signin']);
                        this.loadingCheck = true;
                        break;
                    }
                    default: {
                        this.loadingCheck = true;
                        this.logger.trace(error);

                        break;
                    }
                }
            });
    }


    loadCommentaire() {

        let u: Spectateur;
        this.loadingCommentaireCheck = true;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));


        this.comentaireForm = this.fb.group({
            'message': [null, Validators.required],
        });

        if (u) {


            this.gestwithkey.get("billeterie/:commentaire/:byspectateur/:" + u.spectateurcode + "/:" + this.key, u.token).subscribe(data => {
                    switch (data.status) {
                        case 200: {
                            this.loadingCommentaireCheck = false;
                            this.commentaires = data.body;
                            this.logger.trace("MES COMMENTAIRES ==> ");

                            this.logger.trace(this.commentaires);
                            break;
                        }
                    }
                },
                error => {
                    switch (error.status) {
                        case 401: {
                            //this.router.navigate(['/authentication/signin']);
                            this.loadingCommentaireCheck = false;
                            break;
                        }
                        default: {
                            this.loadingCommentaireCheck = false;
                            this.logger.error(error);
                            break;
                        }
                    }
                });
        }


    }


    saveCommentaire() {
        let u: Spectateur;
        this.loadingCommentaireCheck = true;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));


        if (!this.comentaireForm.valid) {
            return;
        }


        if (u) {
            let request: any = this.comentaireForm.value;
            request.spectateurcode = u.spectateurcode;
            request.eventcode = this.key;

            this.gestwithkey.post(request, "/billeterie/commentaire/add", u.token).subscribe(data => {
                    this.logger.trace('INFOS CNX', data);

                    switch (data.status) {
                        case 201: {
                            this.modal.success({
                                nzTitle: '<b>COMMENTAIRE</b>',
                                nzContent: `<p>Votre commentaire a été soumis avec succès, nous vous en remercions.</p>`,
                                nzOkText: 'OK',
                                nzCentered: true,
                                nzOnOk: () => {
                                }
                            });
                            this.loadCommentaire();
                            break;
                        }
                    }
                },
                error => {
                    //this.loader.close();

                    this.logger.error('ERROR', error);
                    switch (error.status) {


                        default: {
                            this.loadCommentaire();

                            break;
                        }
                    }
                });
        }


    }

    selMenu(i: number) {
        for (let j = 0; j < this.dataMenu.length; j++) {
            $('#ligneMenu' + j).removeClass("activeItem");
        }
        $('#ligneMenu' + i).addClass('activeItem');
    }

    formatNumber(value) {
        if (typeof value === 'number') {
            value = value.toString();
        }
        // Formatage avec 3 chiffres après la virgule
        return this.maskApplierService.applyMask(value, 'separator.3');
    }

    fDate(eventdebut: any) {
        if(!eventdebut) return ;
        return moment(eventdebut).format('DD/MM/YYYY HH:mm:ss')
    }

    share() {
        if (navigator.share) {
            navigator.share({
                title: this.event.eventitre,
                text: `Découvrez l'événement: ${this.event.eventitre} \n`,
                url: window.location.href,
            }).catch(err => console.log("Erreur de partage:", err));
        } else {
            alert("Le partage n'est pas supporté sur ce navigateur.");
        }
    }
    copyLink() {
        navigator.clipboard.writeText(window.location.href).then(() => {
            alert("Lien copié !");
        });
    }

    messenger(link?){
        window.open(`https://www.messenger.com/share?link=${this.pageUrl}`,"_blank")
    }
     whatsappShareUrl() {
         console.log(window.location.href)
        const message = `Découvrez l'événement: ${this.event.eventitre}\n${window.location.href}`;
        // const message = `Découvrez l'événement: ${this.event.eventitre}\n${this.pageUrl}\n\n${this.event.url}`;
         window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`,"_blank")
    }
    instagram(link?){
        window.open(`https://www.instagram.com/`,"_blank");// instagramUrl: string = `https://www.instagram.com/`; // Instagram ne supporte pas le partage direct
    }

}
